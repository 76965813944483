import { get, intersectionWith } from "lodash";
import { useContext } from "react";
import AccessDeniedPage from "src/components/AccessDenied/AccessDenied";
import { AppContext } from "../../../utils/context/AppStore";

const DEFAULT_PROPS = {
    PERMISSIONS: [],
    FALLBACK: () => <AccessDeniedPage />
};

export const PERMISSIONS = {
    TIME_SHEET_READ: 131
}

const MonteraPermissionGuard = ({ 
    children, 
    permissions = DEFAULT_PROPS.PERMISSIONS,
    fallback = DEFAULT_PROPS.FALLBACK
}) => {
    const { GlobalStore } = useContext(AppContext);

    if (!GlobalStore.userData.key) {
        return null;
    }

    if (!permissions?.length) {
        return children 
            ? children(true)
            : fallback();
    }

    if (!get(GlobalStore, 'userData.permissions')) {
        return children 
            ? children(false)
            : fallback();
    }

    const userPermissions = GlobalStore.userData.permissions || [];

    const intersection = intersectionWith(permissions, userPermissions, (x, y) => x === y.key);

    if (!intersection.length) {
        return fallback();
    }

    return children(true);
}

export default MonteraPermissionGuard;