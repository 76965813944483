import React from "react";
import { Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import monteraLogo from "./imgs/montera-logo.svg";
import { useStyles } from "./style";
import UnsubscribeMenu from "./UnsubscribeMenu";

const Unsubscribe = ({ match }) => {
  const { box, wrapperImg } = useStyles();
  return (
    <Container>
      <div className={wrapperImg}>
        <img src={monteraLogo} alt="" style={{ padding: "32px" }} />
      </div>
      <Paper className={box}>
        <UnsubscribeMenu />
      </Paper>
    </Container>
  );
};

export default Unsubscribe;
