import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import AccessDeniedIcon from "./icons/AccessDeniedIcon.svg"
import { useStyles } from './styles';
import { ArrowLeft } from "src/assets/imgs";
import { Link as RouterLink } from "react-router-dom";
import { theme } from '../../utils/styleTheme/mainStyles';
import { useTranslation } from 'react-i18next';

const AccessDeniedPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="space-between" className={classes.mainWrapper}>
      <Grid item xs={6} style={{textAlign: 'right'}}>
        <div style={{textAlign: 'left', display: 'inline-block'}}>
          <Typography variant="h1" className={classes.title} >
            {t("common:all.labels.access_denied_page.title")}
          </Typography>
          <br/>
          <Typography paragraph className={classes.description}>
            {t("common:all.labels.access_denied_page.description")}
          </Typography>
          <br/>
          <div>
            <Button onClick={() => window.history.back()} variant="contained" color="primary" size="large" startIcon={<ArrowLeft/>} className={classes.button}>
              {t("common:common.labels.go_back")}
            </Button>
            <Button 
              to={`/`}
              component={RouterLink} 
              variant="text" 
              color="primary" 
              size="large" 
              className={classes.button}
              style={{marginLeft: theme.spacing(2)}}
            >
              {t("common:common.labels.open_home_page")}
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <img src={AccessDeniedIcon} alt="Access Denied Icon" width="400" height="400"/>
      </Grid>
      
      
    </Grid>
  );
};

export default AccessDeniedPage;
