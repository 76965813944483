import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withRouter } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";
import { AppStore } from "./utils/context/AppStore";
import RouterComponent from "./Router";
import { useLocation } from "react-router-dom";
import { CommentsStore } from "./components/StaffServices/Details/Comments/MessageStore";
import { LineItemsStore } from "./components/LineItems/LineItemsStore";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {  RecoilRoot } from 'recoil';
import { msalConfig } from "./utils/context/controllers/authConfig";
import Login from "./components/Login/Login";
import { PublicClientApplication } from "@azure/msal-browser";
import Main from "./Main";
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { FeatureFlagProvider } from "./utils/context/FeatureFlagProvider";

LicenseInfo.setLicenseKey(process.env.REACT_APP_Mui_license_key);

const useStyles = makeStyles((theme) => ({
  gridMainArea: {
    width: "100%",
  },
  mainArea: {
    marginTop: 57,
    marginLeft: 65,
    backgroundColor: "#f2f6f9",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  main: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    spacing: 0,
    justify: "space-around",
    backgroundColor: "#f2f6f9",
  },
}));

const globalLayout = ["unsubscribe", "404"];
export const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <AppStore>
        <FeatureFlagProvider>
          <RecoilRoot>
          <CommentsStore>
            <LineItemsStore>
              <CssBaseline />
              <AuthenticatedTemplate>
                <Main />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <LoginLogic />
              </UnauthenticatedTemplate>
            </LineItemsStore>
          </CommentsStore>
          </RecoilRoot>
        </FeatureFlagProvider>
      </AppStore>
    </MsalProvider>
  );
};

export default withRouter(App);

const LoginLogic = () => {
  const classes = useStyles();
  const location = useLocation();

  if (globalLayout.indexOf(location.pathname.split("/")[1]) >= 0) {
    return (
      <div className={classes.root}>
        <Grid container justifyContent="center">
          <main className={classes.main}>
            <RouterComponent />
          </main>
        </Grid>
      </div>
    );
  }
  return <Login />;
};
