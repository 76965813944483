import React from "react";
import clsx from 'clsx';
import TopNavSearch from "./TopNavSearch";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import TopNavUser from "./TopNavUserProfile";
import LanguageSwitcher from "./LanguageSwitcher";
import { CommentsIcon } from "./images";
import FEATURES_FLAGS from "../../FeaturesFlags";

const useStyles = makeStyles((theme) => {
  return {
    listItem: {
      height: "100%",
      borderRadius: 0,
      minWidth: theme.spacing(6),
    },
    button: {
      height: "100%",
      borderRadius: 0,
      minWidth: theme.spacing(6),
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#2B59C3 10%",
      },
      "&:focus": {
        backgroundColor: "#2B59C3 20%",
      },
    },
    buttonSelected: {
      background: 'rgba(43, 89, 195, 0.2)'
    }
  };
});

const TopNavBar = ({
  commentsOpen,
  onCommentsClick
}) => {
  const classes = useStyles({ commentsOpen });

  return (
    <Grid container alignItems="center" wrap="nowrap" style={{ height: 56 }}>
      <Grid item>
        <TopNavSearch />
      </Grid>
      <Grid item className={classes.listItem}>
        <Button
          component={Link}
          color="primary"
          to="/sadnbox"
          className={classes.button}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.8938 5.82801C6.24516 4.82919 7.2657 4.22941 8.30928 4.40841C9.35286 4.58742 10.1152 5.493 10.1136 6.55182C10.1136 7.99944 7.94218 8.72325 7.94218 8.72325"
              stroke="#141E30"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 11.6191V11.2572"
              stroke="#141E30"
              strokeLinecap="round"
            />
            <path
              d="M8.00006 15.2379C11.9975 15.2379 15.2382 11.9973 15.2382 7.99981C15.2382 4.00232 11.9975 0.761719 8.00006 0.761719C4.00257 0.761719 0.761963 4.00232 0.761963 7.99981C0.761963 11.9973 4.00257 15.2379 8.00006 15.2379Z"
              stroke="#141E30"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </Grid>
      {FEATURES_FLAGS.STANDALONE_COMMENTS && (
        <Grid item className={classes.listItem}>
          <Button
            className={clsx(classes.button, { [classes.buttonSelected]: commentsOpen })}
            color="primary"
            onClick={onCommentsClick}
          >
            <CommentsIcon />
          </Button>
        </Grid>
      )}
      <Grid item className={classes.listItem}>
        <Button href="#2" color="primary" className={classes.button}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.0028 13.0021H1C2.32572 13.0021 3.40043 11.9274 3.40043 10.6017V6.60099C3.40043 3.50765 5.90807 1 9.00142 1C12.0948 1 14.6024 3.50765 14.6024 6.60099V10.6017C14.6024 11.9274 15.6771 13.0021 17.0028 13.0021ZM10.3857 16.2027C10.0994 16.6962 9.57197 17 9.00142 17C8.43086 17 7.90346 16.6962 7.61717 16.2027H10.3857Z"
              stroke="#141E30"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </Grid>
      <Grid item className={classes.listItem}>
        <LanguageSwitcher />
      </Grid>
      <Grid item>
        <TopNavUser />
      </Grid>
    </Grid>
  );
};

export default TopNavBar;
