import { Collapse, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import CommentItem from '../CommentItem/CommentItem';
import moment from 'moment';
import MonteraExpandIcon from '../../../assets/reuseComponents/MonteraExpandIcon';
import { AppContext } from '../../../utils/context/AppStore';
import { getMomentLocale } from '../../../utils/functions/getMomentLocale';
import { useTranslation } from 'react-i18next';

const CommentsMonthGroupedTable = ({
    previewImageClassName,
    first,
    date,
    items
}) => {
    const { GlobalStore } = useContext(AppContext);
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(true);

    const onRowClick = () => {
        setExpanded(prev => !prev);
    }

    const locale = useMemo(() => {
        return getMomentLocale(GlobalStore.languageLocale);
    }, [GlobalStore]);

    const classes = useStyles({ expanded, first });

    const momentDate = moment(date, null, locale);
    const momentNow = moment();
    const timeType = mapTimeType(momentDate, momentNow);

    let timeTypeTranslation;
    if (timeType === TIME_TYPES.THIS_MONTH) {
        timeTypeTranslation = 'common:all.labels.comments.this_month';
    } else if (timeType === TIME_TYPES.LAST_MONTH) {
        timeTypeTranslation = 'common:all.labels.comments.last_month';
    }

    return (
        <>
            <div className={classes.row} onClick={onRowClick}>
                <div className={classes.rowLeft}>
                    <MonteraExpandIcon expanded={expanded} />
                    <Typography variant='subtitle2'>
                        {momentDate.format("MMMM • YYYY")}
                    </Typography>
                </div>
                <div>
                    {!!timeTypeTranslation && (
                        <Typography variant='subtitle2'>
                            {t(timeTypeTranslation)}
                        </Typography>
                    )}
                </div>
            </div>
            <Collapse
                classes={{
                    root: clsx(classes.contents, classes.container),
                    wrapper: classes.contents,
                    wrapperInner: classes.contents
                }}
                in={expanded}
                mountOnEnter={false}
                unmountOnExit={false}
            >
                {items.map(y => (
                    <CommentItem
                        key={y.messageId}
                        previewImageClassName={previewImageClassName}
                        comment={y}
                    />
                ))}
            </Collapse>
        </>
    )
}

const TIME_TYPES = {
    THIS_MONTH: 1,
    LAST_MONTH: 2
}

const mapTimeType = (momentDate, momentNow) => {
    if (momentDate.isSame(momentNow, 'month')) {
        return TIME_TYPES.THIS_MONTH;
    }
    if (!momentDate.isSame(momentNow, 'year')) {
        return;
    }

    const monthsDiff = momentNow.diff(momentDate, 'months');

    if (monthsDiff === 1) {
        return TIME_TYPES.LAST_MONTH;
    }
}

const useStyles = makeStyles(theme => ({
    row: ({ expanded, first }) => ({
        gridColumn: '1 / span 3',
        borderRadius: theme.spacing(0.5),
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        transition: theme.transitions.create("background", {
            duration: theme.transitions.duration.shortest
        }),
        cursor: 'pointer',
        background: 'rgba(20, 30, 48, 0.1)',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: first ? null : theme.spacing(1)
    }),
    rowLeft: {
        display: 'flex',
        alignItems: 'inherit',
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        },
    },
    root: {
        flex: 1,
    },
    table: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        alignContent: 'flex-start'
    },
    contents: ({ expanded }) => ({
        display: expanded ? 'contents' : null
    }),
    container: {
        gridColumn: '1 / span 3',
    }
}));

export default CommentsMonthGroupedTable;
