import React, { useContext, useState } from "react";
import clsx from "clsx";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import { useMsal } from "@azure/msal-react";
import Navigation from "./components/StaffNavigation/Navigation";
import RouterComponent from "./Router";
import { AppContext } from "./utils/context/AppStore";
import CommentsDrawer from "./components/StaffComments/CommentsDrawer";
import { Logo } from "./components/StaffNavigation/Logo";
import { HandleSystemError } from "./components/SystemError/HandleSystemError";
import { LeftBarContext, LeftBarStore } from "./components/StaffNavigation/LeftBar/store";


const useStyles = makeStyles((theme) => ({
  gridMainArea: {
    width: "100%",
  },
  mainArea: {
    backgroundColor: "#f2f6f9",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mainAreaMargins: ({ menuOpen }) => ({
    marginTop: 57,
    ...(menuOpen ? { marginLeft: 216 } : { marginLeft: 65 }),
  }),
  mainAreaMarginsFullscreen: {
    marginTop: "0px !important",
    marginLeft: "0px !important",
  },
  errorContainer: {
    backgroundColor: theme.palette.primary.main,
    height: "100vh",

    "& > h3": {
      fontSize: "2rem",
      marginTop: "10rem",
      color: theme.palette.common.white,
    },
    "& > h4": {
      fontSize: "1.5rem",
      padding: "1rem",
      color: theme.palette.common.white,
    },
    "& > button": {
      margin: "2rem auto",
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

const Main = () => {
  const { fullScreenMode, GlobalStore } = useContext(AppContext);
  const { instance } = useMsal();
  // Side Navigation Open/Collapsed
  const {
    LeftBarStore, LeftBarApi: {setData},
  } = useContext(LeftBarContext);
  
  const {open} = LeftBarStore;

  // Handle Nav Open/Collapse
  const handleOpen = () => {
    setData("open", !open);
  };

  const [commentsOpen, setCommentsOpen] = useState(false);
  const onCommentsClick = () => {
    setCommentsOpen((prev) => !prev);
  };

  const closeComments = () => {
    setCommentsOpen(false);
  };

  const classes = useStyles({ menuOpen: open });

  const handleLogout = () => {
    instance.logout();
  };

  if (GlobalStore.error) {
    return (
      <div className={classes.errorContainer}>
        <Logo open />
        <Typography variant="h3" component="h3" align="center">
          Access Denied
        </Typography>
        <Typography variant="h4" component="h4" align="center">
          Contact MonteraPlus administrator
        </Typography>
        <Button variant="outlined" size="large" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    );
  }

  return (
    <HandleSystemError>
    <div className="App">
      <Grid container justifyContent="center">
        <Grid item>
          <Navigation
            open={open}
            handleOpen={handleOpen}
            commentsOpen={commentsOpen}
            onCommentsClick={onCommentsClick}
          />
        </Grid>
        <Grid item className={classes.gridMainArea}>
          <main
            className={clsx(classes.mainArea, {
              [classes.mainAreaMargins]: !fullScreenMode,
              [classes.mainAreaMarginsFullscreen]: fullScreenMode,
            })}
          >
            <RouterComponent />
            <CommentsDrawer
              paperClassName={classes.mainAreaMargins}
              open={commentsOpen}
              onClose={closeComments}
            />
          </main>
        </Grid>
      </Grid>
    </div>
    </HandleSystemError>
  );
};

const MainWithContext = () => {
  return (
    <LeftBarStore>
      <Main />
    </LeftBarStore>
  );
};

export default MainWithContext;
