import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  Button,
  Collapse,
  List,
  ListItemIcon,
  ListItemText,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import NavItem from "./NavItem";
import { checkLinkSelect } from "../checkLinkSelect";
import MonteraPermissionGuard from "../../../assets/reuseComponents/MonteraGuards/MonteraPermissionGuard";
import uniqueId from "lodash/uniqueId";
import { useTranslation } from "react-i18next";
import { LeftBarContext } from "./store";
import { useStyles } from "./style";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    minWidth: 175,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}))(Tooltip);

const ParentNavItem = ({ label = "", Icon, subItems }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    LeftBarStore: { open },
  } = useContext(LeftBarContext);

  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  let handleClick = () => {
    if (open) {
      toggleExpand();
    }
  };

  const { location } = useHistory();
  const searchQuery = window.location.search;
  const selected = subItems.some((x) =>
    checkLinkSelect(x.link, location, searchQuery)
  );
  let className = classes.menuItem;
  if (selected) {
    className = classes.menuItemActive;
  }

  let tooltipTitle = "";
  if (!open) {
    tooltipTitle = (
      <List className={classes.menuChildTooltipList} key={label}>
        {subItems.map((x) => (
          <NavItem
            key={x.label}
            label={x.label}
            link={x.link}
            Icon={<div className={classes.iconTooltipPlaceholder}></div>}
          />
        ))}
      </List>
    );
  }
  return (
    <div key={`HtmlTooltip_${label}`}>
      <HtmlTooltip
        title={tooltipTitle}
        placement="right"
        interactive={true}
        arrow={true}
      >
        <Button
          onClick={handleClick}
          fullWidth
          classes={{
            root: clsx(classes.menuItem,{
              [classes.menuItemActive]: selected,
              [classes.menuItemOpen]: expanded,
            }),
          }}
          className={className}
        >
          <ListItemIcon
            classes={{
              root: clsx({
                [classes.iconOpen]: open,
                [classes.iconClose]: !open,
              }),
            }}
          >
            {Icon || <div className={classes.iconPlaceholder}></div>}
          </ListItemIcon>
          <ListItemText
            style={{ color: "#fff", textAlign: "start" }}
            primary={t(`common:all.labels.navigation.${label}`)}
          />
          {open && (
            <>
              {expanded && open ? (
                <ExpandLess className={classes.expansionIcon} />
              ) : (
                <ExpandMore className={classes.expansionIcon} />
              )}
            </>
          )}
        </Button>
      </HtmlTooltip>
      <Collapse
        in={expanded && open}
        timeout="auto"
        unmountOnExit={false}
        mountOnEnter={false}
        key={label}
      >
        {subItems.map((x) => (
          <div key={uniqueId("NavItem")}>
            <MonteraPermissionGuard permissions={x.permissions}>
              {() => (
                <NavItem
                  key={x.label}
                  label={x.label}
                  link={x.link}
                  child
                  Icon={<div className={classes.iconPlaceholder} />}
                />
              )}
            </MonteraPermissionGuard>
          </div>
        ))}
      </Collapse>
    </div>
  );
};


export default ParentNavItem;
