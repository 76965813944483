import React, { useContext } from 'react';
import { AppContext } from '../../../utils/context/AppStore';
import { Button, Grid } from '@material-ui/core';
import { putRecord } from '../../../utils/context/controllers/crud';
import NavIcon, { LocaleType } from './components/NavIcon';
import { useStyles } from './style';
import i18next from 'i18next';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const LanguageSwitcher = () => {
  const classes = useStyles();
  const { GlobalStore, ContextAPI } = useContext<any>(AppContext); //todo on real type of context after changing
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  if (!GlobalStore || !GlobalStore.userData) {
    return;
  }
  const { userData: { locale } } = GlobalStore;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateLocalizationHandler = (selectedLocale: LocaleType) => {
    if (selectedLocale.iso2 !== locale?.iso2) {

      (async () => {
        await Promise.all([
          i18next.changeLanguage(selectedLocale.iso2),
          await putRecord(`Profile/locale`, {
            locale: { key: selectedLocale.id },
          }),
        ]);
      })();
      handleClose()
      ContextAPI.updateLocaleData(selectedLocale);
    }
  };

  return (
    <div onMouseLeave={handleClose}>
      <Grid item style={{ height: 56 }}>
        <Button
          variant="text"
          color="primary"
          className={classes.listItem}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <img
            alt="lang"
            src={`/images/flags/${locale?.iso2}.png`}
            style={{ width: 24 }}
          />
          {Boolean(anchorEl) ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </Button>
      </Grid>
      <NavIcon
        anchorEl={anchorEl}
        handler={updateLocalizationHandler}
        handleClose={handleClose}
      />
    </div>
  );
};

export default LanguageSwitcher;
