import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "../Navigation";

export const useStyles = makeStyles((theme) => {
  return {
    menuList: {
      backgroundColor: theme.palette.primary.main,
      top: theme.spacing(3),
    },
    menuItem: {
      textOverflow: 'ellipsis',
      width: drawerWidth-6,
      overflow: 'hidden',
      textTransform: 'capitalize',
      textAlign: 'start',
      padding: 0,
      borderRadius: 0,
      '&.MuiButton-root:hover': {
        backgroundColor: '#2B59C3',
      },
      "&:hover": {
        backgroundColor: '#2B59C3',
      },
    },
    menuItemActive: {
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'rgba(43, 89, 195, 1)',
      '&.MuiButton-root:hover': {
        backgroundColor: '#2B59C3',
      },
    },
    menuItemOpen: {
      padding: 0,
      borderRadius: 0,
      backgroundColor: "#002771",
      "&:hover": {
        backgroundColor: '#2B59C3',
      },
    },
    menuItemChild: {
      textAlign: 'start',
      textTransform: 'capitalize',
      padding: 0,
      borderRadius: 0,
      backgroundColor: "#002771",
      "&:hover": {
        backgroundColor: '#2B59C3',
      },
    },
    menuChildTooltipList: {
      padding: theme.spacing(1),
    },
    iconOpen: {
      padding: '12px',
      paddingRight: theme.spacing(1),
      minWidth: 0,
      transition: ".4s",
    },
    iconClose: {
      padding: '12px 20px',
      minWidth: 0,
      transition: ".4s",
    },
    iconPlaceholder: {
      height: 16,
      width: 16,
    },
    iconTooltipPlaceholder: {
      height: 16,
      width: 0,
    },
    arrowDown: {
      transform: "rotate(0deg)",
      transition: ".3s",
    },
    arrowUp: {
      transform: "rotate(180deg)",
      transition: ".3s",
    },
    expansionIcon: {
      color: "#ffffff",
      margin: theme.spacing(1),
    },
  };
});
