import stagingMainLogo from "./images/stagingMainLogo.svg";
import defaultMainLogo from "./images/defaultMainLogo.svg";
import stagingSmallMainLogo from "./images/stagingSmallMainLogo.svg";
import defaultSmallMainLogo from "./images/defaultSmallMainLogo.svg";
import React from "react";
import { useStyles } from './Navigation'
import { useHistory } from "react-router-dom";

export const Logo = ({ open }) => {
  const isStagingSite = document.location.host === "staff.monteraplus.site";
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: "/"
    });
  }
  return (
    <div className={classes.logoWrap} style={isStagingSite ? { paddingBottom:  45 } : {}} onClick={handleClick}>
      {open ? (
        <img className={classes.logoOnOpen} src={isStagingSite ? stagingMainLogo : defaultMainLogo} alt="main logo" />
      ) : (
        <img className={classes.logoOnClose} src={isStagingSite ? stagingSmallMainLogo : defaultSmallMainLogo} alt="main logo" />
      )}
    </div>
  )
}
