import React, { useState, createContext } from "react";
import { useHistory } from "react-router-dom";
import {
  getPartnerInfo,
  getPartnerPrices,
  getPartnerInventory,
  postLineItem,
  getUnits,
  getPrice,
  SCollection,
  deleteLineItems,
  updateLineItems,
  getService,
  getCustomerInfo,
  updateLineItemsList
} from "./requsts";
import isEmpty from "lodash/fp/isEmpty";

import defaultState from "./defaultState";
import { aggregate, compare, customizer, operation } from "./utils";
import { getRecord, __getCollection } from "../../../utils/context/controllers/crud";
import { get, mergeWith } from "lodash";

// Plain empty context
export const LineItemsContext = createContext(defaultState);

const LineItemsStore = (props) => {
  const [LineItemsStore, setLineItemsStore] = useState(defaultState);
  const history = useHistory();

  const fetchUnits = async (id, type) => {
    setLoading({ units: true });
    const key =
      type === "s_details"
        ? await getService(id).then((res) => res.project.serviceOrder.key)
        : id;
    await getPartnerInfo(key).then(async (res) => {
      //todo need refactoring all same calls
      if (res.hasOwnProperty("partnerInfo")) {
        await getUnits(res.partnerInfo.partner.key)
          .then((units) => {
            setContextData("units", units);
            setLoading({ units: false });
          })
          .catch((err) => {
            setContextData("error", false);
            setContextData("errorMsg", err);
            console.log(err);
          });
      } else {
        history.goBack();
      }
    });
  };
  const fetchWriteinPrices = async (key) => {
    setLoading({writeinPrices: true});
    await getPartnerInfo(key).then(async (res) => {
      await getPrice("writein", res.partnerInfo.partner.key)
        .then((writeinPrices) => {
          if (writeinPrices.length > 0) {
            setContextData("writeinPrices", writeinPrices[0]);
            setContextData("currencySymbol", writeinPrices[0].currency.code);
            setLoading({ writeinPrices: false });
          }
        })
        .catch((err) => {
          setContextData("error", true);
          setContextData("errorMsg", err);
          console.log(err);
        });
    });
  };
  const getTargetKey = async (id, type) => {
    if (type === "s_details") {
      await getService(id).then((res) => {
        if (res.hasOwnProperty("errors")) {
          return id;
        }
        return res.project.serviceOrder.key;
      });
    }
    return id;
  };
  const fetchPriceCatalog = async (id, type) => {
    setLoading({ pricesCatalog: true });
    const key = await getTargetKey(id, type);
    await getPartnerInfo(key).then(async (res) => {
      if (!res.hasOwnProperty("partnerInfo")) return history.push("/");
      await getPartnerPrices(res.partnerInfo.partner.key)
        .then((res) => {
          setContextData(
            "priceCatalog",
            res[0].productCategoryItems.map((item) => ({
              name: item.productCategory.name,
              items: item.items.map((elem) => ({
                name: elem.catalogItem.defaultText,
                value: elem.priceInfo.default,
                key: elem.key,
                unit: elem.catalogItem.unit,
                isDecimalQuantity: elem.catalogItem.isDecimalQuantity
              }))
            }))
          );
          setLoading({ pricesCatalog: false });
        })
        .catch((err) => {
          setContextData("error", true);
          setContextData("errorMsg", err);
          console.log(err);
        });
    });
  };
  const fetchPartnerInfo = async (id) => {
    setLoading({partnerInfo: true, sellers: true});

    const data = await getPartnerInfo(id);
    const res = await getCustomerInfo(id);
    if (data.hasOwnProperty("partnerInfo")) {
      setContextData("partnerInfo", data.partnerInfo);
    }
    if (res.hasOwnProperty("customerInfo")) {
      setContextData("customerInfo", res.customerInfo);
    }

    const warehouseKey = get(data, 'partnerInfo.warehouse.key');
    if (warehouseKey) {
      const sellersRes = await __getCollection(`warehouses/${warehouseKey}/sellers/lookups`, { active: true });
      const sellers = sellersRes.sellers || [];
      setContextData("sellers", sellers);
    }

    const defaultSellerId = get(data, 'partnerInfo.seller.key', null);
    setContextData("defaultSellerId", defaultSellerId);

    setLoading({ partnerInfo: false, sellers: false });
  };
  const fetchPartnerInventory = async (id, type = "so_details") => {
    setLoading({ partnerInfo: true });
    const data = await getPartnerInventory(id, type);
    if (Array.isArray(data.serviceItems) && data.serviceItems.length > 0) {
      setContextData("partnerInventory", {
        serviceItems: data.serviceItems.map((ent) => ({
          ...ent,
          items: ent.items.map((elem) => ({
            item: elem.item,
            subRows: elem.childItems
          }))
        })),
        isEmpty: data.serviceItems.reduce((acc, cur) => cur.items > 0, false),
        serviceOrderItems: {
          ...data.serviceOrderItems,
          items: data.serviceOrderItems.items.map((ent) => ({
            ...ent,
            subRows: ent.childItems
          }))
        }
      });
    }
    if (!!data.serviceItems && !!data.serviceItems.items && data.serviceItems.items.length > 0) {
      setContextData("partnerInventory", {
        serviceOrderItems: {
          ...data.serviceItems,
          items: data.serviceItems.items.map((ent) => ({
            ...ent,
            subRows: ent.childItems
          })),
        }
      });
    }
    setLoading({ partnerInfo: false });
  };
  const fetchContractorPrices = async (key) => {
    setLoading({externalPrices: true});
    return await getPartnerInfo(key).then(async (res) => {
      await getPrice("contractor")
        .then((externalPrices) => {
          if (!!externalPrices[0] && externalPrices[0].items.length > 0) {
            setContextData(
              "externalPrices",
              externalPrices[0].productCategoryItems.map((item) => ({
                name: item.productCategory.name,
                items: item.items.map((elem) => ({
                  name: elem.catalogItem.defaultText,
                  value: elem.priceInfo.default,
                  key: elem.key,
                  unit: elem.catalogItem.unit,
                  isDecimalQuantity: elem.catalogItem.isDecimalQuantity
                }))
              }))
            );
          }
          setLoading({ externalPrices: false });
        })
        .catch((err) => {
          setLoading({externalPrices: false});
          setContextData("error", true);
          setContextData("errorMsg", err);
          console.log(err);
        });
    });
  };
  const fetchExternalPrices = async (key) => {
    setLoading({externalPrices: true});
    return await getPartnerInfo(key).then(async (res) => {
      await getPrice("external", res.partnerInfo.partner.key)
        .then((externalPrices) => {
          if (!!externalPrices[0] && externalPrices[0].items.length > 0) {
            setContextData(
              "externalPrices",
              externalPrices[0].productCategoryItems.map((item) => ({
              name: item.productCategory.name,
                items: item.items.map((elem) => ({
                name: elem.catalogItem.defaultText,
                value: elem.priceInfo.default,
                key: elem.key,
                unit: elem.catalogItem.unit,
                isDecimalQuantity: elem.catalogItem.isDecimalQuantity
              }))
            }))
            );
          }
          setLoading({ externalPrices: false });
        })
        .catch((err) => {
          setLoading({externalPrices: false});
          setContextData("error", true);
          setContextData("errorMsg", err);
          console.log(err);
        });
    });
  };
  const fetchServicesList = async (id, type) => {
    setLoading({ services: true });
    const list = await SCollection(id, type);
    if (list.hasOwnProperty("result")) {
      const data =
        type === "s_details"
          ? [{ ...list.result.lineItems, service: list.result.service }]
          : list.result.items;
      const total =
        type === "s_details" ? list.result.lineItems.total : list.result.total;
      setContextData("services", data.sort(compare));
      setContextData("total", total);
      if (
        LineItemsStore.relatedServices === "" &&
        data.length >= 1 &&
        !!list.result.service
      ) {
        setContextData(
          "relatedServices",
          type === "s_details"
            ? [list.result.service.key]
            : list.result.items[0].service.key
        );
      }
      setLoading({ services: false });
      return data;
    } else return [];
  };
  const reset = () => {
    setLineItemsStore(() => ({
      ...defaultState
    }));
  };
  const resetInvoice = () => {
    setLineItemsStore((prev) => ({
      ...prev,
      invoice: {
        lineItemsList: [],
        extraItemsList: [],
        writeinPrices: []
      },
      persistingDate: [],
      needRemove: []
    }));
  };
  const updateInvoice = (
    data,
    collection,
    behaviour = "inc",
    extraValue = {},
    target = {},

  ) => {
    setLineItemsStore((prev) => {
      const targetEl = prev.invoice[collection].find(
        (ent) => `${data.key}` === `${ent.key}`
      );

      if (!isEmpty(prev.invoice[collection]) && !!targetEl) {
        return {
          ...prev,
          invoice: {
            ...prev.invoice,
            [collection]: [
              ...prev.invoice[collection].map((ent) => {
                if (
                  `${data.key}` === `${ent.key}` &&
                  `${data.unitId}` === `${ent.unitId}`
                ) {
                  if (!isEmpty(extraValue)) {
                    return {
                      ...ent,
                      type: ent.type === "NEW" ? ent.type : "UPDATE",
                      ...extraValue
                    };
                  }
                  return {
                    ...ent,
                    type: ent.type === "NEW" ? ent.type : "UPDATE",
                    [target.name]: operation(ent.qty, behaviour, target.value)
                  };
                }
                return ent;
              })
            ]
          }
        };
      }

      const { defaultSellerId, sellers } = prev;

      let seller;
      if (defaultSellerId) {
        seller = sellers.find(x => x.key === defaultSellerId);
      }

      return {
        ...prev,
        invoice: {
          ...prev.invoice,
          [collection]: [
            ...prev.invoice[collection],
            {
              type: "NEW",
              ...data,
              qty: 1,
              provisions: null,
              lineItemType: 1,
              sellerId: defaultSellerId,
              seller
            }
          ]
        }
      };
    });
  };

  const addItemToInvoice = (data, collection) => {
    setLineItemsStore((prev) => {
      const { defaultSellerId, sellers } = prev;

      let seller;
      if (defaultSellerId) {
        seller = sellers.find(x => x.key === defaultSellerId);
      }

      return {
        ...prev,
        invoice: {
          ...prev.invoice,
          [collection]: [
            ...prev.invoice[collection],
            {
              type: "NEW",
              ...data,
              qty: 1,
              serviceKey: prev.invoice,
              lineItemType: 1,
              sellerId: defaultSellerId,
              seller
            }
          ]
        }
      };
    });
  };
  const save = async () => {
    if (LineItemsStore.needRemove.length > 0) {
      await deleteLineItems(LineItemsStore.needRemove.map(ent => ent.key));
      setContextData("needRemove", []);
    }

    setLoading({saving: true});
    let data = [];

    [{id: `${!!LineItemsStore.relatedServices
        ? LineItemsStore.relatedServices
        : LineItemsStore.services[0].service.key
      }`, invoice: LineItemsStore.invoice },
      ...LineItemsStore.persistingDate.filter((ent => `${LineItemsStore.relatedServices}` !== `${ent.id}`))]
      .map((ent) => {
      for (let prop in defaultState.invoice) {
        if (Array.isArray(ent.invoice[`${prop}`]) && ent.invoice[prop].length > 0) {
          const temp = ent.invoice[`${prop}`].map(item => {
            const elemData = {
              ...item,
              service: {
                key: ent.id
              },
              priceListItem: {
                key: item.key
              },
              id: item.key,
              lineItemType: item.lineItemType,
              priceListItemType: item.priceListItemType,
              description: !!item.description ? item.description :'',
              producer: null,
              kickback: "0",
              estimatedAssemblyTime: null,
              qty: item.qty
            };
            if (prop === "writeinPrices" ) {
              return {
                ...elemData,
                writeInPrice: item.writeInPrice,
                lineItemType: item.lineItemType,
                writeInDesc: !!item.writeInDesc ? item.writeInDesc : "",
                writeInVat: item.writeInVat,
                writeInUnitId: item.unitId,
                writeInUnitName: item.unitName
              };
            }
            return elemData;
          });
          data = [...data, ...temp];
        }
      };
      return true;
    });
    await postLineItem(data.filter((ent) => ent.type === "NEW"));
    await updateLineItemsList(data.filter((ent) => ent.type === "UPDATE"));

    setContextData("invoice", {
      lineItemsList: [],
      extraItemsList: [],
      writeinPrices: []
    });
    setContextData("persistingDate", defaultState.persistingDate);
    setLoading({ saving: false });
  };

  const handleDelete = async (ids, type, key) => {
    setLoading({ deleting: true });
    await deleteLineItems([ids]).then(async () => {
      await fetchServicesList(key, type).then(() => {
        handleModal({ open: "" });
      });
    });
    setLoading({ deleting: false });
  };
  const fetchSOInfo = async (key, type) => {
    setLoading({SOInfo: true});
    await getRecord("ServiceOrders", key).then((res) => {
      if (!!res.serviceOrder) {
        setLineItemsStore((prev) => ({
          ...prev,
          SOInfo: res.serviceOrder
        }));
      }
    });
    setLoading({ SOInfo: false });
  };
  const handleEdit = (data, priceListItemType) => {
    const editData =
      LineItemsStore.modal.row.priceListItemType === 3 ||
      LineItemsStore.modal.row.hasOwnProperty("writeInPrice")
        ? {
          ...data,
          writeInUnitName: LineItemsStore.modal.row.unitName,
          writeInUnitId: LineItemsStore.modal.row.unitId
        }
        : {
            ...data
        };
    setLineItemsStore((prev) => ({
      ...prev,
      editData: {
        ...LineItemsStore.modal.row,
        ...prev.editData,
        ...editData,
        priceListItemType
      }
    }));
  };
  const handleEditDecimal = (data, priceListItemType) => {
    setLineItemsStore((prev) => ({
      ...prev,
      editData: {
        ...LineItemsStore.modal.row,
        ...prev.editData,
        qty: operation(0,"custom",data.qty),
        priceListItemType
      }
    }));
  }
  const saveEdit = async (key, type) => {
    if (isEmpty(LineItemsStore.editData)) return ;
    setLoading({edit: true});
    const editData =
      LineItemsStore.modal.row.priceListItemType === 3 ||
      LineItemsStore.modal.row.hasOwnProperty("writeInPrice")
        ? {
          ...LineItemsStore.editData,
          writeInUnitName: LineItemsStore.modal.row.unitName,
          writeInUnitId: LineItemsStore.modal.row.unitId,
          qty: !!LineItemsStore.editData.qty
            ? String(LineItemsStore.editData.qty).replace(/,/g, '.')
            : 0
        }
        : {
            ...LineItemsStore.editData,
            qty: !!LineItemsStore.editData.qty
              ? String(LineItemsStore.editData.qty).replace(/,/g, '.')
              : 0
        };
    await updateLineItems(
      LineItemsStore.modal.row.key,
      editData
    ).then(async () => {
        await fetchServicesList(key, type).then(() => {
          handleModal({open: ""});
          setLineItemsStore((prev) => ({
            ...prev,
            editData: {}
          }));
        });
    });
    setLoading({edit: false});
  };
  const prepareForUpdateLineItem = async (
    key,
    type,
    target,
    cleanOk = false
  ) => {
    if (cleanOk) {
      setLineItemsStore((prev) => {
        return {
          ...prev,
          invoice: defaultState.invoice,
          persistingDate: defaultState.persistingDate,
          needRemove: []
        };
      });
    }
    setLoading({ update: true });
    setContextData("invoice", {
      lineItemsList: [],
      extraItemsList: [],
      writeinPrices: []
    });
    await SCollection(key, type)
      .then((res) => {
        if (res.hasOwnProperty("errors")) {
          return [];
        }
        const list = res.result.items.sort(compare);

        if (Array.isArray(list) && list.length > 0) {
          setLineItemsStore((prev) => {
            return {
              ...prev,
              persistingDate: !cleanOk
                ? aggregate(
                    prev,
                    !!LineItemsStore.relatedServices
                  ? LineItemsStore.relatedServices
                      : list[0].service.key,
                    LineItemsStore
                  )
                : []
            };
          });

          const data = !!target
            ? list.find((ent) => `${ent.service.key}` === `${target}`)
            : list[0];
          if (
            !isEmpty(data) &&
            (data.partnerLineItems.length > 0 ||
              data.customerLineItems.length > 0)
          ) {
            const existingData = [
              ...data.partnerLineItems,
              ...data.customerLineItems
            ];
            if (existingData.length > 0) {
              let pool = {
                writeinPrices: [],
                lineItemsList: [],
                extraItemsList: []
              };
              existingData.forEach(ent => {
                if (LineItemsStore.needRemove.map(ent => ent.key).indexOf(Number(ent.key)) >= 0) return;
                if (
                  ent.hasOwnProperty("writeInPrice") ||
                  ent.priceListItemType === 3
                ) {
                  pool.writeinPrices.push({
                    ...ent,
                    type: "LOADED",
                    value:
                      ent.writeInPrice ||
                      ent.priceCustomer ||
                      ent.pricePartner ||
                      ent.summa ||
                      0
                  });
                }
                if (ent.priceListItemType === 1) {
                  pool.lineItemsList.push({
                    ...ent,
                    type: "LOADED",
                    value: ent.priceCustomer
                  });
                }
                if (ent.priceListItemType === 2) {
                  pool.extraItemsList.push({
                    ...ent,
                    type: "LOADED",
                    value: ent.priceCustomer
                  });
                }
              });


              setLineItemsStore(prev => {
                return {
                  ...prev,
                  relatedServices: isEmpty(prev.relatedServices)
                    ? prev.relatedServices
                    : list[0].service.key,
                  existingData: existingData.map((ent) => ent.key),
                  invoice:
                    !!LineItemsStore.persistingDate.find(
                      (elem) => elem.id === target
                    ) && !cleanOk
                      ? mergeWith(
                          pool,
                          LineItemsStore.persistingDate.find(
                            (elem) => elem.id === target
                          ).invoice,
                          customizer
                        )
                      : pool
                };
              });
            }
          } else {
            setContextData("updateScenario", true);
            setLineItemsStore((prev) => {
              return {
                ...prev,
                invoice: !!LineItemsStore.persistingDate.find((elem) => elem.id === target)
                ? LineItemsStore.persistingDate.find((elem) => elem.id === target).invoice
                : prev.invoice,
                relatedServices: !!target
                  ? target
                  : list.length > 0
                  ? list[0].service.key
                  : ""
              };
            });
          }
        }
      })
      .then(() => {
        setLoading({ update: false });
      });
  };
  const increaseInvoice = (data, collection,target) => {
    updateInvoice(data, collection, "inc", null, target);
  };
  const decreaseInvoice = (data, collection,target) => {
    if (data.qty <= 1) return;
    updateInvoice(data, collection, "dec", null, target);
  };
  const setDecimalQuantity = (data, collection, target) => {
    updateInvoice(data, collection, "custom",null, target )
  };
  const handleModal = (data) => {
    setLineItemsStore((prev) => ({
      ...prev,
      editData: {},
      modal: {
        ...data
      }
    }));
  };
  const removeInvoice = (key, collection, serviceId, extraTarget = {}) => {
    const val = LineItemsStore.existingData.indexOf(key) >= 0;
    const condition = (item) => isEmpty(extraTarget)
      ? `${item.key}` !== `${key}`
      : !(`${item.key}` === `${key}` && `${item[extraTarget.target]}` === `${extraTarget.value}`)
    setLineItemsStore((prev) => ({
      ...prev,
      invoice: {
        ...prev.invoice,
        [collection]: [...prev.invoice[collection].filter(condition)],
      },
      needRemove: val
        ? [...prev.needRemove, { serviceId, key }]
        : [...prev.needRemove]
    }));
  };
  const setLoading = (data) => {
    setLineItemsStore((prev) => ({
      ...prev,
      loading: {
        ...prev.loading,
        ...data
      }
    }));
  };

  const setContextData = (key, data) => {
    setLineItemsStore((prev) => ({
      ...prev,
      [key]: data
    }));
  };

  const getInvoicedOptions = () => {
    __getCollection("lineitems/invoiced/lookups")
    .then(({invoiced}) => {
      setLineItemsStore((prev) => ({
        ...prev,
        invoicedLookup: [...invoiced]
      }));
    });
  }

  const LineItemsCApi = {
    setData: setContextData,
    updateLoading: setLoading,
    setDecimalQuantity,
    increaseInvoice,
    decreaseInvoice,
    addItemToInvoice,
    updateInvoice,
    removeInvoice,
    getInvoicedOptions,
    prepareForUpdateLineItem,
    fetchPartnerInventory,
    fetchExternalPrices,
    fetchContractorPrices,
    fetchWriteinPrices,
    fetchPriceCatalog,
    fetchServicesList,
    fetchPartnerInfo,
    fetchSOInfo,
    fetchUnits,
    handleDelete,
    resetInvoice,
    handleModal,
    handleEdit,
    handleEditDecimal,
    saveEdit,
    save,
    reset
  };
  return (
    <LineItemsContext.Provider
      value={{
        LineItemsStore,
        setLineItemsStore,
        LineItemsCApi
      }}
    >
      {props.children}
    </LineItemsContext.Provider>
  );
};

export { LineItemsStore };
