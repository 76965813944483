import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../utils/context/AppStore";
import { Link } from "react-router-dom";
import {
  CircularProgress,
  Grid,
  Button,
  makeStyles,
  MenuItem,
  Typography,
  Paper,
  Backdrop,
  Avatar,
} from "@material-ui/core";
import { theme } from "../../utils/styleTheme/mainStyles";
import Divider from "@material-ui/core/Divider";
import MonteraDialog from "../../assets/reuseComponents/MonteraDialog";
import { useMsal } from '@azure/msal-react';
import { InvertLoading } from '../Suspense';
import { useTranslation } from 'react-i18next';
import useStoreCommentDraft from '../../utils/hooks/useStoreCommentDraft';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: 183,
      borderRadius: 0,
      position: "absolute",
      right: theme.spacing(1),
    },
    menuIcon: {
      display: "block",
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    userLogo: {
      marginRight: theme.spacing(1)
    },
    listItem: {
      height: "100%",
      color: theme.palette.text.primary,
      borderRadius: 0,
      padding: `0 ${theme.spacing(2)}px`,
      textTransform: "none",
      fontWeight: "normal",
    },
    logOutButton: {
      fontWeight: 400,
      width: 150,
      padding: theme.spacing(2),
    },
    backdrop: {
      zIndex: 999,
      backgroundColor: "#ffffff",
      opacity: 0.5,
    },
    loader: {
      color: theme.palette.primary.main,
    },
    loading: {
      margin: 0,
      height: "calc(100vh - 58px)"
    }
  };
});

const TopNavUser = () => {
  // HELPERS
  const classes = useStyles();
  const { GlobalStore } = useContext(AppContext);
  const { t } = useTranslation()
  const [loading, setLoading] = useState("LOADING");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(true);
  const {clearAllDrafts} = useStoreCommentDraft();
  const { instance } = useMsal();
  const open = Boolean(anchorEl);
  // DATA
  const [user, setUserData] = useState(null);

  const toggleDataLoaded = (val) => {
    setDataLoaded(val);
  };

  // START
  useEffect(() => {
    setUserData({
      ...GlobalStore.userData,
    });
    setLoading("LOADED");
  }, [GlobalStore.userData]);

  useEffect(() => {
    if (GlobalStore.translationsLoaded) {
      toggleDataLoaded(false);
    }
  }, [GlobalStore.translationsLoaded]);

  // Open Dialog
  const handleClickDialogOpen = () => {
    setDialogOpen(true);
  };
  // Close Dialog
  const handleDialogClose = async () => {
    setDialogOpen(false);
    setAnchorEl(null);
  };
  // Child menu
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Close Child Menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Does this belong here?
  const signOut = () => {
    clearAllDrafts();
    instance.logout();
  };



  const userLogoText = (name) => {
    if(name){
        return name.split(" ").map(word => (word || "").substring(0, 1))
    } else return "";
  };

  const NavIcon = (link = "", labelKey = "", Icon) => {

    return (
      <MenuItem component={Link} color="textPrimary" to={link}>
        {Icon}
        <Typography style={{ fontWeight: 400 }} variant="button">
          {t(`common:all.labels.navigation.${labelKey}`)}
        </Typography>
      </MenuItem>
    );
  };

  return (
    <>
      {loading === "LOADED" && (
        <>
          <div
            onMouseLeave={handleClose}
            onMouseEnter={(e) => handleMenu(e)}
            onClick={(e) => handleMenu(e)}
          >
            <Grid container style={{ height: 56 }}>
              <Button
                disableRipple
                variant="text"
                color="primary"
                className={classes.listItem}
              >
                <Avatar className={classes.userLogo}>
                    {userLogoText(user.name)}
                </Avatar>
                <Typography noWrap={true}>
                  {user.firstName} {user.lastName}
                </Typography>

                {!open ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ margin: `0 0 0 ${theme.spacing(1)}px` }}
                  >
                    <path
                      d="M3.2002 5.33337L8.0002 10.1334L12.8002 5.33337"
                      stroke="#141E30"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ margin: `0 0 0 ${theme.spacing(1)}px` }}
                  >
                    <path
                      d="M12.8 9.59961L8.00005 4.79961L3.20005 9.59961"
                      stroke="#141E30"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </Button>
            </Grid>
            <Paper className={classes.root} hidden={!open}>
              {NavIcon(
                "/profile",
                "open_profile",
                <svg
                  className={classes.menuIcon}
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3333 16V14.3333C14.3333 12.4924 12.8409 11 11 11H4.33333C2.49238 11 1 12.4924 1 14.3333V16"
                    stroke="#2B59C3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.66683 7.66667C9.50778 7.66667 11.0002 6.17428 11.0002 4.33333C11.0002 2.49238 9.50778 1 7.66683 1C5.82588 1 4.3335 2.49238 4.3335 4.33333C4.3335 6.17428 5.82588 7.66667 7.66683 7.66667Z"
                    stroke="#2B59C3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <Divider />
              <MenuItem onClick={handleClickDialogOpen}>
                <svg
                  className={classes.menuIcon}
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.6 17H2.6C1.71634 17 1 16.2837 1 15.4V2.6C1 1.71634 1.71634 1 2.6 1H6.6"
                    stroke="#2B59C3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.2002 12.2008L15.4002 9.00078L12.2002 5.80078"
                    stroke="#2B59C3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.3998 9H5.7998"
                    stroke="#2B59C3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <Typography style={{ fontWeight: 400 }} variant="button">
                  {t("common:all.labels.navigation.logout")}
                </Typography>
              </MenuItem>

            </Paper>
          </div>
          <MonteraDialog
            title={
              <Typography variant="h3" component={"div"}>
                {t(
                  "common:all.labels.navigation.logout_window_text"
                )}
              </Typography>
            }
            open={isDialogOpen}
            onClose={handleDialogClose}
            showCloseIcon={false}
            titleStyles={{
              padding: "40px 40px 24px 40px",
              fontWeight: 400,
            }}
            contentStyles={{ padding: "0px 40px 40px 40px" }}
          >
            <Button
              onClick={signOut}
              className={classes.logOutButton}
              variant="contained"
              color="primary"
              autoFocus
            >
              {t("common:all.labels.navigation.logout_button_yes")}
            </Button>
            <Button
              onClick={handleDialogClose}
              className={classes.logOutButton}
              color="secondary"
              variant="text"
              style={{ marginLeft: 16 }}
            >
              {t("common:common.labels.cancel")}
            </Button>
          </MonteraDialog>
        </>
      )}
      {loading !== "LOADED" && (
        <Grid container justifyContent="center">
          <CircularProgress size={20} color="primary" />
        </Grid>
      )}

      <Backdrop
        className={classes.backdrop}
        open={dataLoaded}
        onClick={() => {}}
      >
        <InvertLoading className={classes.loading} />
      </Backdrop>
    </>
  );
};

export default TopNavUser;
