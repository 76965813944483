import { useFeatureFlag, useFeatureFlagsLoading } from "src/utils/context/FeatureFlagProvider";
import { Features } from '../context/FeatureFlagProvider/types';
import {  useHotkeys } from 'react-hotkeys-hook';
import { useEffect, useState } from 'react';

const useHotkeysFlag = (hotkey: string, callback:() => null, ) => {
    const flagsLoading = useFeatureFlagsLoading();
    const activeFeatureFlag = useFeatureFlag(Features.hotkeys);
    const [activeHotkeys, setActiveHotkeys] = useState(false);
    useHotkeys(hotkey, () => setActiveHotkeys(prev => !prev));
    useEffect(() => { 
        if(!flagsLoading && !!activeFeatureFlag && activeHotkeys ) {
            callback();
        }
        return () => setActiveHotkeys(false)
    }, [activeHotkeys]);
};

export default useHotkeysFlag;