import React, { useEffect, useState, useRef} from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const MonteraTruncatedText = ({
  text = "",
  maxWidth = "4000px",
  placement = "top",
}) => {
   const { t } = useTranslation()
  const [truncated, setTrancated] = useState(false);
  const [classes, setClass] = useState(null);
  const [height, setHeight] = useState(0);
  const myRef = useRef();

  useEffect(() => {
    setHeight(myRef.current.clientHeight);
    setTrancated((t) => !t);
  }, []);

  useEffect(() => {
    myRef.current.clientHeight < height && setClass({ cursor: "pointer" });
  }, [truncated, height]);
  return (
    <Tooltip
      placement={placement}
      title={
        Boolean(classes)
          ? truncated
            ? t("common:common.labels.show_more")
            : t("common:common.labels.show_less")
          : ""
      }
    >
      <Typography
        variant="inherit"
        component="div"
        ref={myRef}
        style={{ ...classes, maxWidth: maxWidth, wordBreak: "break-word" }}
        noWrap={truncated}
        onClick={() => setTrancated(!truncated)}
        children={text}
      />
    </Tooltip>
  );
};

export default MonteraTruncatedText;
