export const PERMISSIONS = {
    TIME_SHEET_READ: 131,
    CHANGE_TASK_INVOICE_CLAIM: 141
}

export const ROLES = {
    ADMIN: 1,

    ACCOUNT_MANAGER: 5,
    ACCOUNTING: 10
};