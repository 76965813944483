export const HOTKEYS = {
    LEFT_NAVBAR: "alt+n",
    ORDERS_PAGE: "alt+o",
    ORDERS_PAGE_COMPLETED_ORDERS_TAB: "alt+c",
    OPEN_FILTER: "o+f",
    OPEN_SORTING: "o+s",
    CREATE_WORK_ORDER: "c+w",
    CREATE_SERVICE_ORDER: "c+s",
    ADD_FILE: "c+f",
    ADD_SERVICE: "c+s",
    ADD_JOB: "c+j"
}