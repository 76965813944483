import i18next from "i18next";
import Backend from 'i18next-chained-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend'; // fallback xhr load
import { initReactI18next } from "react-i18next"
const Languages = ["en", "da", "fi", "nb", "sv"];
const __translationDomains = JSON.parse(process.env.REACT_APP_TranslationDomains);

const i18n = i18next

const mapLoadPath = loadPath => loadPath.replace('{{t}}', new Date().getTime());

const Backends = __translationDomains
  .map(loadPath => ({
    backend: XHR,
    options: {
      allowMultiLoading: false,
      crossDomain: true,
      loadPath: () => mapLoadPath(loadPath),
    }
  }));

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: Languages,
    ns: 'common',
    defaultNS: 'common',
    fallbackLng: false,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: Backends.map(x => x.backend),
      backendOptions: Backends.map(x => x.options)
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  }, (error, t) => {
    if (error) {

      console.log('error');
      console.error(error);
    }
  });

export default i18n;
