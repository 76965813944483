import { makeStyles  } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  box: {
    width: 560,
    padding: '40px',
    margin: "0 auto"
  },
  wrapperImg: {
    display: "flex",
    justifyContent: "center"
  },
  img: {
    width: '150px',
    height: '184px',
  },
  boldText: {
    fontSize: "14px",
    paddingLeft: '4px',
    color: theme.palette.primary.main
  },
  offset: {
    padding: '16px 0'
  },
  subfont: {
    fontSize: "14px",
    lineHeight: "130%",

    color: " rgba(20, 30, 48, 0.5)",
    letterSpacing: "0.0025em",
  },
  subfont2: {
    fontSize: "12px",
    lineHeight: "100%",
    letterSpacing: "0.004em",
    margin: 0,
    color: " rgba(20, 30, 48, 0.5)",
  },
  btnGroup: {
    marginTop: theme.spacing(3)
  },
  submitBtn: {
    padding: theme.spacing(2),
    fontSize: "14px !important",
    color: "#ffff",
    backgroundColor: theme.palette.primary.main
  },
  cancelBtn: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    fontSize: "14px !important",
    color: theme.palette.primary.main
  }
}));