import React, { useState, useCallback } from "react";
import { __getAuthObject } from "./controllers/authProvider";
import {
  getRecord,
  deleteRecord,
  deleteFileFromFileService,
  getMetaData,
} from "./controllers/crud";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../styleTheme/mainStyles";
import moment from "moment-timezone";

import "moment/locale/nb";
import "moment/locale/sv";
import "moment/locale/da";
import "moment/locale/fi";
import "moment/locale/en-gb";
import { useHistory } from "react-router-dom";
import { useBrowserFullscreen } from "../hooks/useBrowserFullscreen";
import i18next from "i18next";

export const config = {
  auth: {
    authority: process.env.REACT_APP_CONFIG_Authority,
    clientId: process.env.REACT_APP_CONFIG_ClientID,
    redirectUri: process.env.REACT_APP_CONFIG_Redirect_URI,
    postLogoutRedirectUri: process.env.REACT_APP_CONFIG_Post_LogoutRedirect_URI,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CONFIG_Cache_Localtion,
    storeAuthStateInCookie:
      process.env.REACT_APP_CONFIG_Store_Auth_State_In_Cookie === "true",
  },
};

const defaultState = {
  authObject: {},
  userData: {},
  territoryLocale: "",
  languageLocale: "en",
  currency: {
    code: "EUR",
    id: 840,
    name: "UnitedStatesDollar",
    nameLabel: null,
    nameLabelId: "UnitedStatesDollar",
    symbol: "$",
  },
  momentLocale: "en-gb",
  momentTimezone: "Europe/Paris",
  error: false,
  translationsLoaded: false,
  fullScreenMode: false,
  setFullScreenMode: (on) => {},
  onJobDeleteTrigger: false
};
// Plain empty context
export const AppContext = React.createContext(defaultState);

const AppStore = (props) => {
  // Export to config file
  const momentLocaleMap = {
    en: "en-gb",
    no: "nb",
    se: "sv",
    dk: "da",
    fi: "fi",
  };
  const his = useHistory();
  // Default State
  const [GlobalStore, setGlobalStore] = useState(defaultState);
  // Setting default values for Moment & Moment Timezone.
  moment.tz.setDefault("Europe/Paris");


  // Sets Global Localization Settings for current user. userData required parameter - Object, with data to be saved globally.
  // returns string - locale to be used for translation settings, iso2 format.
  const setLocale = async (userData) => {
    if (userData && userData.key) {
      setGlobalStore((prev) => ({
        ...prev,
        userData: userData,
        territoryLocale: userData.territory.alpha2,
        languageLocale: userData.locale.iso2,
        momentLocale: momentLocaleMap[userData.locale.iso2],
      }));

      return userData.locale.iso2;
    } else {
      console.error(
        "Problems with userData inside App Store. Contact Administrator."
      );
      return false;
    }
  };

  const getAuthObject = async (authObject) => {
    return await __getAuthObject(authObject).then((authObject) => {
      setGlobalStore((prev) => ({
        ...prev,
        authObject: authObject,
      }));
    });
  };

  const getUserData = async () => {
    return await getRecord("Profile/login", "").then((response) => {
      if (response.profile) {
        sessionStorage.setItem("userKey", response.profile.key);
        setGlobalStore((prev) => ({
          ...prev,
          userData: response.profile,
        }));
        return response.profile;
      } else {
        setGlobalStore((prev) => ({ ...prev, error: true }));
        console.log("Error getting user Data.");
      }
    });
  };

  // Handler to update GlobalStore
  const setContextData = (key, data) => {
    setGlobalStore((prev) => ({
      ...prev,
      [key]: data,
    }));
  };

  const deleteUploadedFiles = () => {
    if (GlobalStore.dropZoneBoofer && !GlobalStore.dropZoneBoofer.submited) {
      const files = GlobalStore.dropZoneBoofer.files || [];
      files.map(async (file, index) => {
        // delete from portal-api
        const t1 = await deleteRecord("files", file.key);
        // delete from file service
        const t2 = await deleteFileFromFileService(file.fileServiceId);
        if (t1 && t2 && index + 1 === files.length) {
          setContextData("dropZoneBoofer", {});
        }
      });
    } else {
      setContextData("dropZoneBoofer", {});
    }
  };

  // Function initializes Localization and Globalization settings for Application
  const initializeAppStore = async () => {
    const userData = await getUserData();
    if (!userData) {
      return;
    }
    await setLocale(userData);
    updateMomentLocale(userData);
    updateI18next(userData);
    const localesData = await getMetaData("lookups/locales/");
    // Hides the Preloader overlay screen
    setGlobalStore((prev) => ({
      ...prev,
      ...localesData.find((ent) => ent.iso2 === userData.locale.iso2),
      localesData,
      translationsLoaded: true,
    }));
  };
  
  const updateLocaleData = async (locale) => {
    setGlobalStore((prev) => ({
      ...prev,
      ...GlobalStore.localesData.find((ent) => ent.iso2 === locale),
      territoryLocale: locale.iso2,
      languageLocale: locale.iso2,
      momentLocale: momentLocaleMap[locale.iso2],
      userData: {
        ...prev.userData,
        locale: {
          key: locale?.id,
          ...locale
        }
      }
    }));

    const { userData } = GlobalStore;
    updateMomentLocale({ ...userData, locale });
  };

  const updateMomentLocale = (userData) => {
    moment.updateLocale(userData.locale.iso2, {
      week: {
        dow: 1
      },
    });

    if (userData.territory.alpha2 === "se") {
      moment.updateLocale(momentLocaleMap[userData.territory.alpha2], {
        longDateFormat: {
          L: "YYYY-MM-DD",
          LL: "YYYY-MM-DD HH:mm",
        },
      });
    } else {
      moment.updateLocale(momentLocaleMap[userData.territory.alpha2], {
        longDateFormat: {
          L: "DD.MM.YYYY",
          LL: "DD.MM.YYYY HH:mm",
        },
      });
    }
  }

  const updateI18next = (userData) => {
    i18next.changeLanguage(userData.locale.iso2)
  }

  // onClick event handler for a "Go Back" button.
  const goBackBySessionHistory = (
    pathIfURLNotExists /* - default URL in case if the client has no URL history. For example: "/orders" */
  ) => {
    const sessionHistory = JSON.parse(sessionStorage.historyArray); // get a lient history from Session Storage
    if (sessionHistory.length > 1) {
      // if a client has URL history
      sessionHistory.pop(); // remove last element from the array.  This section can be changed if the logic gonna be expands.
      setContextData("historyArray", sessionHistory); // update the Breadcrumbs path
      sessionStorage.setItem("historyArray", JSON.stringify(sessionHistory)); // update Session Storage value
      his.push(sessionHistory[sessionHistory.length - 1]); // regirect
    } else if (pathIfURLNotExists) {
      his.push(pathIfURLNotExists); // regirent to default URL. For example: "/orders"
    } else return null; // don't do anything if no default URL and history
  };
  // Initializes the app if detects user logged in. Otherwise User has to go through Login process.

  const checkUserHasPermission = useCallback((permission) => {
    const userPermissions = GlobalStore.userData.permissions ?? [];
    return userPermissions.some(userPermission => userPermission.key === permission);
}, [GlobalStore.userData.permissions]);

  const ContextAPI = {
    getAuthObject: getAuthObject,
    getUserData: getUserData,
    updateLocaleData,
    setData: setContextData,
    deleteFiles: deleteUploadedFiles,
    goBackBySessionHistory,
    initializeAppStore,
    checkUserHasPermission
  };

  const [fullScreenMode, setFullScreenMode] = useState(false);

  useBrowserFullscreen(fullScreenMode, setFullScreenMode);

  // Pass down the state
  return (
    <AppContext.Provider
      value={{
        GlobalStore,
        setGlobalStore,
        ContextAPI,
        fullScreenMode,
        setFullScreenMode,
      }}
    >
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </AppContext.Provider>
  );
};

export { AppStore };
