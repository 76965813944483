import React, {  } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import NameAvatar from './NameAvatar';
import CommentItemHeader from './CommentItemHeader';
import CommentContent from './CommentContent';
import CommentInfo from './CommentInfo';

const CommentItem = ({
    previewImageClassName,
    comment
}) => {
    const { isRead } = comment;
    const classes = useStyles({ isRead });

    return (
        <>
            <CommentItemHeader
                className={classes.container}
                comment={comment}
            />

            <div className={clsx(classes.logoContainer, classes.container)}>
                <NameAvatar
                    className={classes.userLogo}
                    name={comment.createdByName}
                />
            </div>

            <CommentContent
                className={classes.container} 
                previewImageClassName={previewImageClassName}
                comment={comment}
            />

            <CommentInfo
                className={classes.container} 
                comment={comment}
            />
            <Divider className={classes.divider} />
        </>
    )
}

const useStyles = makeStyles(theme => ({
    container: ({ isRead }) => ({
        background: isRead
            ? null
            : 'rgba(43, 89, 195, 0.05)'
    }),
    logoContainer: {
        padding: theme.spacing(1)
    },
    userLogo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 40,
        width: 40,
        borderRadius: 100,
        backgroundColor: "rgba(20, 30, 48, 0.05)",
        marginRight: theme.spacing(1),
        fontSize: 12,
        color: theme.palette.primary.dark
    },
    info: {
        padding: theme.spacing(1),
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gap: `${theme.spacing(1)}px`,
        alignItems: 'center',
        alignContent: 'flex-start'
    },
    noIcon: {
        gridColumn: 2
    },
    link: {
        color: '#2B59C3'
    },
    divider: {
        gridColumn: '1 / span 3',
    }
}));

export default CommentItem;