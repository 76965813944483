import { parse, stringify } from "qs";
import { useLocation, useHistory } from 'react-router-dom';

function useQueryString() {
    const history = useHistory();
    const location = useLocation();

    const query = parse(location.search, { ignoreQueryPrefix: true });

    const setQuery = (newQuery, { fresh = false } = {}) => {
        history.replace(location.pathname + stringify({ ...(fresh ? {} : query), ...newQuery }, { addQueryPrefix: true }));
    };

    return [query, setQuery];
}

export default useQueryString;