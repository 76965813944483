import React from "react";
import { MembershipIcon } from "../../images";
import { Location } from "history";
import FEATURES_FLAGS from "../../../../FeaturesFlags";
import { MyOrdersIcon } from "../../../../assets/imgs";
import {
  ContractorIcon,
  CustomerIcon, FollowupsIcon,
  OrdersIcon,
  PartnersIcon,
  UsersIcon,
  ReportsIcon,
  TimesheetIcon,
  QuestionnaireIcon,
  WarehousesIcon
} from "../../images";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { PERMISSIONS } from 'src/assets/reuseComponents/MonteraGuards/constants';
import { Features, AvailableFeatureFlagKeys } from 'src/utils/context/FeatureFlagProvider/types';

type ListType = {
  label: string;
  link: string;
  Icon: JSX.Element;
  permission?: number; 
  featureFlag?: AvailableFeatureFlagKeys
}
interface PathProps {
  location: Location,
  searchQuery: string
}

export const jobDispatching = ({ location, searchQuery }: PathProps) => {
  const list: ListType[] = [
      {
        label: "calendar",
        link:
          location?.pathname === "/calendar"
            ? `/calendar${searchQuery}`
            : "/calendar",
        Icon: <TimesheetIcon/>
      },
      {
        label: "reports",
        link: location?.pathname === "/reports"
          ? `/reports${searchQuery}`
          : "/reports",
        Icon: <ReportsIcon/>
      }
    ]

  if (FEATURES_FLAGS?.QUESTIONNAIRE)  {
    list.push({
      label: "questionnaire",
      link: location?.pathname === "/questionnaire"
        ? `/questionnaire${searchQuery}`
        : "/questionnaire",
      Icon: <QuestionnaireIcon/>
    });
    
  }
  list.push({
    label: "timesheets",
    link: location?.pathname === "/timesheet"
      ? `/timesheet${searchQuery}`
      : "/timesheet",
    Icon: <TimesheetIcon/>,
    permission: PERMISSIONS.TIME_SHEET_READ,
    featureFlag: Features.timesheetEditing,
  })
  return ({
    title: "job_dispatching",
    list
  });
};


export const userManagement = ({ location, searchQuery }: PathProps) => ({
  title: "user_management",
  list: [
  {
      label: "users",
      link:
        location?.pathname === "/users" ? `/users${searchQuery}` : "/users",
      Icon: <UsersIcon/>
    },
    {
      label: "contractors",
      link:
        location?.pathname === "/contractors" ? `/contractors${searchQuery}` : "/contractors",
      Icon: <ContractorIcon/>
    }, {
      label: "security",
      Icon: <MembershipIcon />,
      link:
        location?.pathname === "/teams" ? `/teams${searchQuery}` : "/teams",
      subItems: [
        {
          label: "work_teams",
          link:
            location?.pathname === "/teams" ? `/teams${searchQuery}` : "/teams",
          Icon: null
        },
        {
          label: "business_units",
          link: location?.pathname === "/business-units"
            ? `/business-units${searchQuery}`
            : "/business-units",
          Icon: null
        },
      ]}
    ]
});

export const myWork = ({ location, searchQuery }: PathProps) => ({
  title: "my_work",
  list: [
    {
      label: "orders",
      link:
        location?.pathname === "/orders" ? `/orders${searchQuery}` : "/orders",
      Icon: <OrdersIcon />,
    },
    {
      label: "my_orders",
      link: location?.pathname === "/my-orders"
        ? `/my-orders${searchQuery}`
        : "/my-orders",
      Icon: <MyOrdersIcon />
    },
    {
      label: "followups",
      link: location?.pathname === "/followups"
        ? `/followups${searchQuery}`
        : "/followups",
      Icon: <FollowupsIcon />
    }
  ]
});

export const accountManagement = ({ location, searchQuery }: PathProps) => ({
  title: "account_management",
  list: [
     {
      label: "customers",
      link:
        location?.pathname === "/customers" ? `/customers${searchQuery}` : "/customers",
      Icon: <CustomerIcon />
    },
    {
      label: "partners",
      link:
        location?.pathname === "/partners" ? `/partners${searchQuery}` : "/partners",
      Icon: <PartnersIcon />
    },
    {
      label: "warehouses",
      link:
        location?.pathname === "/warehouses" ? `/warehouses${searchQuery}` : "/warehouses",
      Icon: <WarehousesIcon />
    },
  ]
});
export const accounting = ({ location, searchQuery }: PathProps) => ({
  title: "accounting",
  list: [
    {
      label: "invoicing",
      Icon: <LocalAtmIcon style={{ color: 'rgba(255, 255, 255, 0.5)' }} />,
      link:
        location?.pathname === "/invoicing" ? `/invoicing${searchQuery}` : "/invoicing",
    }
  ]
});

