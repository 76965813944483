import { Avatar } from '@material-ui/core';
import React from 'react';

const NameAvatar = ({ name = '', className }) => {
    let initials = '';
    if(name){
        initials =  name.split(" ").map(word => (word || "").substring(0, 1))
    };
    return (
        <Avatar className={className}>
            {initials}
        </Avatar>
    )
}

export default NameAvatar;