import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  btnGroup: {
    marginTop: theme.spacing(1),
  },
  submitBtn: {
    padding: theme.spacing(2),
    minWidth: 120,
    fontSize: "14px !important",
    color: "#ffff",
    backgroundColor: "#2B59C3",
  },
  cancelBtn: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    minWidth: 120,
    fontSize: "14px !important",
    color: theme.palette.primary.main,
  },
}));

export default function MonteraConfirmationDialog({
  title,
  okText,
  cancelText = "",
  children = null,
  Action = (confirmed) => {},
  open = false,
  dialogType = null, // success, error, null(neutral will render no Icon)
  acceptDisabled = false,
}) {
  const classes = useStyles();
  let iconContent;
  if (dialogType === "success") {
    iconContent = (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
          fill="#4ECDC4"
        />
        <path
          opacity="0.2"
          d="M20.8455 40.6309L35.2429 55.0284C47.1672 51.8486 56 40.9842 56 28C56 27.735 56 27.47 56 27.2051L44.694 16.7823L20.8455 40.6309Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M27.0267 35.9513C28.2633 37.1879 28.2633 39.3077 27.0267 40.5443L26.1452 41.4258C24.9086 42.6624 22.7887 42.6624 21.5521 41.4258L10.3344 30.1199C9.09783 28.8833 9.09783 26.7634 10.3344 25.5268L11.2159 24.6453C12.4525 23.4087 14.5724 23.4087 15.809 24.6453L27.0267 35.9513Z"
          fill="white"
        />
        <path
          d="M40.1912 16.4308C41.4278 15.1942 43.5476 15.1942 44.7842 16.4308L45.6657 17.3123C46.9023 18.5489 46.9023 20.6688 45.6657 21.9054L26.2336 41.2492C24.997 42.4858 22.8771 42.4858 21.6405 41.2492L20.759 40.3677C19.5224 39.1311 19.5224 37.0112 20.759 35.7746L40.1912 16.4308Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="28.0001"
            y1="26.25"
            x2="49.0001"
            y2="46.8125"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#051C2C" />
            <stop offset="1" stopColor="#051C2C" stop-Opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  } else if (dialogType === "error") {
    iconContent = (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
          fill="#FB3640"
        />
        <path
          opacity="0.2"
          d="M16.2527 40.6309L31.3568 55.735C44.076 54.2334 54.1454 44.2524 55.7353 31.5331L40.5429 16.3407L16.2527 40.6309Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M39.3829 36.0496C40.6195 37.2862 40.6195 39.4061 39.3829 40.6427L38.7764 41.2492C37.5398 42.4858 35.4199 42.4858 34.1833 41.2492L14.7512 21.817C13.5146 20.5804 13.5146 18.4606 14.7512 17.224L15.446 16.5291C16.6826 15.2925 18.8025 15.2925 20.0391 16.5291L39.3829 36.0496Z"
          fill="white"
        />
        <path
          d="M29.1365 25.7153L23.8486 31.0032L34.183 41.2492C35.4196 42.4858 37.5395 42.4858 38.7761 41.2492L39.4709 40.5544C40.7075 39.3178 40.7075 37.1979 39.4709 35.9613L29.1365 25.7153Z"
          fill="#D6D6D6"
        />
        <path
          d="M34.6707 16.483C35.9072 15.2464 38.0271 15.2464 39.2637 16.483L39.9586 17.1778C41.1952 18.4144 41.1952 20.5343 39.9586 21.7709L20.4381 41.1147C19.2015 42.3513 17.0816 42.3513 15.845 41.1147L15.2385 40.5082C14.0019 39.2716 14.0019 37.1518 15.2385 35.9152L34.6707 16.483Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="26.25"
            y1="26.25"
            x2="45.9375"
            y2="49"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#051C2C" />
            <stop offset="1" stopColor="#051C2C" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  } else {
    iconContent = <></>;
  }

  const handleClose = () => {
    Action(false);
  };
  const handleAccept = () => {
    Action(true);
  };
  return (
    <>
      <Dialog
        open={Boolean(open)}
        onClose={handleClose}
        PaperProps={{ style: { padding: 40 } }}
      >
        <div className={classes.iconWrapper}>{iconContent}</div>
        <DialogTitle style={{ padding: "0 0 24px 0" }}>{title}</DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <DialogContentText style={{ marginBottom: 0 }}>
            {children || ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-start", padding: 0 }}>
          <div className={classes.btnGroup}>
            <Button onClick={handleAccept} disabled={acceptDisabled} className={classes.submitBtn}>
              {okText}
            </Button>
            <Button
              autoFocus
              className={classes.cancelBtn}
              onClick={handleClose}
            >
              {cancelText}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
/* btnGroup: {
  marginTop: theme.spacing(3)
},
submitBtn: {
  padding: theme.spacing(2),
  fontSize: "14px !important",
  color: "#ffff",
  backgroundColor: "#2B59C3",
  opacity: 0.5,
  "&:hover": {
    opacity: 1,
    backgroundColor: "#2B59C3 !important"
  }
},
cancelBtn: {
  marginLeft: theme.spacing(2),
  padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  fontSize: "14px !important",
  color: theme.palette.primary.main
} */
