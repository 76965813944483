import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import CommentsTodayGroupedTable from './CommentsTodayGroupedTableItem';
import CommentsMonthGroupedTable from './CommentsMonthGroupedTableItem';
import { useTranslation } from 'react-i18next';

const CommentsGroupedTable = ({
    previewImageClassName,
    comments,
    desc = true
}) => {
     const { t } = useTranslation()
    const classes = useStyles();

    const [groupedComments, setGroupedComments] = useState({
        today: [],
        months: []
    });

    const mapGroupedComments = () => {
        const todayComments = [];
        const months = [];
        const monthsDict = {};

        const today = moment();

        for (const comment of comments) {
            const date = moment(comment.createdDate);
            if (date.isSame(today, 'date')) {
                todayComments.push(comment);
                continue;
            }

            const month = date.startOf('month').toISOString();
            let monthToPush = monthsDict[month];
            if (!monthToPush) {
                monthToPush = [];
                monthsDict[month] = monthToPush;
                months.push(month);
            }
            monthToPush.push(comment);

        }

        const monthsToSet = months.map(date => ({
            date,
            items: monthsDict[date]
        }));
        setGroupedComments({
            today: todayComments,
            // for dev only
            // today: comments,
            months: monthsToSet,
        });
    }

    useEffect(() => {
        mapGroupedComments();
    }, [comments]);

    const hasTodayComments = !!groupedComments.today.length;
    const hasEarlierComments = !!groupedComments.months.length;

    const hasDivider = hasTodayComments && hasEarlierComments;

    const renderTodayComments = () => hasTodayComments && (
        <>
            <Typography
                className={classes.sectionHeader}
                variant='subtitle1'
            >
                {t('common:all.labels.comments.today')}
            </Typography>
            <CommentsTodayGroupedTable
                previewImageClassName={previewImageClassName}
                items={groupedComments.today}
            />
        </>
    )

    return (
        <div className={classes.table}>
            {desc && renderTodayComments()}
            {desc && hasDivider && (
                <div className={classes.sectionsDivider} />
            )}
            {hasEarlierComments && (
                <>
                    <Typography
                        className={classes.sectionHeader}
                        variant='subtitle1'
                    >
                        {t('common:all.labels.comments.earlier')}
                    </Typography>
                    {groupedComments.months.map((x, i) => (
                        <CommentsMonthGroupedTable
                            previewImageClassName={previewImageClassName}
                            first={i === 0}
                            key={x.date}
                            date={x.date}
                            items={x.items}
                        />
                    ))}
                </>
            )}
            {!desc && hasDivider && (
                <div className={classes.sectionsDivider} />
            )}
            {!desc && renderTodayComments()}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    table: {
        flex: 1,
        display: 'grid',
        gridTemplateColumns: 'auto 1fr minmax(auto, 400px)',
        alignContent: 'flex-start',

        padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px`
    },
    sectionHeader: {
        gridColumn: '1 / span 3',
        padding: `${theme.spacing(0.5)}px 0px`
    },
    sectionsDivider: {
        gridColumn: '1 / span 3',
        marginTop: theme.spacing(2)
    }
}));

export default CommentsGroupedTable;
