import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from "@material-ui/core";

const MonteraDialog = ({
  title = "",
  children = null,
  open = false,
  showCloseIcon = true,
  onClose = () => {},
  titleStyles = { padding: 0 },
  titleClassName = "",
  contentStyles = {
    padding: `${showCloseIcon ? 0 : 40}px 40px 0px 40px`,
    minWidth: 560
  },
  transparentBackdrop = false, // Enable transparent backdrop
  maxWidth = undefined
}) => {
  return (
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose(event, reason);
          }
        }}
        BackdropProps={transparentBackdrop ? { style: { backgroundColor: "transparent" } } : {}}
        maxWidth={maxWidth}
        //Use the onClose prop with the `reason` argument to filter the `backdropClick`
      >
        {showCloseIcon &&
          <div>
            <IconButton
              aria-label="Create New"
              onClick={onClose}
              style={{ float: "right" }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M14 2L2 14"
                    stroke="#141E30"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 2L14 14"
                    stroke="#141E30"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </IconButton>
          </div>
        }
        <DialogTitle className={titleClassName} style={titleStyles}>{title}</DialogTitle>
        <DialogContent style={contentStyles}>{children}<br/><br/></DialogContent>
      </Dialog>
  );
};

export default MonteraDialog;
