import React, { useContext } from "react";
import { Button, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core";
import { checkLinkSelect } from "../checkLinkSelect";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LeftBarContext } from "./store";
import clsx from "clsx";
import SvgIcon from "@material-ui/core/SvgIcon";
import { useStyles } from "./style";

const NavItem = ({ label = "", link = "/", Icon, child = false, external= false, target="_self" }) => {
  const classes = useStyles();
  const {
    LeftBarStore: { open },
  } = useContext(LeftBarContext);
  const { t } = useTranslation();
  const tooltipTitle = !open ? t(`all.labels.navigation.${label}`) : "";
  const history = useHistory();
  const { location } = history;
  const searchQuery = window.location.search;

  const selected = link && checkLinkSelect(link, location, searchQuery);

  const handleLink = () => {
    history.push(link);
  };

  return (
    <Tooltip title={tooltipTitle} placement="right">
      <Button
        fullWidth
        onClick={ !external ? handleLink : null}
        href={external ? link : ""}
        variant="text"
        className={clsx(classes.menuItem,{
          [classes.menuItemActive]: child && selected,
          [classes.menuItemChild]: child && !selected,
        })}
        target="_blank"
      >
        <ListItemIcon
          classes={{
            root: clsx({
              [classes.iconOpen]: open,
              [classes.iconClose]: !open,
            }),
          }}
        >
          <SvgIcon viewBox={"0 0 16 16"} fontSize={!open ? "medium" : "small"}>
            {Icon}
          </SvgIcon>
        </ListItemIcon>
        <ListItemText
          style={{ color: "#fff" }}
          primary={t(`common:all.labels.navigation.${label}`)}
        />
      </Button>
    </Tooltip>
  );
};

export default NavItem;
