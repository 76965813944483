import { useEffect } from "react";

export const useBrowserFullscreen = (fullScreenMode: boolean, onChange: (v: boolean) => void) => {
  useEffect(() => {
    try {
      if (fullScreenMode) {
        if (!document.fullscreenElement) {
          document.getElementsByTagName('body')[0].requestFullscreen().catch(e => console.error(e));

          const onFullscreenChange = () => {
            onChange(!!document.fullscreenElement);
          };

          document.onfullscreenchange = onFullscreenChange;
        }
        return;
      }

      if (document.fullscreenElement) {
        document.exitFullscreen().catch(e => console.error(e));
      }
    } catch {

    }

  }, [fullScreenMode]);
}