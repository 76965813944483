import { makeStyles } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const MonteraExpandIcon = ({
    expanded,
}) => {
    const classes = useStyles({ expanded });

    return (
        <ExpandMoreIcon className={classes.expand} />
    );
}

const useStyles = makeStyles(theme => ({
    expand: ({ expanded }) => ({
        transform: expanded
            ? "rotate(0deg)"
            : "rotate(-90deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    }),
}));

export default MonteraExpandIcon;