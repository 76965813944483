import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18nextCfg from './config/i18n';
import App from './App';
import AppSuspense from './assets/reuseComponents/Suspense/AppSuspense';

ReactDOM.render(
  <I18nextProvider i18n={i18nextCfg}>
    <Router>
      <AppSuspense>
        <App />
      </AppSuspense>
    </Router>
  </I18nextProvider>
  ,
  document.getElementById('root'),
);
