import React, {  } from 'react';
import { Avatar, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { PaperclipIcon } from '../icons';
import { channelsList } from '../../StaffServices/Details/Comments/MessageStore';

const CommentItemHeader = ({
    className,
    comment
}) => {
    const { isRead, attachments } = comment;
    const classes = useStyles();

    const mappedChannel = channelsList.find(x => x.matchingText === comment.channelMatchingName);

    return (
        <div className={clsx(classes.header, className)}>
            <div className={classes.channelContainer}>
                {!!mappedChannel && (
                    <>
                        <Avatar variant="square" src={mappedChannel.icon} className={classes.mainIcons} />
                        <Typography
                            className={classes.channelName}
                            variant='caption'
                        >
                            {comment.channelName}
                        </Typography>
                    </>
                )}
            </div>
            <div className={classes.stateContainer}>
                {!!attachments && (
                    <PaperclipIcon className={classes.stateIcon} />
                )}
                {!isRead && (
                    <div className={classes.unreadRound} />
                )}
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridColumn: '1 / span 3',
        padding: theme.spacing(1)
    },
    channelContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    },
    stateContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    },
    unreadRound: {
        borderRadius: '50%',
        background: '#2B59C3',
        height: theme.spacing(1.5),
        width: theme.spacing(1.5)
    },
    stateIcon: {
        color: '#141E30'
    },
    mainIcons: {
        width: 30,
        height: 30
    },
    channelName: {
        color: theme.palette.primary.dark
    },
}));

export default CommentItemHeader;