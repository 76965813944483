import React from "react";
import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

type Props = {
  is: boolean;
}

const Preloader = ({ is }: Props) => {
  if (!is) {
     return null
  }
  return <Grid container justifyContent="center">
    <CircularProgress className="dataLoading" color="primary" />
  </Grid>
}

export default  React.memo(Preloader);
