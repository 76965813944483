import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  return {
    flag: {
      width: 24,
      margin: `0 ${theme.spacing(1)}px 0 0`,
    },
    listItem: {
      height: '100%',
      borderRadius: 0,
      padding: `0 ${theme.spacing(2)}px`,
      textTransform: 'none',
      fontWeight: 'normal',
    },
    selectedIcon: {
      marginLeft: theme.spacing(2),
    },
  };
});
