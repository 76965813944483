import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    h1: {
      fontSize: 48,
      lineHeight: 1.3,
      letterSpacing: 0.25
    },
    h2: {
      fontSize: 34,
      lineHeight: 1.3,
      letterSpacing: 0.25
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.3,
      letterSpacing: 0
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.3,
      letterSpacing: 0.15
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: 0.15
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1,
      letterSpacing: 0.1
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.3,
      letterSpacing: 0.5
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.3,
      letterSpacing: 0.25
    },
    button: {
      textTransform: "uppercase",
      fontSize: 14,
      lineHeight: 1,
      letterSpacing: 1.25
    },
    caption: {
      fontSize: 12,
      lineHeight: 1,
      letterSpacing: 0.4,
      color: "rgba(20, 30, 48, 0.5)"
    },
    overline: {
      textTransform: "uppercase",
      fontSize: 10,
      lineHeight: 1,
      letterSpacing: 1.5
    }
  },
  palette: {
    primary: {
      main: "#003882",
      light: "#2B59C3"
    },

    secondary: {
      main: "#085c9c"
    },
    // In order to use accent, use "style", not "color"
    accent: {
      backgroundColor: "#06a9c6"
    },
    create: {
      backgroundColor: "#319831",
      color: "#ffffff"
    }
  },
  // HOW TO REWRITE A COMPONENT STYLES
  overrides: {
    MuiAlert: {
      filledSuccess: {
        backgroundColor: "#D0EACC",
        color: "black"
      },
      action: {
        alignItems: "start"
      }
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: 300,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        lineHeight: "100%",
        letterSpacing: "0.0004em"
      }
    },
    MuiButton: {
      root: {
        "&$containedPrimary": {
          backgroundColor: "#2B59C3"
        },
        "&:disabled": {
          backgroundColor: "#2B59C3",
          opacity: 0.5,
          color: "#fff !important"
        }
      }
    },
    MuiFab: {
      root: {
        width: 40,
        height: 40
      }
    },
    MuiTab: {
      root: {
        "@media (min-width: 600px)": {
          minWidth: 50
        }
      },
      textColorPrimary: {
        "&$selected": {
          color: "#2B59C3"
        }
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#2B59C3"
      }
    },
    MuiTableHead: {
      //todo must be in component
      root: {
        backgroundColor: "rgba(43, 89, 195, 0.2)"
      }
    },
    MuiTableSortLabel: {
      root: {
        "&:hover": {
          color: "rgba(0, 0, 0, 0.58)"
        },
        "&:focus": {
          color: "#fff"
        }
      }
    },
    MuiTableCell: {
      head: {
        color: "black",
        fontWeight: "bolder",
        padding: "16px",
        lineHeight: 0.9
      },
      root: {
        wordBreak: "break-word",
        maxWidth: 400,
        padding: "5px 16px "
      }
    },
    MuiFormControl: {
      root: {
        margin: "10px 0"
      }
    },
    MuiTextField: {
      root: {
        margin: "10px 0",
        outlined: {
          backgroundColor: "#ccc"
        },
        "& textarea": {
          resize: "vertical"
        }
      }
    },
    MuiInputLabel: {
      root: {
        "&$outlined": {
          transform: "translate(14px, 16px) scale(1)"
        }
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: 14
      }
    },
    MuiAutocomplete: {
      input: {
        padding: "5px 4px !important"
      },
      popupIndicator: {
        display: "none"
      }
    },
    paper: {
      padding: "1.2rem",
      margin: "10px 0"
    },
    MuiContainer: {
      root: {
        maxWidth: false,
        paddingLeft: "16px !important",
        paddingRight: "16px !important"
      },
      maxWidthLg: {
        maxWidth: "2000px !important"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          color: "#2B59C3"
        },
        "&$disabled": {
          opacity: 0.4
        }
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0
      }
    }
  }
});
