import { useEffect, useState } from "react";

export const useObjectUrl = (blob) => {
    const [url, setUrl] = useState('');
    useEffect(() => {
        if (!blob) {
            return;
        }

        const url = window.URL.createObjectURL(blob);
        setUrl(url);

        return () => window.URL.revokeObjectURL(url);
    }, [blob]);

    return url;
}