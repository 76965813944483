import { CircularProgress, Collapse, Grid, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import MonteraFilePreview from '../../../assets/reuseComponents/MonteraFilePreview';
import { getFileTypeIcon } from '../../../assets/reuseComponents/MonteraFileTypeIcons';
import MonteraTruncatedText from '../../../assets/reuseComponents/MonteraTruncatedText';
import { getFileData } from '../../../utils/context/controllers/crud';
import { downloadFile, getFileExtension, mapPreviewTypeFromExtension } from '../../../utils/functions/file';
import { DownloadIcon, InfoIcon } from '../icons';

const CommentAttachmentItem = ({ attachment, previewImageClassName }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const [downloading, setDownloading] = useState(false);

  const onItemClick = () => {
    if (!shouldShowPreview) {
      return;
    }
    setExpanded(prev => !prev);
  }

  const onPreviewClick = e => {
    e.stopPropagation();
    setExpanded(prev => !prev);
  }

  const onDownloadClick = async e => {
    e.stopPropagation();
    setDownloading(true);

    try {
      const response = await getFileData('files', attachment.fileServiceId);
      downloadFile(response, attachment.fileName);
    } finally {
      setDownloading(false);
    }
  }

  const extension = getFileExtension(attachment);
  const icon = getFileTypeIcon(extension);

  const previewType = mapPreviewTypeFromExtension(extension);

  const shouldShowPreview = !!previewType;

  return (
    <>
      <ListItem className={classes.listItem} button={shouldShowPreview} onClick={onItemClick}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <div className={classes.fileIcon}>
            {icon}
          </div>
        </ListItemAvatar>
        <ListItemText>
          <Grid container justifyContent="space-between">
            <MonteraTruncatedText
              text={attachment.fileName}
              maxWidth="400px"
            />
          </Grid>
        </ListItemText>
        {shouldShowPreview && (
          <ListItemSecondaryAction className={classes.actions}>
            <IconButton onClick={onPreviewClick}>
              <InfoIcon className={classes.actionIcon} />
            </IconButton>
            {downloading
              ? (
                <div className={classes.spinnerContainer}>
                  <CircularProgress size={16} className="dataLoading" color="primary" />
                </div>
              )
              : (
                <IconButton onClick={onDownloadClick}>
                  <DownloadIcon className={classes.actionIcon} />
                </IconButton>
              )
            }
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Collapse in={expanded} mountOnEnter={true}>
        <MonteraFilePreview
          className={classes.preview}
          imageClassName={previewImageClassName}
          id={attachment.fileServiceId}
          type={previewType}
        />
      </Collapse>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  row: ({ expanded }) => ({
    borderRadius: theme.spacing(0.5),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    transition: theme.transitions.create("background", {
      duration: theme.transitions.duration.shortest
    }),
    cursor: 'pointer',
    background: 'rgba(20, 30, 48, 0.1)',

    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: `${theme.spacing(2)}px`
  }),
  body: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(1)
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center'
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 42,
    width: 42
  },
  actionIcon: {
    color: '#2B59C3'
  },
  preview: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export default CommentAttachmentItem;
