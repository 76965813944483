import { CircularProgress, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { getFileData } from '../../utils/context/controllers/crud';
import { useObjectUrl } from '../../utils/hooks/useObjectUrl';

const MonteraFilePreview = ({
    id,
    className,
    imageClassName,
    type
}) => {
    const [blob, setBlob] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchFile = async () => {
        setLoading(true);

        try {
            const response = await getFileData('files', id);
            setBlob(response);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchFile();
    }, []);

    return (
        <div className={className}>
            {loading && (
                <CircularProgress size={60} />
            )}
            {!loading && type === FILE_PREVIEW_TYPES.IMAGE && (
                <MonteraImagePreview className={imageClassName} blob={blob} />
            )}
            {!loading && type === FILE_PREVIEW_TYPES.PDF && (
                <MonteraPdfPreview blob={blob} />
            )}
        </div>
    )
}

const MonteraImagePreview = ({
    className,
    blob,
}) => {
    const url = useObjectUrl(blob);

    const classes = useStyles();

    return (
        <img className={clsx(classes.image, className)} src={url} alt='preview'></img>
    )
}

const MonteraPdfPreview = ({
    blob,
}) => {
    const classes = useStyles();
    const url = useObjectUrl(blob);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setTotalPages(numPages);
        setPage(1);
    }

    const handleChangePage = (e, newPage) => {
        setPage(newPage);

        e.preventDefault()
    };

    return (
        <div className={classes.pdfContainer}>
            <Document
                className={classes.pdfDocument}
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={page} />
            </Document>

            <Pagination
                page={page}
                count={totalPages}
                onChange={handleChangePage}
            />
        </div>
    );
}

export const FILE_PREVIEW_TYPES = {
    IMAGE: 1,
    PDF: 2
}

const useStyles = makeStyles(theme => ({
    pdfContainer: {
        background: '#f1f1f1',
        padding: theme.spacing(2),

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > :not(:first-child)': {
            marginTop: theme.spacing(2)
        }
    },
    pdfDocument: {
        minHeight: 800
    },
    image: {
        maxWidth: "60%",
        maxHeight: "60vh"
    }
}))

export default MonteraFilePreview;