import { makeStyles } from '@material-ui/core';
import React, { ReactNode, Suspense } from 'react';
import Loading from 'src/components/Suspense';

type Props = {
  children: ReactNode
}

const AppSuspense = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <Suspense fallback={<Loading className={classes.loadingContainer} />}>
      {children}
    </Suspense>
  )
}

const useStyles = makeStyles(() => ({
  loadingContainer: {
    margin: 0
  }
}));

export default AppSuspense;