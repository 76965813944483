type IFile = {
    key: number;
    fileName: string;
    categoryId: number;
    category: {
        key: number
    },
    fileServiceId: number;
    createdDate: string;
    sendToIntegration: boolean,
    name: string;
    size: number;
    finished: boolean,
    type: string;
    verifyKey: number;
    chatMessageId: string;
}

interface ICommentDraft {
    text: string;
    fileInfo?: IFile[]
}

const useStoreCommentDraft = () => {
    
    let StoredDraftsArray: string[] = JSON.parse(localStorage.getItem('StoredDraftsArray') || "[]");

    const saveDraft = (key:string, value: ICommentDraft) => {
        if(!!key && !StoredDraftsArray.includes(key)){
            StoredDraftsArray.push(key);
        }
        localStorage.setItem("StoredDraftsArray", JSON.stringify(StoredDraftsArray));
        localStorage.setItem(key, JSON.stringify(value));
    }
    const getDraft = (key: string) => {
        if(StoredDraftsArray.includes(key)){
            return JSON.parse(localStorage.getItem(key) || "{}");
        }
    }
    
    const deleteDraft = (key:string) => {
        if(!!key) StoredDraftsArray = StoredDraftsArray.filter(draftKey => String(draftKey) !== String(key));
        localStorage.setItem("StoredDraftsArray", JSON.stringify(StoredDraftsArray));
        localStorage.removeItem(key);
    }

    const clearAllDrafts = () => {
        StoredDraftsArray.forEach(draftKey =>{
            localStorage.removeItem(draftKey);
        });
        localStorage.removeItem("StoredDraftsArray");
        return true;
    }
    return {saveDraft, getDraft, deleteDraft, clearAllDrafts}
}
export default useStoreCommentDraft;