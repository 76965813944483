import React, { useContext, useState } from "react";
import {
  __getCollection,
  getMetaData,
  getRecord,
  getRelatedRecord,
  postRecord,
  putRecord,
} from "../../../../../utils/context/controllers/crud";
import staff from "../imgs/chenalImgs/staff.svg";
import partner from "../imgs/chenalImgs/partner.svg";
import customer from "../imgs/chenalImgs/customer.svg";
import worker from "../imgs/chenalImgs/worker.svg";
import subcontractor from "../imgs/chenalImgs/subcontractor.svg";
import publicImg from "../imgs/chenalImgs/public.svg";
import { __getAuthObject } from "../../../../../utils/context/controllers/authProvider";
import { AppContext } from "../../../../../utils/context/AppStore";
import isEmpty from "lodash/fp/isEmpty";
import { useUpdatedEffect } from "src/utils/hooks/useUpdatedEffect";
import useQueryString from "src/utils/hooks/useQueryString";
import useStoreCommentDraft from '../../../../../utils/hooks/useStoreCommentDraft';

const __apiVersion = process.env.REACT_APP_ApiVersion;
const __domain = process.env.REACT_APP_Domain;

export const channelsList = [];
channelsList[0] = { matchingText: "Staff", icon: staff, count: 0 };
channelsList[1] = { matchingText: "Partner", icon: partner, count: 0 };
channelsList[2] = { matchingText: "Worker", icon: worker, count: 0 };
channelsList[3] = { matchingText: "Customer", icon: customer, count: 0 };
channelsList[4] = { matchingText: "Public", icon: publicImg, count: 0 };
channelsList[5] = {
  displayText: "Subcontractor",
  icon: subcontractor,
  count: 0,
};

const preDefaultChannel = JSON.parse(
  localStorage.getItem("comments.selectedChannel")
);
const defaultState = {
  threads: [],
  newThread: {},
  meta: {
    key: "",
    orderType: "",
  },
  entityTypes: {
    wo_details: "WorkOrder",
    so_details: "ServiceOrder",
    s_details: "Service",
  },
  selectedEntType: "",
  loading: {
    thread: false,
    channels: false,
    services: false,
    serviceOrders: false,
    msgs: false,
    files: false,
  },
  messageEmail: {},
  isAddNewThread: false,
  chatThread: {},
  serviceOrders: [],
  services: [],
  threadsSelected: {},
  chatchannels: channelsList,
  topicDataIsValid: false,
  selectedChannel: !!preDefaultChannel ? preDefaultChannel : 0,
  totalCount: 0,
  error: false,
  uploadedFiles: [],
  fileList: [],
  readedMessages: [],
  scrollToBottom: true,
  channelsUnreadedComments: [], // amount of unreaded comments per channel,

  sendingMessage: false,

  shareByEmail: false,
  markAsUnread: null,
  messagesCreatedDateDes: true,

  displayDragAndDrop: false,
  fileUploadingAnchorEl: null
};
// Plain empty context
export const CommentsContext = React.createContext(defaultState);

const CommentsStore = (props) => {
  const [{ channelKey }] = useQueryString();
  const [
    { sortBy, des }
  ] = useQueryString();

  const { ContextAPI } = useContext(AppContext);
  const {deleteDraft} = useStoreCommentDraft();
  const getDefaultState = () => {
    const messagesCreatedDateDes = getMessagesCreatedDateDes();
    return { ...defaultState, messagesCreatedDateDes };
  };

  const getMessagesCreatedDateDes = (prev) => {
    if (sortBy !== 'createdDate') {
      return prev?.messagesCreatedDateDes ?? defaultState.messagesCreatedDateDes;
    }
    return des === 'true';
  }

  const [CommentStore, setCommentStore] = useState(getDefaultState);

  const getThreadList = async (id = "", type, currentChannelKey) => {
    if (!id) return;
    setCommentStore((prev) => ({
      ...prev,
      loading: {
        thread: true,
      },
    }));
    return await getRecord(
      `chat/${CommentStore.entityTypes[type]}`,
      `${id}/thread/list?currentChannelKey=${channelKey || currentChannelKey}`,
      { pageSize: 1000 }
    ).then(async (response) => {
      if (!!response && !!response.threads) {
        if (response.threads.length <= 0) {
          setCommentStore((prev) => ({
            ...prev,
            loading: {
              thread: false,
            },
          }));
        } else {
          setCommentStore((prev) => ({
            ...prev,
            ...response,
            threadsSelected: isEmpty(prev.threadsSelected)
              ? response.threads[0]
              : prev.threadsSelected,
            loading: {
              thread: false,
            },
          }));
        }
        return response.threads;
      } else {
        console.log("Error getting user Data.");
      }
    });
  };
  const getChatChannels = async () => {
    try {
      setCommentStore((prev) => ({
        ...prev,
        loading: {
          channels: true,
        },
      }));
      return await getMetaData("lookups/chatchannels").then((response) => {
        if (response.length > 0) {
          const chatchannels = response.map((elemt) => ({
            ...elemt,
            ...channelsList.find(
              (item) => elemt.matchingText === item.matchingText
            ),
          }));
          setCommentStore((prev) => ({
            ...prev,
            chatchannels,
            loading: {
              channels: false,
            },
          }));
          return chatchannels;
        } else {
          console.log("Channels data EMPTY", response);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getChanelThreads = async (id = "", storeThreads = CommentStore.threads, displayLoadingIndicator = true) => {
    if (CommentStore.chatchannels[id].key) {
      if(displayLoadingIndicator) {
        setCommentStore((prev) => ({
          ...prev,
          loading: {
            thread: displayLoadingIndicator,
          },
        }));
      }
      await getRecord(
        `chat/${CommentStore.entityTypes[CommentStore.selectedEntType]}`,
        `${CommentStore.meta.key}/thread/list?currentChannelKey=${channelKey || CommentStore.chatchannels[id].key}`,
        { pageSize: 1000 }
      ).then((response) => {
        setCommentStore((prev) => ({
          ...prev,
          threads: response.threads,
          totalCount: response.totalCount,
          loading: {
            thread: false,
          },
        }));
      });
    }
  };

  const getThreadMsgs = async (id = "", currentChannelKey, withLoading = true) => {
    try {
      setCommentStore((prev) => ({
        ...prev,
        loading: {
          msgs: withLoading,
        },
      }));
      return await getRecord(
        "chat/thread",
        `${id}?currentChannelKey=${currentChannelKey}`,
        // Order is inverted
        { pageSize: 1000, messagesCreatedDateDes: !CommentStore.messagesCreatedDateDes }
      ).then((response) => {
        if (!!response) {
          setCommentStore((prev) => ({
            ...prev,
            ...response,
            loading: {
              msgs: false,
            },
          }));
          return response.threads;
        } else {
          console.log("Error getting user Data.");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getCommentsFiles = (forThreadOnly = false) => {
    setCommentStore((prev) => ({
      ...prev,
      loading: {
        files: true,
      },
    }));
    getRecord(
      `chat/${CommentStore.entityTypes[CommentStore.selectedEntType]}`,
      `${CommentStore.meta.key}/files`,
      forThreadOnly && { threadKey: CommentStore.chatThread.key }
    ).then(({ files }) => {
      setCommentStore((prev) => ({
        ...prev,
        fileList: [...files],
        loading: {
          files: false,
        },
      }));
    });
  };

  const clearMsgList = () => {
    setCommentStore((prev) => ({
      ...prev,
      chatThread: {
        messages: [],
      },
    }));
  };
  const addThread = async () => {
    setCommentStore((prev) => ({
      ...prev,
      loading: {
        thread: true,
      },
    }));

    const { shareByEmail } = CommentStore;

    const sentMessage = await postRecord("chat/message", CommentStore.newThread)
      .then((response) => {
        if (!!response) {
          setCommentStore((prev) => ({
            ...prev,
            newThread: {},
            loading: {
              thread: false,
            },
          }));
          return response;
        } else {
          console.log("Error getting user Data.");
        }
      })
      .then(async response => { 
        try {
          if (CommentStore.uploadedFiles.length !== 0 && response.data.key) {
            await sendAttachments(response.data.key);
          }
        } finally {
          return response;
        }
      })
      .then(async (response) => {
        const {
          chatchannels,
          selectedChannel,
          meta,
          selectedEntType,
        } = CommentStore;
        await getThreadList(
          meta.key,
          selectedEntType,
          chatchannels[selectedChannel].key
        ).then(async (res) => {
          deleteDraft(chatchannels[selectedChannel].key);
          MsgContextApi.setData("topicDataIsValid", false)
          if (!!res && response.data?.threadKey) {
            setCommentStore((prev) => ({
              ...prev,
              isAddNewThread: false,
              threadsSelected: res.find(
                (ent) => ent.key === Number(response.data.threadKey)
              ),
              uploadedFiles: [],
            }));
          }
          await getThreadMsgs(
            response.data.threadKey,
            chatchannels[selectedChannel].key
          );
        });

        return response;
      })
      .catch((err) => {
        console.log(err);
        setCommentStore((prev) => ({
          ...prev,
          error: true,
          errorMsg: err,
          loading: {
            thread: false,
          },
        }));
      });

      if (!shareByEmail) {
        return;
      }

      MsgContextApi.setData("shareCommentByEmail", { ...sentMessage.data });
  };
  const getMsgsEmails = (msgKey) => {
    getRelatedRecord("chat/message", msgKey, "email/list", {
      pageSize: 1000,
    }).then((response) => {
      setCommentStore((prev) => ({
        ...prev,
        messageEmail: { ...response },
      }));
    });
  };

  const sendAttachments = async (key) => {
    let promiseArr = [];
    CommentStore.uploadedFiles.forEach((file) => {
      promiseArr.push(
        putRecord(
          "Files",
          { ...file, chatMessageId: key },
          file.key
        )
      );
    });
    return await Promise.all(promiseArr);
  }

  const sendMsg = async (data) => {
    setCommentStore((prev) => ({
      ...prev,
      loading: {
        msgs: true,
      },
      sendingMessage: true
    }));

    const { shareByEmail } = CommentStore;

    const sentMessage = await postRecord("chat/message", data)
      .then((response) => {
        if (!!response) {
          // UPLOAD ATTACHED FILES
          if (CommentStore.uploadedFiles.length !== 0 && response.data.key) {
            sendAttachments(response.data.key)
              .then(() => {
                const respData = response.data;
                respData.files = CommentStore.uploadedFiles;
                setCommentStore((prev) => ({
                  ...prev,
                  chatThread: {
                    ...CommentStore.chatThread,
                    messages: [...CommentStore.chatThread.messages, respData],
                  },
                  loading: {
                    msgs: false,
                  },
                  uploadedFiles: [],
                  sendingMessage: false
                }));
              });
          } else {
            setCommentStore((prev) => ({
              ...prev,
              chatThread: {
                ...CommentStore.chatThread,
                messages: [...CommentStore.chatThread.messages, response.data],
              },
              loading: {
                msgs: false,
              },
              sendingMessage: false
            }));
          }
        } else {
          console.log("Error getting user Data.");
        }

        return response.data;
      })
      .catch((err) => {
        console.log(err);
        setCommentStore((prev) => ({
          ...prev,
          error: true,
          errorMsg: err,
          loading: {
            msgs: false,
          },
        }));
        console.log(err);
      });

    if (!shareByEmail) {
      return;
    }

    MsgContextApi.setData("shareCommentByEmail", { ...sentMessage });

  };
  const shareMsg = async (data) => {
    const authObject = await __getAuthObject();
    setCommentStore((prev) => ({
      ...prev,
      loading: {
        msgs: true,
      },
    }));
    return await fetch(
      `${__domain}/${__apiVersion}/chat/message/${data.messageKey}/share/${data.channelKey}`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${authObject.accessToken}`,
        },
      }
    )
      /* BUG #5198
      .then((res) => {
        const {
          chatchannels,
          selectedChannel,
          meta,
          selectedEntType,
        } = CommentStore;

        getThreadList(
          meta.key,
          selectedEntType,
          chatchannels[selectedChannel].key
        )

      })*/
      .then(async (res) => {
        await res.json();
        setCommentStore((prev) => ({
          ...prev,
          loading: {
            msgs: false,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
        setCommentStore((prev) => ({
          ...prev,
          error: true,
          errorMsg: err,
          loading: {
            msgs: false,
          },
        }));
        console.log(err);
      });
  };
  // REFRESH MESSAGES LIST OF THE CURRENT TOPIC
  const refreshCurrentThreadMsg = async () => {
    if(!(CommentStore.threadsSelected || CommentStore.threads[0]) && !CommentStore.chatchannels[CommentStore.selectedChannel || 0].key) return;
    try {
      setCommentStore((prev) => ({
        ...prev,
        loading: {
          msgs: true,
        },
      }));
      return await getRecord(
        "chat/thread",
        `${CommentStore.threadsSelected.key || CommentStore.threads[0].key}?currentChannelKey=${CommentStore.chatchannels[CommentStore.selectedChannel || 0].key
        }`,
        // Order is inverted
        { pageSize: 1000, messagesCreatedDateDes: !CommentStore.messagesCreatedDateDes }
      ).then((response) => {
        if (!!response) {
          setCommentStore((prev) => ({
            ...prev,
            ...response,
            loading: {
              msgs: false,
            },
          }));
          return response.threads;
        } else {
          console.log("Error getting user Data.");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  // GET WorkOrder -> ServiceOrder OR GET ServiceOrder -> Projects
  const GetRelatedOrderList = async (key, type) => {
    const url =
      type === "wo_details"
        ? `WorkOrders/${key}/serviceOrders`
        : type === "so_details"
          ? `ServiceOrders/${key}/projects`
          : null;
    const responseObjectName =
      type === "wo_details"
        ? "serviceOrders"
        : type === "so_details"
          ? "projects"
          : null;

    if (url && responseObjectName) {
      return await __getCollection(url, {
        pageSize: 1000,
        sortBy: "createdDate",
        des: true,
      })
        .then((response) => response[responseObjectName])
        .catch(() => []);
    } else return [];
  };

  // GET WorkOrder -> ServiceOrder AND ServiceOrder -> Services
  const fetchRelatedCollections = async (key, type) => {
    const relatedCollection = await GetRelatedOrderList(key, type).then(
      (responseCollection) => {
        if (type === "wo_details") {
          // Set list of Service Orders
          if (!!responseCollection && Array.isArray(responseCollection)) {
            // Create Array of Services
            const promiseArr = responseCollection.map((serviceOrder) =>
              // GET Services for each Service Order
              GetRelatedOrderList(serviceOrder.key, "so_details").then(
                (servicesList) =>
                  servicesList.length &&
                  Array.isArray(servicesList) &&
                  servicesList
              )
            );
            return Promise.all(promiseArr).then((resultArr) => {
              return {
                serviceOrders: responseCollection,
                services: resultArr.filter((el) => el && el.length).flat(), // Parse services collections
              };
            });
          } else {
            return {
              serviceOrders: responseCollection,
            };
          }
        } else if (type === "so_details") {
          // Set list of Services
          return {
            services: responseCollection,
          };
        }
      }
    );

    setCommentStore((prev) => ({
      ...prev,
      ...relatedCollection,
    }));
  };

  const fetchUserData = async () => {
    const authObject = await __getAuthObject();
    return await fetch(`${__domain}/${__apiVersion}/Profile`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    })
      .then(async (res) => {
        const userData = await res.json();
        ContextAPI.setData("userData", userData);
      })
      .catch((err) => {
        console.log(err);
        setCommentStore((prev) => ({
          ...prev,
          error: true,
          errorMsg: err,
          loading: {
            msgs: false,
          },
        }));
        console.log(err);
      });
  };

  const markCommentAsUnread = async () => {
    const message = CommentStore.markAsUnread;
    if (!message) {
      return;
    }

    const key = message.key;
    const messageIds = [key];

    await postRecord("chat/unread", { messageIds })

    updateState({ markAsUnread: null });

    await getChanelThreads(CommentStore.selectedChannel || 0, CommentStore.threads, false); // Refresh the amount of unreaded messages for each thread
    await refreshCurrentThreadMsg();
    await getAmountOfUnreadedCommentsChannels(); // update the count of new comments for channels
  }

  const reset = () => {
    setCommentStore(getDefaultState());
  };
  const setContextData = (key, data) => {
    setCommentStore((prev) => ({
      ...prev,
      [key]: data,
    }));
  };

  const setContextDataCallback = (key, callback) => {
    setCommentStore((prev) => ({
      ...prev,
      [key]: callback(prev[key]),
    }));
  };

  const updateState = (newProp = {}) => {
    setCommentStore((prev) => ({
      ...prev,
      ...newProp,
    }));
  }
  // POST an array of "readed" comments
  const postReadedComments = (unreadMessages) => {
    postRecord("chat/read", { messageIds: unreadMessages || CommentStore.readedMessages })
      .then(() => {
        refreshCurrentThreadMsg();
        // setContextData("readedMessages", []);  
        // setContextData("scrollToBottom", false); // remove scroll to bottom
        updateState({
          readedMessages: [],
          scrollToBottom: false
        });
        getChanelThreads(CommentStore.selectedChannel || 0); // Refresh the amount of unreaded messages for each thread
        getAmountOfUnreadedCommentsChannels(); // update the count of new comments for channels
      });
  }

  // GET amount of unreaded messages
  const getAmountOfUnreadedCommentsChannels = () => {
    return getRecord("chat/channel/unread", `${CommentStore.meta.key}/${CommentStore.entityTypes[CommentStore.selectedEntType]}`)
      .then(({ unreadMessages }) => {
        if (!unreadMessages) {
          return;
        }
        if (isNaN(unreadMessages.length)) {
          return;
        }
        setContextData("channelsUnreadedComments", [...unreadMessages]);
      });
  };

  useUpdatedEffect(() => {
    if (sortBy !== 'createdDate') {
      return;
    }
    const desBool = des === 'true';
    MsgContextApi.setData("messagesCreatedDateDes", desBool);

  }, [sortBy, des]);

  useUpdatedEffect(() => {
    refreshCurrentThreadMsg();
  }, [CommentStore.messagesCreatedDateDes]);

  // Fefresh channels & topics counters of new messages 
  const refreshCounters = (id) => {
    getAmountOfUnreadedCommentsChannels();
    getChanelThreads(id, CommentStore.threads, false)
  }
  const MsgContextApi = {
    getAmountOfUnreadedCommentsChannels,
    setData: setContextData,
    setDataCallback: setContextDataCallback,
    updateState,
    fetchRelatedCollections,
    refreshCurrentThreadMsg,
    postReadedComments,
    fetchUserData,
    getChanelThreads,
    getCommentsFiles,
    getThreadList,
    refreshCounters,
    getChatChannels,
    getThreadMsgs,
    getMsgsEmails,
    addThread,
    clearMsgList,
    shareMsg,
    sendMsg,
    reset,
    markCommentAsUnread
  };
  return (
    <CommentsContext.Provider
      value={{
        CommentStore,
        setCommentStore,
        MsgContextApi,
      }}
    >
      {props.children}
    </CommentsContext.Provider>
  );
};

export { CommentsStore };
