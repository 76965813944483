import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { List } from "@material-ui/core";
import { theme } from "../../utils/styleTheme/mainStyles";
import {
  ArchiveIcon,
  CommentIcon,
  HelpIcon,
  HomeIcon,
  SupportIcon,
} from "./images";
import LocalAtmIcon from "@material-ui//icons/LocalAtm";
import NavItem from "./LeftBar/NavItem";
import FEATURES_FLAGS from "../../FeaturesFlags";
import {
  accounting,
  accountManagement,
  jobDispatching,
  myWork,
  userManagement,
} from "./LeftBar/Groups";
import MonteraRoleGuard from "../../assets/reuseComponents/MonteraGuards/MonteraRoleGuard";
import { MyOrdersIcon } from "../../assets/imgs";
import { ROLES } from "../../assets/reuseComponents/MonteraGuards/constants";
import { GroupBlock } from "./LeftBar";
import Feature from "../../assets/reuseComponents/Feature/Feature";
import { Features } from "src/utils/context/FeatureFlagProvider/types";

const useStyles = makeStyles(() => {
  return {
    menuList: {
      backgroundColor: theme.palette.primary.main,
      top: theme.spacing(3),
    },
  };
});

const LeftNavBar = () => {
  const classes = useStyles();
  const { location } = useHistory();
  const searchQuery = window.location.search;

  return (
    <List
      component="nav"
      aria-label="main mailbox folders"
      className={classes.menuList}
    >
      <NavItem
        label="dashboard"
        link={location.pathname === "/" ? `/${searchQuery}` : "/"}
        Icon={<HomeIcon />}
      />
      {FEATURES_FLAGS.STANDALONE_COMMENTS && (
        <NavItem
          label="comments"
          link={
            location.pathname === "/comments"
              ? `/comments${searchQuery}`
              : "/comments"
          }
          Icon={<CommentIcon />}
        />
      )}

      {FEATURES_FLAGS.INVOICING && (
        <MonteraRoleGuard
          roles={[ROLES.ACCOUNTING]}
          success={() => (
            <NavItem
              label="invoicing"
              link={
                location.pathname === "/invoicing"
                  ? `/invoicing${searchQuery}`
                  : "/invoicing"
              }
              Icon={
                <LocalAtmIcon style={{ color: "rgba(255, 255, 255, 0.5)" }} />
              }
            />
          )}
        />
      )}
      {FEATURES_FLAGS.DISPATCHER_REPORT && (
        <NavItem
          label="dispatcher_report"
          link={
            location.pathname === "/dispatcher-report"
              ? `/dispatcher-report${searchQuery}`
              : "/dispatcher-report"
          }
          Icon={<MyOrdersIcon />}
        />
      )}
      <GroupBlock {...myWork(location, searchQuery)} />
      <GroupBlock {...jobDispatching(location, searchQuery)} />
      <GroupBlock {...accounting(location, searchQuery)} />
      <GroupBlock {...userManagement(location, searchQuery)} />
      <GroupBlock {...accountManagement(location, searchQuery)} />
      <NavItem
        label="archive"
        link="https://archive.monteraplus.com"
        Icon={<ArchiveIcon />}
        external={true}
      />
      <Feature featureKey={Features.helpPage}>
        {() => (
          <NavItem
            label="help"
            link={
              location.pathname === "/help" ? `/help${searchQuery}` : "/help"
            }
            Icon={<HelpIcon />}
          />
        )}
      </Feature>
      <Feature featureKey={Features.supportPage}>
        {() => (
          <NavItem
            label="support"
            link={
              location.pathname === "/support"
                ? `/support${searchQuery}`
                : "/support"
            }
            Icon={<SupportIcon />}
          />
        )}
      </Feature>
      
      <Feature featureKey={Features.displayNewsPortal}>
        {() => (
          <NavItem
            label="news"
            link={
              location.pathname === "/news"
                ? `/news${searchQuery}`
                : "/news"
            }
            Icon={<HelpIcon />}
          />
        )}
      </Feature>
    </List>
  );
};

export default LeftNavBar;
