export const COMMENT_STATES = {
    ALL: {
        key: 'all',
        translationKey: "common:all.labels.comments.filters.state.values.all"
    },
    READ: {
        key: 'read',
        translationKey: "common:all.labels.comments.filters.state.values.read"
    },
    UNREAD: {
        key: 'unread',
        translationKey: "common:all.labels.comments.filters.state.values.unread"
    }
};