const parseBool = (v: string | undefined) => v === 'true';

const FEATURES_FLAGS = {
  SHOW_EXPERIMENTAL_BUTTON: parseBool(process.env.REACT_APP_SHOW_EXPERIMENTAL_BUTTON),
  SHOW_SCHEDULER: parseBool(process.env.REACT_APP_SHOW_SCHEDULER),
  SHOW_SCHEDULER_SERVICE_PANEL: parseBool(process.env.REACT_APP_SHOW_SCHEDULER_SERVICE_PANEL),
  QUESTIONNAIRE: parseBool(process.env.REACT_APP_QUESTIONNAIRE),
  STANDALONE_COMMENTS: parseBool(process.env.REACT_APP_STANDALONE_COMMENTS),
  COMMENTS_READ_ALL_BUTTON: parseBool(process.env.REACT_APP_COMMENTS_READ_ALL_BUTTON),
  INVOICING: parseBool(process.env.REACT_APP_INVOICING),
  DISPATCHER_REPORT: parseBool(process.env.REACT_APP_DISPATCHER_REPORT),
  MARK_COMMENT_AS_UNREAD: parseBool(process.env.REACT_APP_MARK_COMMENT_AS_UNREAD),
  SHOW_COMMENT_SHARED_EMAILS: parseBool(process.env.REACT_APP_SHOW_COMMENT_SHARED_EMAILS),
  ALLOW_UPLOAD_QUESTIONNAIRE: parseBool(process.env.REACT_APP_ALLOW_UPLOAD_QUESTIONNAIRE),
}

export default FEATURES_FLAGS
