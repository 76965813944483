// Config object to be passed to Msal on creation
// import { LogLevel } from '@azure/msal-common';
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const msalConfig = {
    auth: {
        authority: process.env.REACT_APP_CONFIG_Authority,
        clientId: process.env.REACT_APP_CONFIG_ClientID,
        redirectUri: process.env.REACT_APP_CONFIG_Redirect_URI,
        postLogoutRedirectUri:process.env.REACT_APP_CONFIG_Post_LogoutRedirect_URI,
    },
    cache: {
        cacheLocation: process.env.REACT_APP_CONFIG_Cache_Location,
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
       /* loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            piiLoggingEnabled: false
        },*/
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
    }
};
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authenticationParameters = {
    scopes: [process.env.REACT_APP_CONFIG_Scopes],
};

export const authenticationParametersFile = {
    scopes: [process.env.REACT_APP_CONFIG_FILE_Scopes],
};
