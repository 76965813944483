import React from 'react';
import CommentItem from '../CommentItem/CommentItem';

const CommentsTodayGroupedTable = ({
    previewImageClassName,
    items
}) => {
    return (
        <>
            {items.map(y => (
                <CommentItem
                    key={y.messageId}
                    previewImageClassName={previewImageClassName}
                    comment={y}
                />
            ))}
        </>
    )
}

export default CommentsTodayGroupedTable;