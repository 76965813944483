import { msalInstance } from "../../../App";
import includes from "lodash/includes";
import {
  requiresInteractionErrorCodes,
} from "./authProvider";

export const authenticationParametersFile = {
  scopes: [process.env.REACT_APP_CONFIG_FILE_Scopes],
};

export const __getDataAuthObject = () => {
  try {
    return  msalInstance
      .acquireTokenSilent({
        ...authenticationParametersFile,
        account: msalInstance.getAllAccounts()[0],
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  } catch (e) {
    if (includes(requiresInteractionErrorCodes, e.errorCode)) {
      return msalInstance.loginRedirect({
        ...authenticationParametersFile,
        prompt: "select_account",
        account: msalInstance.getAllAccounts()[0],
      });
    }
    throw e;
  }
};
