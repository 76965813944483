import { msalInstance } from "../../../App";
import includes from "lodash/includes";

export const authenticationParameters = {
  scopes: [process.env.REACT_APP_CONFIG_Scopes],
};

export const requiresInteractionErrorCodes = [
  "consent_required",
  "interaction_required",
  "login_required",
  "user_login_error",
  "invalid_grant",
];
export const requiresErrorCodes = [
  "AADSTS700084",
  "AADSTS50058",
];
export const __getAuthObject = () => {
  try {
    return msalInstance
      .acquireTokenSilent({
        ...authenticationParameters,
        account: msalInstance.getAllAccounts()[0],
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  } catch (e) {
    console.log(e.error_codes);
    console.log(e);
    if (includes(requiresInteractionErrorCodes, e.errorCode) || includes(requiresInteractionErrorCodes, e.error_codes)) {
      return msalInstance.loginRedirect({
        ...authenticationParameters,
        prompt: "select_account",
        account: msalInstance.getAllAccounts()[0],
      });
    }
    throw e;
  }
};