import React, { useState } from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { ClockIcon, EyeIcon, ImportantIcon } from '../icons';
import { useStringShorting } from '../../../utils/hooks/useStringShorting';
import CommentAttachments from './CommentAttachments';
import MonteraDateDisplay from '../../../assets/reuseComponents/MonteraDateDisplay';
import { useTranslation } from 'react-i18next';

const CommentContent = ({
  className,
  previewImageClassName,
  comment,
}) => {
  const { isRead, isImportant, isSharedByEmail } = comment;
  const classes = useStyles({ isRead });

   const { t } = useTranslation()

  const [contentExpanded, setContentExpanded] = useState(false);

  const [shortenContent, hasOverflow] = useStringShorting(comment.message, 100, !contentExpanded);

  const onExpand = () => {
    setContentExpanded(true);
  };

  const onCollapse = () => {
    setContentExpanded(false);
  };

  return (
    <div className={clsx(classes.body, className)}>
      {isImportant && (
        <div className={classes.importantContainer}>
          <ImportantIcon />
          <Typography
            className={classes.importantText}
            variant="caption"
          >
            {t('common:all.labels.comments.important')}
          </Typography>
        </div>
      )}
      {isSharedByEmail && (
        <div className={classes.importantContainer}>
          <img src="/images/utils/shareIcon.png" alt="share" className={classes.shareByEmailIcon} />
          <Typography
            variant="caption"
          >
            {t('common:all.labels.comments.shared_by_email')}
          </Typography>
        </div>
      )}
      <div className={classes.sentInfo}>
        <Link
          className={classes.link}
          component={RouterLink}
          to={`users/details/${comment.createdById}`}
          target="_blank"
        >
          {comment.createdByName}
        </Link>
          <MonteraDateDisplay value={comment.createdDate} variant = "caption" format="L LT" />
      </div>
      <Typography variant="body2" style={{
        overflowWrap: 'break-word',
        whiteSpace: 'pre-line',
      }}>
        {shortenContent}
      </Typography>
      {hasOverflow && (
        <Link
          className={clsx(classes.link, classes.toggleShowMore)}
          component="button"
          variant="subtitle1"
          onClick={contentExpanded
            ? onCollapse
            : onExpand
          }
        >
          {contentExpanded
            ? t('common:common.labels.show_less')
            : t('common:common.labels.show_more')
          }
        </Link>
      )}
      <div className={classes.readStatusContainer}>
        {isRead && (
          <>
            <EyeIcon />
            <Typography variant="caption">
              {t('common:all.labels.comments.seen')}
            </Typography>
          </>
        )}
        {!isRead && (
          <>
            <ClockIcon />
            <Typography variant="caption">
              {t('common:all.labels.comments.waiting_to_be_read')}
            </Typography>
          </>
        )}
      </div>
      <CommentAttachments
        previewImageClassName={previewImageClassName}
        comment={comment}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  body: {
    '& > :not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
  sentInfo: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
  importantContainer: {
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
  importantText: {
    color: '#FB3640',
  },
  readStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(0.5),
    },
  },
  link: {
    color: '#2B59C3',
  },
  toggleShowMore: {
    fontSize: 14,
    textDecoration: 'none !important',
  },
  shareByEmailIcon: {
    width: 15,
    marginTop: -2
  }
}));

export default CommentContent;
