import React from "react";
import { Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./style";
import saddog from "./imgs/saddog.svg";
import monteraLogo from "./imgs/montera-logo.svg";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from 'react-i18next';

const Success = () => {
  const { img, wrapperImg, subfont, offset, box } = useStyles();
   const { t } = useTranslation()
  return (
    <Container>
      <div className={wrapperImg}>
        <img src={monteraLogo} alt="" style={{ padding: "32px" }} />
      </div>
      <Paper className={box}>
        <div className={wrapperImg}>
          <img src={saddog} alt="" className={img} />
        </div>
        <Typography align="center" variant="h3" className={offset}>
          {t("common:all.labels.unsubscribe.success.title")}
        </Typography>
        <Typography align="center" paragraph={true} className={subfont}>
          {t("common:all.labels.unsubscribe.success.subtitle")}
        </Typography>
        <Typography align="center" paragraph={true} className={subfont}>
          {t("common:all.labels.unsubscribe.content.you_can_undo")}
          <Link variant="body2" style={{ paddingLeft: "4px" }} to="/profile">
            {t(
              "common:all.labels.unsubscribe.content.account_preferences"
            )}
            .
          </Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default Success;
