import React from "react";
import { Typography, Grid, Button, Link } from "@material-ui/core";
import Icon from "./icons/404Icon.svg"
import { useStyles } from './styles';
import { ArrowLeft } from "src/assets/imgs";
import { Link as RouterLink } from "react-router-dom";
import { theme } from '../../utils/styleTheme/mainStyles';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="space-between" className={classes.mainWrapper}>
      <Grid item xs={6} style={{textAlign: 'right'}}>
        <div style={{textAlign: 'left', display: 'inline-block', }}>
          <Typography variant="h1" className={classes.title404} >
            404
          </Typography>
          <Typography paragraph variant="h3" className={classes.subTitle}>
            {t("common:all.labels.page_not_found.subTitile")}
          </Typography>
          <Typography paragraph className={classes.description}>
            {t("common:all.labels.page_not_found.description")} <Link href="mailto:someone@example.com" target="_top">{t("common:all.labels.page_not_found.email")}</Link>.
          </Typography>
          <br/>
          <div>
            <Button onClick={() => window.history.back()} variant="contained" color="primary" size="large" startIcon={<ArrowLeft/>} className={classes.button}>
              {t("common:common.labels.go_back")}
            </Button>
            <Button 
              to={`/`}
              component={RouterLink} 
              variant="text" 
              color="primary" 
              size="large" 
              className={classes.button}
              style={{marginLeft: theme.spacing(2)}}
            >
              {t("common:common.labels.open_home_page")}
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <img src={Icon} alt="404 icon" width="400" height="400"/>
      </Grid>
      
      
    </Grid>
  );
};

export default PageNotFound;
