import React, { useLayoutEffect, useState } from "react";
import clsx from 'clsx';
import "./styles.css";

const Loading = ({ style = {}, className }) => {
  return (
    <div className={clsx("loaderWrapper", className)} style={{ ...style }}>
      <div className="loader">
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__ball" />
      </div>
    </div>
  );
};

export const InvertLoading = ({ className, showLoader = true }) => {
  return (
    <div className={clsx("loaderWrapper", className)} style={{background: '#fff'}}>
      {showLoader && (
        <div className="loader">
          <div className="loader__bar" style={{background: '#003882'}}/>
          <div className="loader__bar" style={{background: '#003882'}}/>
          <div className="loader__bar" style={{background: '#003882'}}/>
          <div className="loader__bar" style={{background: '#003882'}}/>
          <div className="loader__bar" style={{background: '#003882'}}/>
          <div className="loader__ball" style={{background: '#003882'}}/>
        </div>
      )}
    </div>
  );
};

export const InvertLoadingWithDelay = ({ className, delay = 250 }) => {
  const [showLoader, setShowLoader] = useState(false);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => setShowLoader(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    <InvertLoading className={className} showLoader={showLoader} />
  );
};

export default Loading;
