import React, {useContext, useEffect} from "react";
import clsx from "clsx";

import { AppContext } from "../../utils/context/AppStore";
import TopNavBar from "./TopNavBar";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Tooltip from "@material-ui/core/Tooltip";
import LeftNavBar from "./LeftNavBar";
import { IconButton } from "@material-ui/core";
import Breadcrums from "./Breadcrumbs";

import { useMsal } from '@azure/msal-react';
import { Logo } from './Logo';
import { useTranslation } from 'react-i18next';


export const drawerWidth = 216;

export const useStyles = makeStyles((theme) => {
  return {
    hidden: {
      display: 'none'
    },
    appBar: {
      width: "calc(100% - 65px)",
      backgroundColor: "#fff",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {}),
      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.05)",
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    TopNavBar: {
      display: "flex",
      alignContent: "space-between",
      marginLeft: "auto",
    },
    hide: {
      display: "none",
    },
    drawer: {
      root: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    drawerOpen: {
      backgroundColor: theme.palette.primary.main,
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&::-webkit-scrollbar': {
        width: '0.4em',
        height: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#fff",
        outline: '1px solid slategrey'
      }
    },
    drawerClose: {
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8) + 1,
      },
    },
    topNavToolbal: {
      padding: `0 0 0 ${theme.spacing(2)}px`,
      minHeight: 56,
    },
    logoWrap: {
      width: "100%",
      height: 50,
      display: "absolute",
      justifyContent: "center",
      padding: theme.spacing(2),
      transition: ".5s"
    },
    logoOnOpen: {
      position: "fixed"
    },
    logoOnClose: {
      position: "relative"
    },
  };
});

const Navigation = React.memo(({
  open,
  handleOpen,
  commentsOpen,
  onCommentsClick,
}) =>{
  const { accounts } = useMsal();
  const { t } = useTranslation()
  const { GlobalStore , ContextAPI , fullScreenMode} = useContext(AppContext);
  const classes = useStyles();
  useEffect(() => {
    if (!GlobalStore.translationsLoaded && accounts.length >= 0) {
      ContextAPI.initializeAppStore()
    }
  },[])
  return (
    <>
      <AppBar
        color="default"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
          [classes.hidden]: fullScreenMode
        })}
      >
        <Toolbar className={classes.topNavToolbal} id="topNavToolbal">
          <Tooltip
            title={t("common:all.labels.navigation.collapse")}
          >
            <IconButton onClick={() => handleOpen()}>
              {open && (
                <svg width="5" height="8" viewBox="0 0 5 8" fill="none">
                  <path
                    d="M4.00012 1.28564L1.14298 4.14279L4.00012 6.99993"
                    stroke="#141E30"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <svg width="12" height="14" viewBox="0 0 12 14" fill="none">
                <path
                  d="M1 1H11"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 7H11"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 13H11"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {!open && (
                <svg width="5" height="8" viewBox="0 0 5 8" fill="none">
                  <path
                    d="M1 7L3.85714 4.14286L1 1.28571"
                    stroke="#141E30"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </IconButton>
          </Tooltip>
          <Breadcrums />
          <div className={classes.TopNavBar}>
            <TopNavBar
              commentsOpen={commentsOpen}
              onCommentsClick={onCommentsClick}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.hidden]: fullScreenMode
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <Logo open={open} />
        <LeftNavBar open={open} />
      </Drawer>
    </>
  );
})

export default Navigation;
