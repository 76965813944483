import React, { createContext, useState } from "react";
import useHotkeysFlag from "src/utils/hooks/useHotkeysFlag";
import { HOTKEYS } from "src/utils/constants/Hotkeys";

const defaultState = {
  open: true,
};

export const LeftBarContext = createContext(defaultState);

const LeftBarStore = (props) => {
  const [LeftBarStore, setLeftBarStore] = useState(defaultState);

  useHotkeysFlag(HOTKEYS.LEFT_NAVBAR, () => toggleLeftBar());

  const setContextData = (key, data) => {
    setLeftBarStore((prev) => ({
      ...prev,
      [key]: data,
    }));
  };
  const toggleLeftBar = () => {
    setLeftBarStore({ open: !LeftBarStore.open });
  };

  const LeftBarApi = {
    setData: setContextData,
    toggleLeftBar,
  };
  return (
    <LeftBarContext.Provider
      value={{
        LeftBarStore,
        setLeftBarStore,
        LeftBarApi,
      }}
    >
      {props.children}
    </LeftBarContext.Provider>
  );
};

export { LeftBarStore };
