import { Typography } from '@material-ui/core';
import React from 'react';
import MonteraConfirmationDialog from '../../assets/reuseComponents/MonteraConfirmationDialog';
import { useTranslation } from 'react-i18next';

const ConfirmReadingDialog = ({
    open,
    onClose = () => {},
    onSubmit = () => {},
    disabled
}) => {
     const { t } = useTranslation()

    const Action = submit => {
        if (submit) {
            onSubmit();
            return;
        }
        onClose()
    }

    return (
        <MonteraConfirmationDialog
            open={open}
            title={(<>
                {t("common:all.labels.comments.mark_all_comments_as_read")}
                <br />
                <br />
                <Typography variant="body1">
                    {t("common:all.labels.comments.mark_all_comments_as_read_3")}
                </Typography>
            </>)}

            okText={t("common:all.labels.navigation.yes")}
            cancelText={t("common:common.labels.cancel")}
            Action={Action}
            onClose={onClose}
            acceptDisabled={disabled}
        />
    )
}

export default ConfirmReadingDialog;
