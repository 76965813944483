import { __getAuthObject } from "../../../utils/context/controllers/authProvider";

const __apiVersion = process.env.REACT_APP_ApiVersion;
const __domain = process.env.REACT_APP_Domain;
const __metaApi = process.env.REACT_APP_MetaApi;

export const postLineItem = async (data) => {
  if (data.length <= 0) return;
  const authObject = await __getAuthObject();
  return await fetch(
    `${__domain}/${__apiVersion}/LineItems/list`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authObject.accessToken}`,
      },
      body: JSON.stringify({
        lineItemList: data
      })
    }).then((res) => {
    return res
  });
}
export const deleteLineItems = async (data) => {
  const authObject = await __getAuthObject();
  return await fetch(
    `${__domain}/${__apiVersion}/LineItems/delete`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authObject.accessToken}`,
      },
      body: JSON.stringify(data)
    }).then((res) => {
    return res
  });
}

export const updateLineItems = async (id, data) => {
  const authObject = await __getAuthObject();
  return await fetch(
    `${__domain}/${__apiVersion}/LineItems/${id}`,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authObject.accessToken}`,
      },
      body: JSON.stringify(data)
    }).then((res) => {
    return res
  });
}
export const updateLineItemsList = async (data) => {
  if (data.length <= 0) return;
  const authObject = await __getAuthObject();
  return await fetch(
    `${__domain}/${__apiVersion}/LineItems/list`,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authObject.accessToken}`,
      },
      body: JSON.stringify({
        lineItemList: data
      })
    }).then((res) => {
    return res
  });
}
export const getUnits = async (id) => {
  const authObject = await __getAuthObject();
  return await fetch(
    `${__metaApi}/${__apiVersion}/Prices/partner/${id}/unit/list`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};
export const getPrice = async (type = "writein", id) => {
  const authObject = await __getAuthObject();
  const req = !!id
    ? `${__metaApi}/${__apiVersion}/Prices/${type}/priceList/list/${id}`
    : `${__metaApi}/${__apiVersion}/Prices/${type}/priceList/list`
  return await fetch(
    req,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};
export const getService = async (id) => {
  const authObject = await __getAuthObject();
  return await fetch(
    `${__domain}/${__apiVersion}/Projects/${id}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};
export const getPartnerInfo = async (id) => {
  const authObject = await __getAuthObject();
  return await fetch(
    `${__domain}/${__apiVersion}/ServiceOrders/${id}/PartnerInfo`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};
export const getCustomerInfo = async (id) => {
  const authObject = await __getAuthObject();
  return await fetch(
    `${__domain}/${__apiVersion}/ServiceOrders/${id}/customerInfo`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};
export const getPartnerInventory = async (serviceId, type) => {
  const mapType = {
    "s_details": "service",
    "so_details": "serviceOrder",
  }
  if (!serviceId) return;
  const authObject = await __getAuthObject();
  return await fetch(
    `${__domain}/${__apiVersion}/LineItems/partnerInventory/${mapType[type]}/${serviceId}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};

export const getProductCatalog = async (partnerId) => {
  if (!partnerId) return;
  const authObject = await __getAuthObject();
  return await fetch(
    `${__metaApi}/${__apiVersion}/Prices/partner/${partnerId}/productCatalog`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};
export const getPartnerPrices = async (partnerId) => {
  if (!partnerId) return;
  const authObject = await __getAuthObject();
  return await fetch(
    `${__metaApi}/${__apiVersion}/Prices/partner/${partnerId}/priceList/list`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};

export const SCollection = async (serviceOrderId,  type = "serviceOrder") => {
  if (!serviceOrderId) return;
  const authObject = await __getAuthObject();
  return await fetch(
    `${__domain}/${__apiVersion}/LineItems/${type === "s_details"? "service": "serviceOrder"}/${serviceOrderId}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authObject.accessToken}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};
