export const defaultState = {
  externalPrices: [],
  invoicedLookup: [],
  writeinPrices: {
    items: []
  },
  priceCatalog: [],
  partnerInventory: [],
  partnerInfo: {
    partner: {
      key: ""
    }
  },
  editData: {},
  customerInfo: {
    customer: {
      key: ""
    }
  },
  meta: {},
  invoice: {
    lineItemsList: [],
    extraItemsList: [],
    writeinPrices: []
  },
  needRemove: [],
  modal: {
    open: ""
  },
  units: [],
  existingData: [],
  persistingDate: [],
  SOInfo: {
    isIkeaRelated: false
  },
  currencySymbol: "",
  updateScenario: false,
  services: [],
  total: {total: 0, totalVat: 0, totalQty: 0},
  relatedServices: "",
  searchedValueLI: "",
  searchedValueEX: "",
  comeFrom: "",
  loading: {
    externalPrices: false,
    writeinPrices: false,
    creation: false,
    units: false,
    update: false
  },
  sellers: [],
  defaultSellerId: null,
  error: false
};
export default defaultState
