import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { postRecord } from "../../utils/context/controllers/crud";
import { useStyles } from "./style";
import ill from "./imgs/ill.svg";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

const __domain = process.env.REACT_APP_Domain;

const __apiVersion = process.env.REACT_APP_ApiVersion;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UnsubscribeMenu = () => {
  const {
    boldText,
    wrapperImg,
    subfont,
    subfont2,
    offset,
    btnGroup,
    cancelBtn,
    submitBtn,
  } = useStyles();
  let query = useQuery();

   const { t } = useTranslation()
  const history = useHistory();
  // DATA
  const uuk = query.get("uuk");
  const [values, setValues] = useState({
    unsubscribePersonalEmails: false,
    unsubscribeGeneralEmails: false,
    unsubscribeSms: false,
  });

  useEffect(() => {
    const { language, languages } = i18next;
    if (language && languages.includes(language)) {
      return;
    }
    i18next.changeLanguage("en")
  }, [])

  //GET DATA
  useEffect(() => {
    (async () => {
      await fetch(`${__domain}/${__apiVersion}/unsubscribe?uuk=${uuk}`)
        .then(async (res) => {
          const data = await res.json();
          setValues(data.subscriptionPreferences);
        })
        .catch((error) => {
          history.push("/404");
          console.log(error);
        });
    })();
  }, [uuk]);
  // DATA HANDLING
  const setUnsubscribe = (event) => {
    const isChecked = event.target.checked;
    const name = event.target.name;
    setValues({ ...values, [name]: !isChecked });
  };

  const handleUnsubscribeUpdate = async (event) => {
    event.preventDefault();
    await postRecord("unsubscribe", values, `uuk=${query.get("uuk")}`).then(
      (response) => {
        if (response.status === 200) {
          history.push("/unsubscribe/success");
        }
      }
    );
  };
  // Link to profile page
  const toProfile = (event) => {
    history.push("/profile");
    event.preventDefault();
  };
  // Link to profile page
  const onCancel = (event) => {
    history.push("/");
    event.preventDefault();
  };
  return (
    <>
      <div className={wrapperImg}>
        <img src={ill} alt="" />
      </div>
      <Typography align="center" variant="h3" className={offset}>
        {t("common:all.labels.unsubscribe.content.header_title")}
      </Typography>
      <Typography align="left" paragraph={true} className={subfont}>
        {t("common:all.labels.unsubscribe.content.provide_you_with")}
        <span className={boldText}>
          {t("common:all.labels.unsubscribe.content.useful_information")}
        </span>
        {t("common:all.labels.unsubscribe.content.information")}
        <span className={boldText}>
          {t("common:all.labels.unsubscribe.content.important_to_you")}
        </span>
      </Typography>
      <Typography align="left" paragraph={true} className={subfont}>
        {t("common:all.labels.unsubscribe.content.kind_of_updates")}
        <span className={boldText}>
          {t("common:all.labels.unsubscribe.content.receive_from_Montera")}
        </span>
      </Typography>
      <Typography align="left" paragraph={true} className={subfont}>
        {t("common:all.labels.unsubscribe.content.you_can_undo")}
        <span
          className={boldText}
          style={{
            cursor: "pointer",
          }}
          onClick={toProfile}
        >
          {t("common:all.labels.unsubscribe.content.account_preferences")}
          .
        </span>
      </Typography>
      <Typography align="left" variant="h4" className={offset}>
        {t("common:all.labels.unsubscribe.checkbox.title")}
      </Typography>
      <form autoComplete="off" onSubmit={handleUnsubscribeUpdate}>
        <FormControlLabel
          control={
            <Checkbox
              onClick={setUnsubscribe}
              value={values["unsubscribePersonalEmails"]}
              checked={!values["unsubscribePersonalEmails"]}
              name="unsubscribePersonalEmails"
              color="primary"
            />
          }
          label={
            <>
              <Typography align="left" variant="body1">
                {t("common:all.labels.unsubscribe.emailPersonal.label_title")}
              </Typography>
              <Typography align="left" className={subfont2}>
                {t("common:all.labels.unsubscribe.emailPersonal.sublabel")}
              </Typography>
            </>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              onClick={setUnsubscribe}
              value={values["unsubscribeGeneralEmails"]}
              checked={!values["unsubscribeGeneralEmails"]}
              name="unsubscribeGeneralEmails"
              color="primary"
            />
          }
          label={
            <>
              <Typography align="left" variant="body1">
                {t("common:all.labels.unsubscribe.emailGeneral.label_title")}
              </Typography>
              <Typography align="left" className={subfont2}>
                {t("common:all.labels.unsubscribe.emailGeneral.sublabel")}
              </Typography>
            </>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              onClick={setUnsubscribe}
              value={values["unsubscribeSms"]}
              checked={!values["unsubscribeSms"]}
              name="unsubscribeSms"
              color="primary"
            />
          }
          label={
            <>
              <Typography align="left" variant="body1">
                {t("common:all.labels.unsubscribe.phoneSms.label_title")}
              </Typography>
              <Typography align="left" className={subfont2}>
                {t("common:all.labels.unsubscribe.phoneSms.sublabel")}
              </Typography>
            </>
          }
        />
        <div className={btnGroup}>
          <Button className={submitBtn} type="submit">
            {t("common:common.labels.save_preferences")}
          </Button>
          <Button onClick={onCancel} className={cancelBtn}>
            {t("common:common.labels.cancel")}
          </Button>
        </div>
      </form>
    </>
  );
};
export default UnsubscribeMenu;
