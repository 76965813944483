import React, { useEffect, useContext } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import PageNotFound from "./components/PageNotFound/PageNotFound";
import Unsubscribe from "./components/PageUnsubscribe/PageUnsubscribe";
import Success from "./components/PageUnsubscribe/UnsubscribeSuccess";

import { AppContext } from "./utils/context/AppStore";
import FEATURES_FLAGS from "./FeaturesFlags";

import MonteraRoleGuard from "./assets/reuseComponents/MonteraGuards/MonteraRoleGuard";
import { ROLES } from "./assets/reuseComponents/MonteraGuards/constants";
import AccessDeniedPage from "./components/AccessDenied/AccessDenied";
import InefficientPrivilegesPage from "./components/InefficientPrivileges/InefficientPrivileges";
import MenuContentSuspense from "./assets/reuseComponents/Suspense/MenuContentSuspense";
import Feature from "./assets/reuseComponents/Feature/Feature";
import { Features } from "src/utils/context/FeatureFlagProvider/types";
import Preloader from "./assets/reuseComponents/Preloader";
import useHotkeysFlag from "./utils/hooks/useHotkeysFlag";
import { HOTKEYS } from "src/utils/constants/Hotkeys";

const BusinessUnits = React.lazy(() => import("./components/BusinessUnits"));

const DispatcherReportPage = React.lazy(() =>
  import("./components/DispatcherReport")
);
const DashboardPage = React.lazy(() => import("./pages/StaffDashboard"));

const CompletedServicesDrillDown = React.lazy(() =>
  import("./components/ServicesDrilldown/CompletedServicesDrillDown")
);
const ServicesByStatusDrillDown = React.lazy(() =>
  import("./components/ServicesDrilldown/ServicesByStatusDrillDown")
);
const StaffComments = React.lazy(() =>
  import("./components/StaffComments/StaffComments")
);

const SOPerPartnerDrilldown = React.lazy(() =>
  import("./components/ServicesDrilldown/SOPerPartnerDrilldown")
);
const ActiveSOByOwnerDrilldown = React.lazy(() =>
  import("./components/ServicesDrilldown/ActiveSOByOwnerDrilldown")
);

const MyOrders = React.lazy(() => import("./components/MyOrders"));
const ContractorsPage = React.lazy(() =>
  import("./components/StaffContractors/StaffContractors")
);
const StaffContractorDetails = React.lazy(() =>
  import(
    "./components/StaffContractors/StaffContractorDetails/StaffContractorDetails"
  )
);

const AllServicesDrillDown = React.lazy(() =>
  import("./components/ServicesDrilldown/AllServicesDrillDown")
);
const JobsAndTasksDrillDown = React.lazy(() =>
  import("./components/ServicesDrilldown/JobsAndTasksDrillDown")
);
const ServiceOrdersDrillDown = React.lazy(() =>
  import("./components/ServicesDrilldown/ServiceOrdersDrillDown")
);
const StaffTeams = React.lazy(() =>
  import("./components/StaffTeams/StaffTeams")
);
const StaffTeamDetails = React.lazy(() =>
  import("./components/StaffTeams/TeamDetails/StaffTeamDetails")
);
const StaffQuestionnaire = React.lazy(() =>
  import("./components/StaffQuestionnaire/StaffQuestionnaire")
);

// Customers
const StaffCustomers = React.lazy(() =>
  import("./components/StaffCustomers/StaffCustomers")
);
const StaffCustomerDetails = React.lazy(() =>
  import("./components/StaffCustomers/Details/CustomerDetails")
);
const StaffCustomersCreate = React.lazy(() =>
  import("./components/StaffCustomers/Create/CustomerCreate")
);
// Projects
const StaffProjects = React.lazy(() =>
  import("./components/StaffProjects/StaffProjects")
);
const StaffProjectsCreate = React.lazy(() =>
  import("./components/StaffProjects/ProjectsCreate/StaffProjectsCreate")
);
const StaffProjectsDetails = React.lazy(() =>
  import("./components/StaffProjects/ProjectDetails/StaffProjectsDetails")
);
const StaffProjectEdit = React.lazy(() =>
  import("./components/StaffProjects/StaffProjectEdit")
);
// Services
const StaffServices = React.lazy(() =>
  import("./components/StaffServices/StaffServices")
);
const WOCreate = React.lazy(() =>
  import("./components/StaffServices/Create/WorkOrder/WOCreate")
);
const StaffWorkOrderDetails = React.lazy(() =>
  import("./components/StaffServices/Details/WO/WODetails")
);
const SOCreate = React.lazy(() =>
  import("./components/StaffServices/Create/ServiceOrder/SOCreate")
);
const StaffServiceOrderDetails = React.lazy(() =>
  import("./components/StaffServices/Details/SO/SODetails")
);
const StaffServiceCreatePage = React.lazy(() =>
  import("./components/StaffServices/Create/Service/ServiceCreatePage")
);
const StaffServiceCreateBulkPage = React.lazy(() =>
  import(
    "./components/StaffServices/Create/Service/ServicesBulkCreation/StaffServiceCreateBulkPage"
  )
);
const ServiceDetails = React.lazy(() =>
  import("./components/StaffServices/Details/Service/ServiceDetails")
);
// Partners
const PartnersPage = React.lazy(() =>
  import("./components/StaffPartners/StaffPartners")
);
const StaffPartnersDetails = React.lazy(() =>
  import("./components/StaffPartners/Details/StaffPartnersDetails")
);
// Warehouses
const WarehousesPage = React.lazy(() =>
  import("./components/StaffWarehouses/StaffWarehouses")
);
const WarehouseDetails = React.lazy(() =>
  import("./components/StaffWarehouses/Details/StaffWarehousesDetails")
);
// Organizations
const StaffOrganizations = React.lazy(() =>
  import("./components/StaffOrganizations/StaffOrganizations")
);
//Delivery Address
const StaffDelieryAddress = React.lazy(() =>
  import("./components/StaffDeliveryAddress/StaffDeliveryAddress")
);
// Users
const StaffUsers = React.lazy(() =>
  import("./components/StaffUsers/StaffUsers")
);
const UserDetails = React.lazy(() =>
  import("./components/StaffUsers/UserDetails/UserDetails")
);
// Followups
const StaffFollowups = React.lazy(() =>
  import("./components/StaffFollowups/StaffFollowups")
);
// HELP
const StaffHelp = React.lazy(() => import("./components/StaffHelp/StaffHelp"));
//Invoicing
const Invoicing = React.lazy(() => import("./components/Invoicing"));
//Reports
const Reports = React.lazy(() => import("./components/Reports"));
// SUPPORT
const StaffSupport = React.lazy(() =>
  import("./components/StaffSupport/StaffSupport")
);
const AddLineItem = React.lazy(() => import("./components/LineItems"));
// Other
const UserProfile = React.lazy(() =>
  import("./components/StaffUserProfile/UserProfile")
);
const Sandbox = React.lazy(() => import("./components/Sandbox/Sandbox"));

const StaffSchedulerIndex = React.lazy(() =>
  import("./components/StaffCalendar/StaffSchedulerIndex")
);

const TimesheetReport = React.lazy(() =>
  import("./components/StaffTimesheets/StaffTimesheets")
);

const NewsReal = React.lazy(() => import("./components/NewsPortal/index"));

const RouterComponent = () => {
  const { pathname } = useLocation(); // CURRENT URL
  const { ContextAPI } = useContext(AppContext); // CONTEXT API to trigger Breadcrumbs component

  useHotkeysFlag(HOTKEYS.ORDERS_PAGE, () => {
    if (pathname !== "/orders") {
      window.location.href = "/orders";
    }
  });

  // URL TRACKING
  useEffect(() => {
    routesHistory(ContextAPI, pathname);
  }, [pathname]);
  return (
    <MenuContentSuspense>
      <Switch>
        <Redirect exact from="/" to="/dashboard/public" />
        <Redirect from="/#" to="/dashboard/public" />
        <Redirect from="/login" to="/dashboard/public" />
        <Redirect from="/home" to="/dashboard/public" />
        <Route
          exact
          path="/dashboard/completedServices"
          component={CompletedServicesDrillDown}
        />
        <Route
          exact
          path="/dashboard/services-by-status"
          component={ServicesByStatusDrillDown}
        />
        <Route
          exact
          path="/dashboard/all-services"
          component={AllServicesDrillDown}
        />
        <Route
          exact
          path="/dashboard/jobs-and-tasks"
          component={JobsAndTasksDrillDown}
        />
        <Route
          exact
          path="/dashboard/SOPerPartner"
          component={SOPerPartnerDrilldown}
        />
        <Route
          exact
          path="/dashboard/new-service-orders"
          component={ServiceOrdersDrillDown}
        />
        <Route
          exact
          path="/dashboard/ActiveSOByOwner"
          component={ActiveSOByOwnerDrilldown}
        />
        <Route exact path="/dashboard/:type" component={DashboardPage} />

        <Redirect
          exact
          path="/customers/details/:key"
          to="/customers/0/details/:key"
        />

        <Route
          exact
          path="/customers/create"
          component={StaffCustomersCreate}
        />
        <Route exact path="/customers/:page?" component={StaffCustomers} />
        <Route
          exact
          path="/customers/:tab/details/:key"
          component={StaffCustomerDetails}
        />

        <Route
          path="/projects/create/:customer?"
          component={StaffProjectsCreate}
        />
        <Route exact path="/projects/:page?" component={StaffProjects} />
        {/* PROBABLY NEEDS TO BE REMOVED */}
        <Route
          exact
          path="/s_details/details/:key"
          component={StaffProjectsDetails}
        />
        <Route
          exact
          path="/projects/details/edit/:key"
          component={StaffProjectEdit}
        />

        <Route exact path="/orders" component={StaffServices} />
        <Route exact path="/orders/work_order" component={WOCreate} />
        <Route
          exact
          path="/orders/wo_details/:key/"
          component={StaffWorkOrderDetails}
        />
        <Route exact path="/orders/service_order" component={SOCreate} />

        <Redirect
          exact
          from="/orders/so_details/:key"
          to="/orders/0/so_details/:key"
        />
        <Route
          exact
          path="/orders/:tab/so_details/:key"
          component={StaffServiceOrderDetails}
        />
        <Route
          exact
          path="/orders/service"
          component={StaffServiceCreatePage}
        />
        <Route
          exact
          path="/orders/service-bulk"
          component={StaffServiceCreateBulkPage}
        />
        <Redirect
          exact
          from="/orders/s_details/:key"
          to="/orders/0/s_details/:key"
        />
        <Route
          exact
          path="/orders/:tab/s_details/:key"
          component={ServiceDetails}
        />

        <Route exact path="/partners/:page?" component={PartnersPage} />
        <Route
          exact
          path="/partners/details/:key"
          component={StaffPartnersDetails}
        />

        <Route exact path="/warehouses/:page?" component={WarehousesPage} />
        <Route
          exact
          path="/warehouses/details/:key?"
          component={WarehouseDetails}
        />
        <Route
          exact
          path="/new_line_items/:type?/:key?/:tab?/:serviceKey?/"
          component={AddLineItem}
        />

        <Route
          exact
          path="/organizations/:page?"
          component={StaffOrganizations}
        />

        <Route exact path="/users" component={StaffUsers} />
        <Route exact path="/users/details/:key" component={UserDetails} />

        <Route exact path="/followups" component={StaffFollowups} />

        <Route
          exact
          path="/help"
          render={() => (
            <Feature
              featureKey={Features.helpPage}
              fallback={() => <Redirect to="/404" />}
              loading={() => <Preloader is={true} />}
            >
              {() => <StaffHelp />}
            </Feature>
          )}
        />

        <Route
          exact
          path="/support"
          render={() => (
            <Feature
              featureKey={Features.helpPage}
              fallback={() => <Redirect to="/404" />}
              loading={() => <Preloader is={true} />}
            >
              {() => <StaffSupport />}
            </Feature>
          )}
        />

        <Route
          exact
          path="/calendar"
          render={() =>
            FEATURES_FLAGS.SHOW_SCHEDULER ? (
              <StaffSchedulerIndex />
            ) : (
              <Redirect to="/404" />
            )
          }
        />



        <Route exact path="/timesheet" component={TimesheetReport} />

        <Route exact path="/my-orders" component={MyOrders} />

        <Route exact path="/contractors" component={ContractorsPage} />

        <Route
          exact
          path="/contractors/details/:key"
          component={StaffContractorDetails}
        />

        <Route exact path="/teams" component={StaffTeams} />

        <Route exact path="/teams/details/:key" component={StaffTeamDetails} />

        <Route
          exact
          path="/dispatcher-report"
          render={() =>
            FEATURES_FLAGS.DISPATCHER_REPORT ? (
              <DispatcherReportPage />
            ) : (
              <Redirect to="/404" />
            )
          }
        />

        <Route exact path="/delivery/:page?" component={StaffDelieryAddress} />
        <Route exact path="/profile" component={UserProfile} />
        <Route
          exact
          path="/sandbox"
          render={() => (
            <Feature
              featureKey={Features.sandboxPage}
              fallback={() => <Redirect to="/404" />}
              loading={() => <Preloader is={true} />}
            >
              {() => <Sandbox />}
            </Feature>
          )}
        />
        <Route exact path="/unsubscribe" component={Unsubscribe} />
        <Route exact path="/unsubscribe/success" component={Success} />
        <Route exact path="/business-units" component={BusinessUnits} />
        <Route
          path="/news"
          render={() => (
            <Feature
              featureKey={Features.displayNewsPortal}
              fallback={() => <Redirect to="/404" />}
              loading={() => <Preloader is={true} />}
            >
              {() => <NewsReal />}
            </Feature>
          )}
        />
        <Route path="/404" component={PageNotFound} />
        <Route
          path="/InefficientPrivilegesPage"
          component={InefficientPrivilegesPage}
        />

        <Route
          exact
          path="/questionnaire"
          render={() =>
            FEATURES_FLAGS.QUESTIONNAIRE ? (
              <StaffQuestionnaire />
            ) : (
              <Redirect to="/404" />
            )
          }
        />

        <Route
          exact
          path="/comments"
          render={() =>
            FEATURES_FLAGS.STANDALONE_COMMENTS ? (
              <StaffComments />
            ) : (
              <Redirect to="/404" />
            )
          }
        />

        <Route
          exact
          path="/invoicing"
          render={() =>
            FEATURES_FLAGS.INVOICING ? (
              <MonteraRoleGuard
                roles={[ROLES.ACCOUNTING]}
                success={() => <Invoicing />}
                deny={() => <AccessDeniedPage />}
              />
            ) : (
              <Redirect to="/404" />
            )
          }
        />
        <Route exact path="/reports/:type?" component={Reports} />

        <Redirect to="/404" />
      </Switch>
    </MenuContentSuspense>
  );
};

export default RouterComponent;

function routesHistory(ContextAPI, pathname) {
  // GET SESSION STORAGE DATA
  let historyArray = JSON.parse(sessionStorage.getItem("historyArray")) || [];
  // RESET IF /
  if (pathname.split("/").filter((el) => el !== "").length === 1) {
    historyArray = [];
  }

  // REMOVE FROM HISTORY ARRAY IF EXISTS AND NOT THE LEAST ITEM
  const removeIfExistsInBreadcrumbs = [
    "/orders/service/",
    "/orders/service",
    "/orders/work_order",
    "/orders/work_order/",
    "/orders/service_order",
    "/orders/service_order/",
  ];
  removeIfExistsInBreadcrumbs.map((el) => {
    if (
      historyArray.indexOf(el) > -1 &&
      historyArray.indexOf(el) < historyArray.length
    ) {
      return historyArray.splice(historyArray.indexOf(el), 1);
    } else {
      return null;
    }
  });

  // REMOVE LAST ITEM IF EXISTS AND THE LAST ITEM
  const rewriteIfRedirectsToSelf = ["orders/so_details", "orders/s_details"];

  rewriteIfRedirectsToSelf.map((el) => {
    const cleanLastHistoryItem =
      historyArray.slice(-1)[0] &&
      historyArray
        .slice(-1)[0]
        .split("/") // split the url to array
        .filter((el) => {
          return el !== "" && isNaN(el); // remove first & empty items
        })
        .join("/");
    const cleanCurrentPath = pathname
      .split("/") // split the url to array
      .filter((el) => {
        return el !== "" && isNaN(el); // remove first & empty items
      })
      .join("/");
    if (
      el === cleanLastHistoryItem &&
      cleanCurrentPath === cleanLastHistoryItem
    ) {
      return historyArray.pop();
    } else {
      return null;
    }
  });

  // REMOVE ON SWITCH TO PREV. URL
  if (historyArray.includes(pathname)) {
    historyArray.length = historyArray.indexOf(pathname) + 1;
  } else {
    switch (pathname) {
      case "/": // SET "HOME" IF "/""
      case "/dashboard/public":
        historyArray = ["/home"];
        break;
      default:
        // EXCEPTION FOR SO DETAILS NOT TO STORE SPECIFIC TAB NUMBER
        const cleanPath = pathname
          .split("/") // split the url to array
          .filter((el) => {
            return el !== "" && isNaN(el); // remove first & empty items
          })
          .join("/");
        if (cleanPath === "orders/so_details") {
          const keySO = Number(
            pathname
              .split("/")
              .filter((el) => {
                return !isNaN(el);
              })
              .pop()
          );
          historyArray.push(`/orders/0/so_details/${keySO}`);
          break;
        }
        // MOST USE CASES
        historyArray.push(pathname);
        break;
    }
  }
  // UPDATE SESSION STORAGE
  ContextAPI.setData("historyArray", historyArray);
  sessionStorage.setItem("historyArray", JSON.stringify(historyArray));
}
