import { isArray, uniqBy } from 'lodash';

export const compare = (a, b) => {
  if (a.service.name > b.service.name) return -1;
  if (a.service.name < b.service.name) return 1;
  return 0;
};
export const operation = (count, behaviour, customValue) => {
  if (behaviour === "custom") {
      return customValue || null // return value
  }
  if (behaviour === "inc") {
    return Math.trunc(Number(count)) + 1;
  }
  if (count === 1) return count; //position is important

  if (behaviour === "dec") {
    return Math.trunc(Number(count)) - 1;
  }
  return count;
};
export const customizer = (objValue, srcValue ) => {
  if (isArray(objValue)) {
    return uniqBy(srcValue.concat(objValue), 'key');
  }
}

export const aggregate = (prev, key, data) => {
  const isExist = data.persistingDate.find((ent) => {
    return `${ent.id}` === `${key}`
  })

  let upInvoice = {}
  for (const name in data.invoice) {
    upInvoice[name] = [
      ...data.invoice[name].filter((ent) => {
        return ent.type !== 'LOADED' || data.needRemove.indexOf(ent.id) >= 0;
      })
    ]
  }
  if(!!isExist) {
    return [
      ...prev.persistingDate.filter((elem) => `${elem.id}` !== `${isExist.id}`), {
        id: isExist.id,
        invoice: upInvoice
      }
    ]
  } else {
    return [...prev.persistingDate,{
      id: `${ key }`,
      invoice: upInvoice
    }]
  }
}
