import { __getCollection } from "../../utils/context/controllers/crud";
import qs from 'qs';
import { COMMENT_STATES } from "./constants";
import moment from "moment";

const mapLookupFromKey = x => ({ key: +x.key })

const minimizeFilters = filters => {
    if (!filters) {
        return filters;
    }

    let users;
    if (filters.users) {
        users = filters.users.map(mapLookupFromKey);
    }

    let isRead;
    if (filters.state) {
        if (filters.state.key === COMMENT_STATES.READ.key) {
            isRead = true;
        }
        if (filters.state.key === COMMENT_STATES.UNREAD.key) {
            isRead = false;
        }
    }

    let channels;
    if (filters.channels) {
        channels = filters.channels.map(mapLookupFromKey);
    }

    let customer;
    if (filters.customer && filters.customer.key) {
        customer = mapLookupFromKey(filters.customer);
    }

    let deliveryAddresses;
    if (filters.deliveryAddresses) {
        deliveryAddresses = filters.deliveryAddresses.map(mapLookupFromKey);
    }

    let createdBy;
    if (filters.createdBy) {
        createdBy = filters.createdBy.map(mapLookupFromKey);
    }

    let createdDateFrom;
    let createdDateTo;
    if (filters.createdDate) {
        const createdDate = moment(filters.createdDate);
        createdDateFrom = createdDate.clone().startOf('day').toISOString();
        createdDateTo = createdDate.clone().add(1, 'days').startOf('day').toISOString();
    }

    let hoursNumber;
    if (filters.hoursNumber) {
        hoursNumber = filters.hoursNumber;
    }

    let partner;
    if (filters.partner) {
        partner = mapLookupFromKey(filters.partner);
    }

    let serviceOrders;
    if (filters.serviceOrders) {
        serviceOrders = filters.serviceOrders.map(mapLookupFromKey);
    }

    let services;
    if (filters.services) {
        services = filters.services.map(mapLookupFromKey);
    }

    let serviceTypes;
    if (filters.serviceTypes) {
        serviceTypes = filters.serviceTypes.map(mapLookupFromKey);
    }

    return {
        users,
        isRead,
        channels,
        customer,
        deliveryAddresses,
        message: filters.message,
        createdBy,
        createdDateFrom,
        createdDateTo,
        hoursNumber,
        partner,
        serviceOrders,
        services,
        serviceTypes,
    };
}

const paramsSerializer = params => {
    return qs.stringify(params, { allowDots: true, skipNulls: true })
};

export const getComments = async (page, pageSize, sortBy, des, filters, cancelToken) => {
    const mappedFilters = minimizeFilters(filters);
    return await __getCollection('chat/standaloneComments', { page, pageSize, sortBy, des, filters: mappedFilters }, paramsSerializer, cancelToken);
}

export const getCommentAttachments = async (messageId) => {
    return await __getCollection(`chat/message/${messageId}/attachments`, { page: 1, pageSize: 1000 });
}