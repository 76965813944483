import React from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { FileTextIcon, GridIcon } from '../../StaffServices/imgs';
import { BriefcaseIcon } from '../../StaffCalendar/icons';
import { CartIcon, PackageIcon, UserIcon, FolderIcon } from '../icons';
import { useTranslation } from 'react-i18next';

const CommentInfo = ({
    className,
    comment
}) => {
    const { isRead } = comment;
    const classes = useStyles({ isRead });

     const { t } = useTranslation()

    return (
        <div className={clsx(classes.info, className)}>
            {!!comment.workOrderId && (
                <>
                    <FolderIcon />
                    <Link
                        className={classes.link}
                        component={RouterLink}
                        to={`/orders/wo_details/${comment.workOrderId}`}
                        target="_blank"
                    >
                        {comment.workOrderName}
                    </Link>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.wo')}
                    </Typography>
                </>
            )}
            {!!comment.serviceOrderOptionalName && (
                <>
                    <Typography className={classes.noIcon} variant='subtitle2'>
                        {comment.workOrderOptionalName}
                    </Typography>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.wo_name')}
                    </Typography>
                </>
            )}
            {!!comment.serviceOrderId && (
                <>
                    <GridIcon />
                    <Link
                        className={classes.link}
                        component={RouterLink}
                        to={`/orders/0/so_details/${comment.serviceOrderId}`}
                        target="_blank"
                    >
                        {comment.serviceOrderName}
                    </Link>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.so')}
                    </Typography>
                </>
            )}
            {!!comment.serviceOrderOptionalName && (
                <>
                    <Typography className={classes.noIcon} variant='subtitle2'>
                        {comment.serviceOrderOptionalName}
                    </Typography>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.so_name')}
                    </Typography>
                </>
            )}
            {!!comment.serviceId && (
                <>
                    <FileTextIcon />
                    <Link
                        className={classes.link}
                        component={RouterLink}
                        to={`/orders/0/s_details/${comment.serviceId}`}
                        target="_blank"
                    >
                        {comment.serviceName}
                    </Link>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.service')}
                    </Typography>
                </>
            )}
            {!!comment.serviceTypeName && (
                <>
                    <Typography className={classes.noIcon} variant='subtitle2'>
                        {comment.serviceTypeName}
                    </Typography>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.service_type')}
                    </Typography>
                </>
            )}
            {!!comment.partnerId && (
                <>
                    <BriefcaseIcon />
                    <Link
                        className={classes.link}
                        component={RouterLink}
                        to={`/partners/details/${comment.partnerId}`}
                        target="_blank"
                    >
                        {comment.partnerName}
                    </Link>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.partner')}
                    </Typography>
                </>
            )}
            {!!comment.warehouseId && (
                <>
                    <PackageIcon />
                    <Link
                        className={classes.link}
                        component={RouterLink}
                        to={`/warehouses/details/${comment.warehouseId}`}
                        target="_blank"
                    >
                        {comment.warehouseName}
                    </Link>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.warehouse')}
                    </Typography>
                </>
            )}
            {!!comment.customerId && (
                <>
                    <UserIcon />
                    <Link
                        className={classes.link}
                        component={RouterLink}
                        to={`/customers/details/${comment.customerId}`}
                        target="_blank"
                    >
                        {comment.customerName}
                    </Link>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.customer')}
                    </Typography>
                </>
            )}

            {!!comment.deliveryAddress && (
                <>
                    <CartIcon />
                    <Typography variant='subtitle2'>
                        {comment.deliveryAddress}
                    </Typography>
                    <Typography variant='caption'>
                        {t('common:all.labels.comments.info.delivery_address')}
                    </Typography>
                </>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    info: {
        padding: theme.spacing(1),
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gap: `${theme.spacing(1)}px`,
        alignItems: 'center',
        alignContent: 'flex-start'
    },
    noIcon: {
        gridColumn: 2
    },
    link: {
        color: '#2B59C3'
    }
}));

export default CommentInfo;
