// Logout timeout - 30 minutes (3600000 ms)
let MAX_INACTIVE_TIME = 1000 * 60 * 60 * 4;
let timeout;

export const startTimeout = () => {
  // console.log(
  //   "Starting Timeout: ",
  //   timeout,
  //   "time: ",
  //   new Date().getUTCHours(),
  //   new Date().getUTCMinutes()
  // );
  timeout = window.setTimeout(() => {
    // console.log(
    //   "About to redirect to login. Time: ",
    //   new Date().getUTCHours(),
    //   new Date().getUTCMinutes()
    // );
    window.location.href = "/login";
  }, MAX_INACTIVE_TIME);
};

// Restores Inactive Time
export const restartTimeout = () => {
  // console.log(
  //   "resetting timeout: ",
  //   timeout,
  //   "time: ",
  //   new Date().getUTCHours(),
  //   new Date().getUTCMinutes()
  // );
  clearTimeout(timeout);
  startTimeout();
};
