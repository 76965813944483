import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import uniqueId from "lodash/uniqueId";
import { LeftBarContext } from "./store";
import NavItem from "./NavItem";
import ParentNavItem from "./ParentNavItem";
import { makeStyles } from "@material-ui/core/styles";
import MonteraPermissionGuard from 'src/assets/reuseComponents/MonteraGuards/MonteraPermissionGuard';
import Feature from 'src/assets/reuseComponents/Feature/Feature';

export const useStyles = makeStyles(() => ({
  root: {
    color: "rgba(255, 255, 255, 0.5)",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    margin: "0px 16px 6px 16px",
    textTransform: "capitalize",
  },
  wrapper: {
    padding: '12px 0'
  }
}));

export const GroupBlock = ({ title, list }) => {
  const { root, wrapper } = useStyles()
  const { t } = useTranslation();
  const {
    LeftBarStore: { open },
  } = useContext(LeftBarContext);

  return (
    <div className={wrapper}>
      {open && !!title && (
        <Typography
          component="div"
          className={root}
        >
          {t(`all.labels.navigation.groups.${title}`)}
        </Typography>
      )}

      {list.map((items, index) => {
        if (!!items) {
          if(!!items.permission){
            if(!!items.featureFlag) return ( 
              <Feature key={index} featureKey={items.featureFlag} children={() => (
                <MonteraPermissionGuard  permissions={[items.permission]} fallback={()=>(<></>)} >
                  {() => <NavItem {...items} />}
                </MonteraPermissionGuard>
            )} /> )
            else return ( 
              <MonteraPermissionGuard  key={index} permissions={[items.permission]}>
                {() => <NavItem {...items} />}
              </MonteraPermissionGuard>
            )
          }
          if (
            open &&
            items.hasOwnProperty("subItems") &&
            items.subItems.length >= 0
          ) {
            return (
              <div key={uniqueId("nav")}>
                <ParentNavItem {...items} />
              </div>
            );
          }
          return (
            <div key={uniqueId("nav")}>
              <NavItem {...items} />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};



