import { DependencyList, useEffect } from "react";
import { usePrevious } from "./usePrevious";

type Destructor = () => void;
type Callback = (deps: DependencyList) => void | Destructor;

export const useUpdatedEffect = (callback: Callback, deps: DependencyList, assignInitial = true) => {
  const prevDeps = usePrevious(deps, assignInitial);
  useEffect(() => {
    if (!assignInitial) {
        return callback(deps);
    }
    let changed = false;
    for (let i = 0; i < deps.length; i++) {
      const dep = deps[i];
      const prevDep = prevDeps[i];
      if (prevDep !== dep) {
        changed = true;
      }
    }

    if (!changed) {
      return;
    }

    return callback(deps);
  });
};

// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-0.html#variadic-tuple-types
