export const Features = {
  carousel: "carousel",
  createServiceReport: "createServiceReport",
  shareCommentsToWorkers: "shareCommentsToWorkers",
  customersUriFiltering: "customersUriFiltering",
  jobsAndTasks: "jobsAndTasks",
  sandboxPage: "sandboxPage",
  helpPage: "helpPage",
  supportPage: "supportPage",
  hotkeys: "hotkeys",
  customerPhoneFilter: "customerPhoneFilter",
  schedulerDragAndDrop: "schedulerDragAndDrop",
  displayNewsPortal: "displayNewsPortal",
  timesheetEditing: "timesheetEditing"
} as const;

export type AvailableFeatureFlagKeys = keyof typeof Features;
export type AvailableFeatureFlags = typeof Features[AvailableFeatureFlagKeys];

export type Context = {
  flags: {
    [key in AvailableFeatureFlags]: boolean;
  };
  loading: boolean;
};
