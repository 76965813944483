import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { NoDataIcon } from '../../components/StaffTimesheets/images';

const NoDataDefault = () => {
    const classes = useStyles();
    return (
        <div className={classes.noDataWrapper}>
            <NoDataIcon />
            <Typography
                variant="h4"
                align="center"
            >
                {i18next.t(
                    "common:common.labels.no_search_results"
                )}
            </Typography>
        </div>
    );
}

const renderNoDataDefault = () => <NoDataDefault />;

const MonteraDataLoadingWrapper = ({
    children,
    hasData = true,
    loading,
    renderNoData = renderNoDataDefault
}) => {

    const classes = useStyles();

    if (loading) {
        return (
            <div className={classes.spinnerWrapper}>
                <CircularProgress size={60} className="dataLoading" color="primary" />
            </div>
        )
    }

    if (!hasData) {
        return renderNoData();
    }

    return children || null;
}

const useStyles = makeStyles(theme => ({
    noDataWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        "& :not(:first-child)": {
            marginTop: theme.spacing(1)
        }
    },
    spinnerWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }
}));

export default MonteraDataLoadingWrapper;