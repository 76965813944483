import React, { useContext, useState } from "react";
import { useEffect } from "react";
import createCtx from "src/utils/functions/createCtx";
import { AppContext } from "../AppStore";
import { getMetaData } from "../controllers/crud";
import { AvailableFeatureFlagKeys, Context } from "./types";

type Props = {
  children: React.ReactElement;
};

const [useCtx, Provider] = createCtx<Context>();
const initialState: Context = {
  flags: {
    carousel: false,
    createServiceReport: false,
    customersUriFiltering: false,
    shareCommentsToWorkers: false,
    jobsAndTasks: false,
    sandboxPage: false,
    helpPage: false,
    supportPage: false,
    hotkeys: false,
    customerPhoneFilter: false,
    schedulerDragAndDrop: false,
    displayNewsPortal: false,
    timesheetEditing: false
  },
  loading: true,
};

const FeatureFlagProvider = ({ children }: Props): React.ReactElement => {
  const {
    GlobalStore: { userData },
  } = useContext(AppContext) as any; //TODO: need typization

  const [featureFlagStore, setFeatureFlagStore] = useState(initialState);
  const fetchFeatureFlags = async () => {
    const flags = await getMetaData("Configuration/feature-flags/staff-portal");
    setFeatureFlagStore({
      flags,
      loading: false,
    });
  };

  useEffect(() => {
    if (!userData.key) {
      return;
    }
    fetchFeatureFlags();
  }, [userData.key]);

  return <Provider value={featureFlagStore}>{children}</Provider>;
};

const useFeatureFlag = (featureKey: AvailableFeatureFlagKeys) => {
  const featureFlags = useCtx();
  const active = featureFlags.flags[featureKey];
  return active;
};

const useFeatureFlags = (featureKeys: AvailableFeatureFlagKeys[]) => {
  const featureFlags = useCtx();
  const activeArray = featureKeys.map(
    (featureKey) => featureFlags.flags[featureKey]
  );
  return activeArray;
};

const useFeatureFlagsLoading = () => {
  const featureFlags = useCtx();
  return featureFlags.loading;
};

export {
  useCtx as useFeatureFlagContext,
  useFeatureFlag,
  useFeatureFlags,
  useFeatureFlagsLoading,
  FeatureFlagProvider,
};
