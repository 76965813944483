import { Menu, MenuItem, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useStyles } from '../style';
import { AppContext } from '../../../../utils/context/AppStore';
import CheckIcon from '@material-ui/icons/Check';

export interface LocaleType {
  currency: any;
  currencyId: number | string;
  englishName: string;
  id: string;
  iso2: string;
  iso3: string;
  localeTag: string;
  name: string;
  nameLabel: any;
  nameLabelId: any;
}
interface Props {
  anchorEl?: null | Element | ((element: Element) => Element);
  handleClose?: () => void;
  handler: (data: LocaleType) => void;
}

const NavIcon = ({ anchorEl, handleClose, handler }: Props) => {
  const { GlobalStore } = useContext<any>(AppContext);
  const { localesData, userData} = GlobalStore;

  const classes = useStyles();
  if (!localesData || !anchorEl) {
    return null;
  }

  return (

    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}

    >
      {localesData.map((locale: LocaleType) => {
        return <MenuItem
          key={locale.id}
          color="primary"
          onClick={() => handler(locale)}
        >
          <img
            alt="lang"
            src={`/images/flags/${locale.iso2}.png`}
            className={classes.flag}
          />
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 400, width: '100%' }}
            color="textPrimary"
          >
            {locale.englishName}
          </Typography>
          {userData.locale.iso2 === locale.iso2 && (
            <div className={classes.selectedIcon}>
              <CheckIcon color={'primary'} />
            </div>
          )}
        </MenuItem>;
      })}
    </Menu>
  );
};
export default NavIcon;
