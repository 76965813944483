import React from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";

const MonteraDateDisplay = ({ value, label, variant = "h6", format="L" }) => {
  return (
    <span>
      {label && (
        <Typography variant={variant} style={{ margin: "10px" }}>
          {label}
        </Typography>
      )}
      {moment(value).format(format)}
    </span>
  );
};

export default MonteraDateDisplay;
