import { Collapse, List, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import MonteraExpandIcon from '../../../assets/reuseComponents/MonteraExpandIcon';
import { PaperclipBigIcon } from '../icons';
import CommentAttachmentItem from './CommentAttachmentItem';
import MonteraDataLoadingWrapper from '../../../assets/reuseComponents/MonteraDataLoadingWrapper';
import { getCommentAttachments } from '../api';
import { useTranslation } from 'react-i18next';

const CommentAttachments = ({ comment, previewImageClassName }) => {
  const { attachments } = comment;

   const { t } = useTranslation()

  const fetched = useRef(false);

  const [expanded, setExpanded] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const classes = useStyles({ expanded });

  const getItems = async () => {
    setLoading(true);

    try {
      const response = await getCommentAttachments(comment.messageId);
      setItems(response.files);
    } finally {
      setLoading(false);
    }
  }

  const onRowClick = () => {
    setExpanded(prev => !prev);
  }

  useEffect(() => {
    if (!expanded) {
      return;
    }

    if (fetched.current) {
      return;
    }
    fetched.current = true;

    getItems();
  }, [expanded]);

  if (!attachments) {
    return null;
  }

  return (
    <>
      <div className={classes.row} onClick={onRowClick}>
        <MonteraExpandIcon expanded={expanded} />
        <div className={classes.body}>
          <PaperclipBigIcon />
          <Typography variant='subtitle2'>
            {t('common:all.labels.comments.attachments')}
          </Typography>
        </div>
        <Typography variant='subtitle2'>
          {attachments}
        </Typography>
      </div>
      <Collapse in={expanded}>
        <MonteraDataLoadingWrapper
          loading={loading}
        >
          <List>
            {items.map(x => (
              <CommentAttachmentItem
                key={x.key}
                previewImageClassName={previewImageClassName}
                attachment={x}
              />
            ))}
          </List>
        </MonteraDataLoadingWrapper>
      </Collapse>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  row: ({ expanded }) => ({
    borderRadius: theme.spacing(0.5),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    transition: theme.transitions.create("background", {
      duration: theme.transitions.duration.shortest
    }),
    cursor: 'pointer',
    background: 'rgba(20, 30, 48, 0.1)',

    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: `${theme.spacing(2)}px`
  }),
  body: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(1)
    }
  }
}))

export default CommentAttachments;
