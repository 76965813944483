import React, { useEffect, useState, useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Typography, Breadcrumbs, Link } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { theme } from "../../utils/styleTheme/mainStyles";
import { AppContext } from "../../utils/context/AppStore";
import { useTranslation } from 'react-i18next';

const Breadcrums = () => {
  /* ----------------------------------------------
    This component uses the Session Storage -> "historyArray"
    to build dynamic Breadcrumbs.

    Session Storage -> "historyArray" control function
    located in Routing.js file.

    Global Storage -> "historyArray" duplicate the Session Storage
    to trigger update of this component and for future extra cases.
  ----------------------------------------------   */
  const { t } = useTranslation()
  const { GlobalStore } = useContext(AppContext);

  const { pathname } = useLocation();

  const [history, setHistory] = useState([]);

  // GET OBJECT DATA BY URL PATH
  const getObjectPath = (obj, originPath) => {
    for (
      var i = 0, path = originPath.split("/"), len = path.length;
      i < len;
      i++
    ) {
      obj = obj[path[i]] || "";
    }
    return obj || "";
  };
  // GET PARAMS FROM SESSION STORAGE
  const getParams = (path) => {
    return sessionStorage.getItem(path); // GET URL PARAMS FROM SESSION STORAGE
  };

  const urlMatch = {
    home: {
      text: t("common:all.labels.navigation.home")
    },
    customers: {
      text: t("common:all.labels.navigation.customers"),
      details: {
        text: t("common:all.labels.customer_details.title")
      },
      create: {
        text: t("common:all.labels.customer_create.title")
      }
    },
    projects: {
      text: t("common:all.labels.navigation.projects"),
      details: {
        text: t("common:all.labels.project_details.title")
      },
      create: {
        text: t("common:all.labels.project_create.title")
      }
    },
    orders: {
      text: t("common:all.labels.navigation.orders"),
      work_order: {
        text: t("common:all.labels.services_create.work_order"),
        dynamic: true
      },
      wo_details: {
        text: t("common:all.labels.orders.wo_details"),
        dynamic: true
      },
      service_order: {
        text: t("common:all.labels.services_create.service_order"),
        dynamic: true
      },
      so_details: {
        text: t("common:all.labels.orders.so_details"),
        dynamic: true
      },
      service: {
        text: t("common:all.labels.orders.service_create"),
        dynamic: true
      },
      s_details: {
        text: t("common:all.labels.service_details.title"),
        dynamic: true
      }
    },
    new_line_items: {
      so_details: {
        text: t("common:all.labels.line_items.title"),
        dynamic: true
      }
    },
    partners: {
      text: t("common:all.labels.partners_list.title"),
      details: {
        text: t("common:all.labels.partner_details.title")
      }
    },
    warehouses: {
      text: t("common:all.labels.warehouses_list.title"),
      details: {
        text: t("common:all.labels.warehouses_details.title")
      }
    },
    users: {
      text: t("common:all.labels.users_list.title"),
      details: {
        text: t("common:all.labels.user_details.title")
      }
    },
    followups: {
      text: t("common:all.labels.navigation.followups")
    },
    organizations: {
      text: t("common:all.labels.organizations_list.title"),
      details: {
        text: "Details"
      }
    },
    profile: {
      text: t("common:all.labels.user_profile.title")
    },
    help: {
      text: t("common:all.labels.navigation.help")
    },
    support: {
      text: t("common:all.labels.navigation.support")
    },
    calendar: {
      text: t("common:all.labels.navigation.calendar")
    },
    'my-orders': {
      text: t("common:all.labels.navigation.my_orders")
    },
    contractors: {
      text: t("common:all.labels.navigation.contractors"),
      details: {
        text: t("common:all.labels.navigation.contractor_details")
      }
    },
    timesheet: {
      text: t("common:all.labels.navigation.timesheets")
    },
    reports: {
      text: t("common:all.labels.navigation.reports"),
      contractor: {
        text: t("common:all.labels.navigation.contractor"),
        dynamic: true
      },
      employee: {
        text: t("common:all.labels.navigation.employee"),
        dynamic: true
      },
    },
    teams: {
      text: t("common:all.labels.navigation.work_teams"),
      details: {
        text: t("common:all.labels.navigation.work_team_details"),
      }
    },
    questionnaire: {
      text: t("common:all.labels.navigation.questionnaire"),
    },
    comments: {
      text: t("common:all.labels.navigation.comments"),
    },
    'business-units': {
      text: "Busines Units",
    },
    news: {
      text: "News",
    },
  };

  // START
  useEffect(() => {
    setHistory([]);

    let boofer = new Set();
    const historyArray =
      JSON.parse(sessionStorage.getItem("historyArray")) || [];
    const verifyPath = pathname === "/" ? "/home" : pathname;

    !historyArray.includes(verifyPath) && historyArray.push(verifyPath);
    // Loop for each path in the URL history array
    historyArray.map((el) => {
      const cleanPath = el
        .split("/") // split the url to array
        .filter((el) => {
          return el !== "" && isNaN(el); // remove first & empty items
        })
        .join("/");

      if (
        cleanPath !== "/orders/service_order" ||
        (cleanPath !== "/orders/work_order" &&
          historyArray.indexOf(el) !== historyArray.length - 1)
      ) {
        const urlMatched = getObjectPath(urlMatch, cleanPath);
        if (urlMatched) {
          // PATH IS EXISTS
          urlMatched.path = el + (getParams(cleanPath) || "");
          boofer.add(urlMatched);
        }
      }
      return true;
    });
    setHistory([...boofer]);
  }, [GlobalStore.historyArray]);

  /// Check HERE
  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        style={{ margin: `0 ${theme.spacing(2)}px` }}
      >
        {history.map((el, idx) => {
          if (idx < history.length - 1) {
            return (
              <Link
                component={RouterLink}
                color="textSecondary"
                underline="none"
                to={el.path}
                key={idx}
              >
                {el.text}
              </Link>
            );
          } else {
            return (
              <Typography color="textPrimary" key={idx}>
                {el.text}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    </>
  );
};

export default Breadcrums;

export function GetBreadcrumbsHistory() {
  return JSON.parse(sessionStorage.getItem("historyArray")) || [];
}
