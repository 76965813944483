import { Button, ClickAwayListener, Divider, Drawer, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { ExternalLinkIcon, CrossIcon, NoCommentsIcon } from './icons';
import { getComments } from './api';
import CommentsGroupedTable from './CommentsGroupedTable/CommentsGroupedTable';
import { ReadAllIcon } from '../StaffServices/Details/Comments/components/ThreadsAndChat/Chat/images';
import { AppContext } from '../../utils/context/AppStore';
import MonteraDataLoadingWrapper from '../../assets/reuseComponents/MonteraDataLoadingWrapper';
import { COMMENT_STATES } from './constants';
import { /*getMetaData,*/ postRecord } from '../../utils/context/controllers/crud';
import ConfirmReadingDialog from './ConfirmReadingDialog';
import { useTranslation } from 'react-i18next';

const CommentsDrawer = ({
    paperClassName,
    open,
    onClose = () => { },
}) => {
    const { t }= useTranslation()
    const {  GlobalStore } = useContext(AppContext);

    const page = 1;
    const pageSize = 50;
    const sortBy = 'createdDate';
    const des = 'true';

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState({});

    const [dialogState, setDialogState] = useState({
        state: DIALOG_STATES.HIDDEN
    });

    const filtersLoaded = useRef(false);
    const hasUnread = items.some(x => !x.isRead);

    const onDialogClose = () => {
        setDialogState({
            state: DIALOG_STATES.HIDDEN
        })
    }


    const onReadClick = () => {
        setDialogState({
            state: DIALOG_STATES.CONFIRM_READING,
            saving: false
        })
    }

    const onRead = async () => {
        const unreadComments = items.filter(x => !x.isRead);
        const messageIds = unreadComments.map(x => x.messageId);

        const dto = {
            messageIds
        };

        setDialogState(prev => ({
            ...prev,
            saving: true
        }));

        try {
            await postRecord('chat/read', dto);
            await refreshItems();
            setDialogState({
                state: DIALOG_STATES.HIDDEN
            });
        } catch {
            setDialogState(prev => ({
                ...prev,
                saving: false
            }));
        }
    }

    const refreshItems = async () => {
        try {
            const response = await getComments(page, pageSize, sortBy, des, filters);
            setItems(response.comments);
            setTotalCount(response.totalCount);

        } finally {
            setLoading(false);
        }
    };

    const setDefaultFilters = async () => {
        setLoading(true);

        const users = [
            GlobalStore.userData
        ];

        const state = {
            ...COMMENT_STATES.UNREAD,
            displayText: t(COMMENT_STATES.UNREAD.translationKey)
        };

        // TODO: only Partner or all channels?
        // const fetchedChannels = await getMetaData('lookups/chatchannels');
        // const channels = fetchedChannels.filter(x => x.matchingText === 'Partner');

        filtersLoaded.current = true;
        setFilters(prev => ({
            ...prev,
            users,
            state,
            // channels
        }));
    }

    const onClickAway = () => {
        if (!open) {
            return;
        }

        onClose();
    }

    const onLinkClick = () => {
        onClose();
    }

    useEffect(() => {
        if (!open) {
            return;
        }
        setDefaultFilters();
    }, [open]);

    useEffect(() => {
        if (!filtersLoaded.current) {
            return;
        }
        refreshItems();
    }, [filters]);

    const classes = useStyles();

    let mouseEvent = 'onClick';
    if (!open) {
        mouseEvent = false;
    }

    if (dialogState.state !== DIALOG_STATES.HIDDEN) {
        mouseEvent = false;
    }

    const noData = !items.length && !loading;

    const moreCount = Math.max(totalCount - items.length, 0);

    const hasMore = !!moreCount;

    return (
        <>
            <ClickAwayListener
                onClickAway={onClickAway}
                mouseEvent={mouseEvent}
            >
                <Drawer
                    variant="persistent"
                    anchor="right"
                    className={classes.drawer}
                    classes={{
                        paper: clsx(classes.paper, paperClassName),
                    }}
                    BackdropProps={{ className: classes.backdrop }}
                    open={open}
                    onClose={onClose}
                >
                    <div className={classes.header}>
                        <IconButton
                            component={RouterLink}
                            to="/comments"
                            onClick={onLinkClick}
                        >
                            <ExternalLinkIcon className={classes.headerIcon} />
                        </IconButton>
                        <IconButton
                            onClick={onClose}
                        >
                            <CrossIcon className={classes.headerIcon} />
                        </IconButton>
                    </div>
                    <div className={classes.subHeader}>
                        <Typography variant='h4'>
                            {t('common:all.labels.navigation.comments')}
                        </Typography>
                        <Button
                            className={classes.actionButton}
                            onClick={onReadClick}
                            startIcon={<ReadAllIcon />}
                            disabled={!hasUnread}
                            variant='text'
                        >
                            {t('common:all.labels.comments.mark_all_as_read_tooltip')}
                        </Button>
                    </div>
                    <Divider />
                    <MonteraDataLoadingWrapper
                        loading={loading}
                        hasData={!noData}
                        renderNoData={() => (
                            <div className={classes.noDataWrapper}>
                                <NoCommentsIcon />
                                <Typography
                                    variant='h4'
                                >
                                    {t('common:all.labels.comments.no_new_label')}
                                </Typography>
                                <Typography
                                    className={classes.caption}
                                    variant='subtitle1'
                                >
                                    {t('common:all.labels.comments.no_new_caption')}
                                </Typography>
                            </div>
                        )}
                    >
                        <div className={classes.table}>
                            <CommentsGroupedTable
                                comments={items}
                                previewImageClassName={classes.previewImage}
                            />
                            {hasMore && (
                                <div className={classes.hasMoreWrapper}>
                                    <Typography
                                        variant='subtitle1'
                                    >
                                        {t('common:all.labels.comments.more_count', { moreCount })}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </MonteraDataLoadingWrapper>
                    <Divider />
                    <div className={classes.footer}>
                        <Button
                            component={RouterLink}
                            to='/comments'
                            className={classes.actionButton}
                            endIcon={<ExternalLinkIcon />}
                            onClick={onLinkClick}
                        >
                            {t('common:all.labels.comments.open_page')}
                        </Button>
                    </div>
                </Drawer>
            </ClickAwayListener>

            <ConfirmReadingDialog
                open={dialogState.state === DIALOG_STATES.CONFIRM_READING}
                onClose={onDialogClose}
                onSubmit={onRead}
                disabled={dialogState.saving}
            />
        </>
    );
}

const DIALOG_STATES = {
    HIDDEN: 0,
    CONFIRM_READING: 1,
}

const useStyles = makeStyles((theme) => ({
    drawer: {
        position: "sticky",
        overflow: 'hidden',
        pointerEvents: 'none',
        zIndex: theme.zIndex.drawer,
    },
    paper: {
        marginTop: 57,
        marginRight: theme.spacing(1),
        pointerEvents: 'all',

        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 57px)",
        minHeight: 700,
        minWidth: 800,
    },
    backdrop: {
        position: "absolute !important",
        background: 'transparent'
    },

    header: {
        background: '#1B3A7F',
        padding: `${theme.spacing(1)}px 0px`,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        },
    },
    headerIcon: {
        color: 'white'
    },
    subHeader: {
        padding: `0px ${theme.spacing(2)}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        },
    },
    noDataWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > :not(:first-child)': {
            marginTop: theme.spacing(2)
        }
    },
    table: {
        overflow: 'auto',
        flex: 1,
    },
    hasMoreWrapper: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    actionButton: {
        padding: theme.spacing(2),
        color: '#2B59C3',
        '&[disabled]': {
            background: '#fff !important',
            color: '#2B59C3 !important',
            opacity: 0.5
        }
    },
    caption: {
        color: 'rgba(20, 30, 48, 0.5)'
    },
    previewImage: {
        maxWidth: 600
    }
}));

export default CommentsDrawer;
