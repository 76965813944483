import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { authenticationParameters } from '../../utils/context/controllers/authProvider';
import { useMsal } from '@azure/msal-react';
import Loading from '../Suspense';
import bgImage from "../../assets/imgs/login-bg.jpeg";
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: "30vh",
    width: "250px",
    height: "50px",
    color: "white",
    fontSize: "17px",
    borderRadius: "50px",
    border: "2px solid white",
    transition: "ease-out 1s",
    letterSpacing: "2px",
    "&:hover": {
      border: "2px solid white",
      backgroundColor: "#085c9c"
    }
  },
  input: {
    display: "none"
  },
  wrap: {
    textAlign: "center",
    backgroundColor: "#003882",
    height: "100vh"
  },
  title: {
    color: "white",
    fontWeight: "600",
    paddingTop: "20vh",
    padding: "40px"
  },
  subtitle: {
    color: "white",
    paddingTop: 20,
  },
  loader: {
    color: "#ffffff",
    marginTop: "20vh"
  },
  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  "@media (max-width: 600px)": {
    button: {
      marginTop: "25vh"
    },
    titleWrapper: {
      paddingTop: "10vh"
    }
  },
  footer: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    color: "#ffffff",
    bottom: 10,
    lineHeight: '40px'
  },
  link: {
    color: "#ffffff"
  }
}));
const Login = React.memo(() => {
  const classes = useStyles();
  const { instance, inProgress } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect({ ...authenticationParameters,
      prompt: 'select_account'
  })
  }

  return (
    <div className={clsx(classes.wrap, classes.background)} color="primary">
      <Typography variant="h2" component="h2" className={classes.title}>
        Montera+
      </Typography>
      <Typography variant="h3" component="h3" className={classes.subtitle}>
        Staff Portal
      </Typography>
      {inProgress === "handleRedirect" ? (
        <Loading style={{ height: 100, paddingTop: 300, background: 'transparent', margin: 0, }} />
      ) : (
        <Button variant="outlined" color="primary" className={classes.button} onClick={handleLogin}>
          <span>Login</span>
        </Button>
      )}
      <Typography variant="h4" component="h4" className={classes.footer}>
        Driven and Developed by <a className={classes.link} href="https://aionis.io/" target="_blank" rel="noreferrer">Aionis</a>
        <br/>
        (c) Montera AS. All rights reserved
      </Typography>
    </div>
  );
});

export default Login;
