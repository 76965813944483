import { get, intersection, map } from "lodash";
import { useContext } from "react";
import { AppContext } from "../../../utils/context/AppStore";
import { ROLES } from './constants';

const DEFAULT_PROPS = {
    PERMISSIONS: [],
    SUCCESS: () => null,
    DENY: () => null,
    LOADING: () => null,
    ALLOW_ADMIN: true
};

const MonteraRoleGuard = ({ 
    roles = DEFAULT_PROPS.PERMISSIONS,
    success = DEFAULT_PROPS.SUCCESS,
    deny = DEFAULT_PROPS.DENY,
    loading = DEFAULT_PROPS.LOADING,
    allowAdmin = DEFAULT_PROPS.ALLOW_ADMIN
}) => {
    const { GlobalStore } = useContext(AppContext);

    if (!roles.length) {
        return success();
    }

    const userRoles = get(GlobalStore, 'userData.roles');
    if (!userRoles) {
        return loading();
    }

    const userRoleKeys = map(userRoles, x => x.key);

    const isAdmin = userRoleKeys.some(x => x === ROLES.ADMIN);
    if (isAdmin && allowAdmin) {
        return success();
    }

    const intersected = intersection(roles, userRoleKeys);

    if (!intersected.length) {
        return deny();
    }

    return success();
}

export default MonteraRoleGuard;