import { useFeatureFlag, useFeatureFlagsLoading } from "src/utils/context/FeatureFlagProvider";
import { AvailableFeatureFlagKeys } from "src/utils/context/FeatureFlagProvider/types";

type Props<T> = {
    featureKey: AvailableFeatureFlagKeys;
    children: () => T;
    fallback?: () => null;
    loading?: () => null;
};
const Feature = <T,>({ featureKey, children,  loading = () => null, fallback = () => null}: Props<T>) => {
    const flagsLoading = useFeatureFlagsLoading();
    const active = useFeatureFlag(featureKey);

    if(flagsLoading && loading) {
        return loading()
    }

    if (!active) {
        return  fallback();
    }
    return children();
};

export default Feature;