import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    height: "calc(100vh - 56px)",
    alignItems: "center",
    "& > div":{
      padding: theme.spacing(2)
    }
  },
  title404: {
    fontSize: "120px",
    fontWeight: 500,
    color: theme.palette.secondary.dark,
  },
  description: {
    maxWidth: 430
  },
  subTitle: {
    color: theme.palette.secondary.dark
  },
  button: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    fontWeight: 400
  }
}));
