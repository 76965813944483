import { makeStyles } from '@material-ui/core';
import React, { ReactNode, Suspense } from 'react';
import { InvertLoadingWithDelay } from 'src/components/Suspense';

type Props = {
  children: ReactNode
}

const MenuContentSuspense = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <Suspense fallback={<InvertLoadingWithDelay className={classes.loadingContainer} />}>
      {children}
    </Suspense>
  )
}

const useStyles = makeStyles(() => ({
  loadingContainer: {
    margin: 0,
    height: "calc(100vh - 58px)"
  }
}));

export default MenuContentSuspense;