import { FILE_PREVIEW_TYPES } from "../../assets/reuseComponents/MonteraFilePreview";
import { getFileData } from "../context/controllers/crud";

let fileUrlCache = {};

export const getFileUrl = async (fileServiceId) => {
  if (fileUrlCache[fileServiceId]) {
    return fileUrlCache[fileServiceId];
  }

  const data = await getFileData("files", fileServiceId);
  const url = window.URL.createObjectURL(new Blob([data]));
  fileUrlCache[fileServiceId] = url;

  return url;
};

export const getFileExtension = (file) =>
  file.fileName.slice(
    (Math.max(0, file.fileName.lastIndexOf(".")) || Infinity) + 1
  );

const imageExtensions = ["jpeg", "jpg", "png", "gif", "bmp"];
export const isImageFile = (file) => {
  const extension = getFileExtension(file);
  return isImageExtension(extension);
}

export const isImageExtension = ext =>
  imageExtensions.includes(ext.toLowerCase());

export const isPdfExtension = ext =>
  ext.toLowerCase() === 'pdf';

export const downloadFile = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  try {
    let element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    element.remove();
  } finally {
    window.URL.revokeObjectURL(url);
  }
}

export const downloadLinkedFile = (url, filename) => {
  let element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  element.remove();
}

export const mapPreviewTypeFromExtension = ext => {
  if (isImageExtension(ext)) {
    return FILE_PREVIEW_TYPES.IMAGE;
  }

  if (isPdfExtension(ext)) {
    return FILE_PREVIEW_TYPES.PDF;
  }
}