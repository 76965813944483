import Axios from "axios";
import { __getAuthObject, authenticationParameters } from "./authProvider";
import { restartTimeout } from "../../functions/timeout";
import { __getDataAuthObject } from "./authenticationData";
import { Service } from "axios-middleware";
import { msalInstance } from "../../../App";
const service = new Service(Axios);

const __domain = process.env.REACT_APP_Domain;
const __apiVersion = process.env.REACT_APP_ApiVersion;
const __metaApi = process.env.REACT_APP_MetaApi;
const __filesApi = process.env.REACT_APP_FilesApi;

service.register({
  onRequest(config) {
    restartTimeout();
    return config;
  },
  onSync(promise) {
    promise.catch((error) => {
      if (error.response && error.response.status === 401) {
        msalInstance.loginRedirect({
          ...authenticationParameters,
          prompt: "select_account",
        });
      }
    });
    return promise;
  },
  onResponse(response) {
    return response;
  },
});

export async function __getCollection(
  collection,
  params = { pageSize: 15, page: 1, sortBy: "", des: false, filters: "" },
  paramsSerializer,
  cancelToken
) {
  const authObject = await __getAuthObject();
  restartTimeout();
  const response = await Axios.get(
    `${__domain}/${__apiVersion}/${collection}`,
    {
      cancelToken: cancelToken,
      params: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authObject.accessToken,
      },
      paramsSerializer,
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // Error :fearful:
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log("Error data: ", error.response.data);
        console.log("Error status: ", error.response.status);
        console.log("Error headers: ", error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, error.request
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log("Error Request: ", error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return [];
    });
  return response;
}

export async function getMetaData(collection, params = {}) {
  const authObject = await __getAuthObject();
  restartTimeout();
  const response = await Axios.get(
    `${__metaApi}/${__apiVersion}/${collection}`,
    {
      params: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authObject.accessToken,
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // Error :fearful:
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log("Error data: ", error.response.data);
        console.log("Error status: ", error.response.status);
        console.log("Error headers: ", error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, error.request
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log("Error Request: ", error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return [];
    });
  return response;
}

export async function getRecord(collection, id, params = {}, paramsSerializer) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.get(
      `${__domain}/${__apiVersion}/${collection}/${id}`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
        paramsSerializer,
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function getJobsAndTask(id, params = {}, paramsSerializer) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.get(
      `${__domain}/${__apiVersion}/ServiceOrders/${id}/jobs-list`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
        paramsSerializer,
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function getJobsDetail(id, params = {}, paramsSerializer) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.get(
      `${__domain}/${__apiVersion}/Jobs/${id}/details`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
        paramsSerializer,
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function getRelatedRecord(collection, id, record, params = {}) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.get(
      `${__domain}/${__apiVersion}/${collection}/${id}/${record}`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function postRequestFile(data, url) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.post(
      `${__domain}/${__apiVersion}/${url}`,
      {
        serviceOrderKeys: data,
        // isInvoiced: true //for invoced status
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function postRequestFilePure(data, url) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.post(
      `${__domain}/${__apiVersion}/${url}`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function postRecord(collection, data, query, silent = true) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.post(
      `${__domain}/${__apiVersion}/${collection}${query ? "?" + query : ""}`,
      JSON.stringify(data),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
      }
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
          return error.response.data;
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }

        if (!silent) {
          throw error;
        }
        return error;
      });
    return response;
  });
}

export async function putRecord(
  collection,
  data,
  key = "",
  query = "",
  silent = true
) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.put(
      `${__domain}/${__apiVersion}/${collection}/${key}${
        query ? "?" + query : ""
      }`,
      JSON.stringify(data),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
      }
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
          if (!silent) {
            throw error;
          }
          return error.response;
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
          throw error.request;
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        if (!silent) {
          throw error;
        }
        return [];
      });
    return response;
  });
}

export async function deleteRecord(collection, id) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.delete(
      `${__domain}/${__apiVersion}/${collection}/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
      }
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function deleteRelatedRecord(
  collection,
  collectionId,
  record,
  recordId
) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.delete(
      `${__domain}/${__apiVersion}/${collection}/${collectionId}/${record}/${recordId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function getFileData(collection, id) {
  return __getDataAuthObject().then(async (authDataObject) => {
    restartTimeout();
    const response = await Axios.get(
      `${__filesApi}/${__apiVersion}/${collection}/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authDataObject.accessToken,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function postFileToFileService(file) {
  const fileAccessToken = await __getDataAuthObject().then((res) => {
    return res.accessToken;
  });
  let formData = new FormData();
  formData.append("file", file);
  const response = await fetch(
    `${__filesApi}/${__apiVersion}/files/?fileSign=Project`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + fileAccessToken,
      },
      body: formData,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // Error :fearful:
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log("Error data: ", error.response.data);
        console.log("Error status: ", error.response.status);
        console.log("Error headers: ", error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, error.request
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */

        console.log("Error Request: ", error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return [];
    });
  return response;
}

// upload progress in a range between 0 and 1
export async function postFileToFileServiceWithProgress(
  file,
  progressCallback,
  silent = true
) {
  const fileAccessToken = await __getDataAuthObject().then((res) => {
    return res.accessToken;
  });
  let formData = new FormData();
  formData.append("file", file);
  const response = await Axios.post(
    `${__filesApi}/${__apiVersion}/files/?fileSign=Project`,
    formData,
    {
      headers: {
        Authorization: "Bearer " + fileAccessToken,
      },
      onUploadProgress: (p) => {
        progressCallback(p.loaded / p.total);
      },
    }
  )
    .then((res) => {
      progressCallback(1);
      return res.data;
    })
    .catch((error) => {
      // Error :fearful:
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log("Error data: ", error.response.data);
        console.log("Error status: ", error.response.status);
        console.log("Error headers: ", error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, error.request
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */

        console.log("Error Request: ", error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);

      if (!silent) {
        throw error;
      }
      return [];
    });
  return response;
}

export async function deleteFileFromFileService(fileKey) {
  const fileAccessToken = await __getDataAuthObject().then((res) => {
    return res.accessToken;
  });
  restartTimeout();

  const response = await Axios.delete(
    `${__filesApi}/${__apiVersion}/files/${fileKey}`,
    {
      headers: {
        Authorization: "Bearer " + fileAccessToken,
      },
    }
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // Error :fearful:
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log("Error data: ", error.response.data);
        console.log("Error status: ", error.response.status);
        console.log("Error headers: ", error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, error.request
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */

        console.log("Error Request: ", error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return [];
    });
  return response;
}

// TESTING
export async function getProfileInfo() {
  // console.log("user logged in result: ", result);
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.get(`${__domain}/${__apiVersion}/Profiles`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authObject.accessToken,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // Error :fearful:
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

// Special case for IKEA Service Status Update. Same as 'putStatus', but also alerts the user if the submission was incorrect
export async function putStatusRecord(collection, data, key, query = "") {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.put(
      `${__domain}/${__apiVersion}/${collection}/${key}${
        query ? "?" + query : ""
      }`,
      JSON.stringify(data),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authObject.accessToken,
        },
      }
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        // Error :fearful:
        console.log("Initial error: ", error);
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log("Error data: ", error.response.data);
          console.log("Error status: ", error.response.status);
          console.log("Error headers: ", error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, error.request
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          console.log("Error Request: ", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return [];
      });
    return response;
  });
}

export async function getPDFReceipt(serviceOrderId) {
  return __getAuthObject().then(async (authObject) => {
    restartTimeout();
    const response = await Axios.get(
      `${__domain}/${__apiVersion}/pdf/LineItemsInvoicePdf`,
      {
        params: {
          serviceOrderId: serviceOrderId,
          portal: "staff",
        },
        headers: {
          Authorization: "Bearer " + authObject.accessToken,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    return response;
  });
}
